import { useTranslation } from "react-i18next";
import HighlighterWrapper from "../../common/HighlighterWrapper";

const C2Q014 = (props: { searchText: string }) => {
  const { searchText } = props;
  const searchTexts = searchText.split(" ");
  const translationNamespace = ["faq"];
  const { t } = useTranslation(translationNamespace);
  const ad2p1 = t("cat2.details.ad2p1");
  const ad2p2 = t("cat2.details.ad2p2");
  const ad2p3 = t("cat2.details.ad2p3");
  const ad2p4 = t("cat2.details.ad2p4");
  const ad2p5 = t("cat2.details.ad2p5");
  const ad2p6 = t("cat2.details.ad2p6");
  const ad2p7 = t("cat2.details.ad2p7");
  return (
    <>
      
      <ul>
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={ad2p1?.toString()} />
        </li>
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={ad2p2?.toString()} />
        </li>
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={ad2p3?.toString()} />
        </li>
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={ad2p4?.toString()} />
        </li>
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={ad2p5?.toString()} />
        </li>
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={ad2p6?.toString()} />
        </li>
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={ad2p7?.toString()} />
        </li>
      </ul>
     
    </>
  );
};

export default C2Q014;
 