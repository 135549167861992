import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CourseShort } from '../../model/courseShort';
import { SearchResult } from '../../model/searchResult';
import { RootState } from '../store';

const initialStateValue: SearchResult = {
  results: [],
  total: null,
  currentPage: 1,
  pages: 0
}

const searchResultSlice = createSlice({
  name: 'searchResult',
  initialState: initialStateValue,
  reducers: {
    setSearchReults: (state, action: PayloadAction<CourseShort[]>) => {
      state.results = action.payload;
    },
    setTotal: (state, action: PayloadAction<number | null>) => {
      state.total = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setPages: (state, action: PayloadAction<number>) => {
      state.pages = action.payload;
    },
    resetSearchResults: (state) => {
      state.results = [];
      state.total = null;
      state.currentPage = 1;
      state.pages = 0;
    },
  }
});

export const { setSearchReults, setTotal, setCurrentPage, setPages, resetSearchResults } = searchResultSlice.actions;
export const getSearchResult = (state: RootState) => state.searchResult
export default searchResultSlice.reducer;