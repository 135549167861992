const BgPatternOne = () => {
  return (
    <div className="banner-patterns" data-testid="bg-pattern-one-component">
      <svg width="181" height="187" viewBox="0 0 181 187" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M62.7205 138.425C62.7205 131.083 56.5374 125.128 48.907 125.128C41.2765 125.128 35.0935 131.08 35.0935 138.425C35.0935 145.77 41.2765 151.721 48.907 151.721C56.5374 151.721 62.7205 145.77 62.7205 138.425ZM58.5754 138.425C58.5754 133.284 54.2479 129.118 48.907 129.118C43.566 129.118 39.2386 133.284 39.2386 138.425C39.2386 143.566 43.566 147.731 48.907 147.731C54.2479 147.731 58.5754 143.566 58.5754 138.425Z" fill="#FF8200"/>
<path fillRule="evenodd" clipRule="evenodd" d="M20.1388 105.252C20.1388 99.9005 15.6291 95.5596 10.0694 95.5596C4.50972 95.5596 0 99.9005 0 105.252C0 110.604 4.50972 114.944 10.0694 114.944C15.6291 114.944 20.1388 110.604 20.1388 105.252ZM17.1165 105.252C17.1165 101.504 13.9594 98.4652 10.0658 98.4652C6.17216 98.4652 3.01499 101.504 3.01499 105.252C3.01499 109 6.17216 112.039 10.0658 112.039C13.9594 112.039 17.1165 109 17.1165 105.252Z" fill="#FF8200"/>
<path fillRule="evenodd" clipRule="evenodd" d="M121.897 10.4505C124.894 10.4505 127.326 8.10981 127.326 5.22523C127.326 2.34065 124.894 0 121.897 0C118.901 0 116.469 2.34065 116.469 5.22523C116.469 8.10981 118.901 10.4505 121.897 10.4505ZM121.897 8.88183C123.997 8.88183 125.696 7.24303 125.696 5.22523C125.696 3.20743 123.994 1.56862 121.897 1.56862C119.801 1.56862 118.099 3.20743 118.099 5.22523C118.099 7.24303 119.801 8.88183 121.897 8.88183Z" fill="#EF6079"/>
<path fillRule="evenodd" clipRule="evenodd" d="M148.431 10.4505C151.428 10.4505 153.859 8.10981 153.859 5.22523C153.859 2.34065 151.428 0 148.431 0C145.434 0 143.002 2.34065 143.002 5.22523C143.002 8.10981 145.434 10.4505 148.431 10.4505Z" fill="#FF8200"/>
<path fillRule="evenodd" clipRule="evenodd" d="M20.1388 72.0829C20.1388 66.7313 15.6291 62.3904 10.0694 62.3904C4.50972 62.3904 0 66.7278 0 72.0829C0 77.438 4.50972 81.7754 10.0694 81.7754C15.6291 81.7754 20.1388 77.4344 20.1388 72.0829Z" fill="#60CDB8"/>
<path fillRule="evenodd" clipRule="evenodd" d="M62.7205 72.0829C62.7205 64.7416 56.5374 58.7864 48.907 58.7864C41.2765 58.7864 35.0935 64.7381 35.0935 72.0829C35.0935 79.4277 41.2765 85.3793 48.907 85.3793C56.5374 85.3793 62.7205 79.4277 62.7205 72.0829Z" fill="#072B42"/>
<path d="M163.298 120.486C172.814 120.486 180.527 113.06 180.527 103.901C180.527 94.7416 172.814 87.3164 163.298 87.3164C153.782 87.3164 146.068 94.7416 146.068 103.901C146.068 113.06 153.782 120.486 163.298 120.486Z" fill="#60CDB8"/>
<path fillRule="evenodd" clipRule="evenodd" d="M146.068 37.5557C146.068 46.7147 153.783 54.1402 163.298 54.1402C172.813 54.1402 180.527 46.7147 180.527 37.5557C180.527 28.3966 172.813 20.9711 163.298 20.9711C153.783 20.9711 146.068 28.3966 146.068 37.5557ZM151.238 37.5557C151.238 43.967 156.637 49.1642 163.298 49.1642C169.959 49.1642 175.358 43.967 175.358 37.5557C175.358 31.1443 169.959 25.9472 163.298 25.9472C156.637 25.9472 151.238 31.1443 151.238 37.5557Z" fill="#60CDB8"/>
<path fillRule="evenodd" clipRule="evenodd" d="M77.1465 138.249C77.1465 147.408 84.8608 154.834 94.3761 154.834C103.891 154.834 111.606 147.408 111.606 138.249C111.606 129.09 103.891 121.665 94.3761 121.665C84.8608 121.665 77.1465 129.09 77.1465 138.249ZM82.3161 138.249C82.3161 144.661 87.7154 149.858 94.3761 149.858C101.037 149.858 106.436 144.661 106.436 138.249C106.436 131.838 101.037 126.641 94.3761 126.641C87.7154 126.641 82.3161 131.838 82.3161 138.249Z" fill="#FEC340"/>
<path fillRule="evenodd" clipRule="evenodd" d="M77.1465 105.252C77.1465 114.411 84.8608 121.837 94.3761 121.837C103.891 121.837 111.606 114.411 111.606 105.252C111.606 96.093 103.891 88.6674 94.3761 88.6674C84.8608 88.6674 77.1465 96.093 77.1465 105.252ZM82.3161 105.252C82.3161 111.663 87.7154 116.861 94.3761 116.861C101.037 116.861 106.436 111.663 106.436 105.252C106.436 98.8407 101.037 93.6435 94.3761 93.6435C87.7154 93.6435 82.3161 98.8407 82.3161 105.252Z" fill="#8C4799"/>
<path fillRule="evenodd" clipRule="evenodd" d="M111.606 137.07C111.606 146.229 119.32 153.655 128.835 153.655C138.35 153.655 146.065 146.229 146.065 137.07C146.065 127.911 138.35 120.486 128.835 120.486C119.32 120.486 111.606 127.911 111.606 137.07Z" fill="#EF6079"/>
<path d="M163.298 153.655C172.814 153.655 180.527 146.23 180.527 137.07C180.527 127.911 172.814 120.486 163.298 120.486C153.782 120.486 146.068 127.911 146.068 137.07C146.068 146.23 153.782 153.655 163.298 153.655Z" fill="#FEC340"/>
<path fillRule="evenodd" clipRule="evenodd" d="M146.068 170.243C146.068 179.402 153.783 186.827 163.298 186.827C172.813 186.827 180.527 179.402 180.527 170.243C180.527 161.084 172.813 153.658 163.298 153.658C153.783 153.658 146.068 161.084 146.068 170.243ZM151.238 170.243C151.238 176.654 156.637 181.851 163.298 181.851C169.959 181.851 175.358 176.654 175.358 170.243C175.358 163.831 169.959 158.634 163.298 158.634C156.637 158.634 151.238 163.831 151.238 170.243Z" fill="#072B42"/>
</svg>
    </div>
  );
};

export default BgPatternOne;
