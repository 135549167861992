import ParagraphWrapper from "../../common/ParagraphWrapper";

const CQTemplate1 = (props: { searchText: string, paragraph1: string, paragraph2: string }) => {
    const { searchText, paragraph1, paragraph2 } = props;
    const searchTexts = searchText.split(" ");
  return (
    <>
      <ParagraphWrapper searchTexts={searchTexts} textToHighlight={paragraph1?.toString()} />
      <ParagraphWrapper searchTexts={searchTexts} textToHighlight={paragraph2?.toString()} />
    </>
  );
};

export default CQTemplate1;
