import { create } from "apisauce";
import { LANGUAGE_LOOKUP_LOCAL_STORAGE } from "../constant/translation";

const getActiveLanguage = (): string => {
    const language = localStorage.getItem(LANGUAGE_LOOKUP_LOCAL_STORAGE);
    return language || "en";
};

const activeLang = getActiveLanguage();

const apiClient = create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        "X-LANG": activeLang,
        token: process.env.REACT_APP_CREATE_API_KEY || "",
    },
});

apiClient.addRequestTransform((request: any) => {
    request.headers["X-LANG"] = getActiveLanguage();
    return request;
});

export default apiClient;
