import { Container, Typography } from "@mui/material";
import { HashLink } from 'react-router-hash-link';
import "./target-workforce.scss"
import { Trans, useTranslation } from 'react-i18next';
import { HCBS_URL } from "../../constant/path";

interface SubSectionProps {
    title: string;
    description: string;
}

const SubSection = (props: SubSectionProps) => {
    const { title, description } = props
    return (
        <div className="sub-section">
            <Typography variant="h5" sx={{ margin: '1.5rem 0' }}>{title}</Typography>
            <Typography>{description}</Typography>
        </div>
    )
}

const TargetWorkforce = () => {
    const translationNamespace = ['aboutCalGrowsTranslations'];
    const { t } = useTranslation(translationNamespace);

    const renderSubSections = () => {
        const sections = [];
        for (let i = 1; i <= 2; i++) {
            sections.push(<SubSection
                key={i}
                title={t(`targetWorkforce.subSection${i}.title`)}
                description={t(`targetWorkforce.subSection${i}.description`)}
            />)
        }
        return sections
    }

    return (
        <div className="about-target-workforce" data-testid="about-target-workforce">
            <Container maxWidth="xl">
                <h2 className="about-target-workforce__title">
                    {t('targetWorkforce.title')}
                </h2>
                <Typography>{t('targetWorkforce.description')}</Typography>
                <div className="sub-sections">
                    {renderSubSections()}
                </div>
                <Typography>
                    <Trans components={{
                        HashLink: <HashLink smooth to={HCBS_URL} target="_blank" />,
                    }}>
                        {t('targetWorkforce.footer.description')}
                    </Trans>
                </Typography>
            </Container>
        </div>
    )
}

export default TargetWorkforce;