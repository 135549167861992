import { Grid, Container, Typography, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { INCENTIVE_CLAIM_URL } from "../../constant/path";
import incentiveIcon from "../../images/incentiveTraining.svg";
import CirclePatternTwo from "../common/circlePatternTwo/circlePatternTwo";

export default function IncentiveClaim() {
  const translationNamespace = ["getPaidTranslations"];
  const { t } = useTranslation(translationNamespace);
  return (
    <div className="incentive-claim-section" data-testid="incentive-claim-component">
      <Container maxWidth="xl" sx={{ p: "50px" }}>
        <Typography
          variant="h3"
          className=""
          sx={{ color: "#8C4799", textAlign: "left" }}
        >
          {t("incentiveClaim.title")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item sm={12} md={9} sx={{ paddingBottom: "50px" }}>
            <Grid item sm={12} md={10} sx={{ paddingBottom: "10px" }}>
              <Typography variant="body2" className="condition-text">
                {t("incentiveClaim.paragraph")}
              </Typography>
            </Grid>
            <Grid item sm={12} md={7} sx={{ paddingBottom: "50px" }}>
              <Typography variant="body2" className="condition-text">
                {t("incentiveClaim.condition")}
              </Typography>
            </Grid>
            <a href={INCENTIVE_CLAIM_URL} target="_blank" rel="noreferrer" style={{textDecoration: "none"}}>
              <Button
                variant="contained"
                id="get_paid_submit_incentive_claim_btn"
                sx={{
                  display: "flex",
                  padding: "6px 35px",
                }}
              >
                {t("incentiveClaim.btnText")}
              </Button>
            </a>
            
          </Grid>
          <Grid item sm={12} md={3} sx={{ paddingBottom: "50px" }}>
            <img src={incentiveIcon} width="115" alt="Incentive Icon" />
          </Grid>
        </Grid>
        
      </Container>
      <span className="session-circle-pattern"><CirclePatternTwo/></span>
    </div>
  );
}
