import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PageTitle from "../components/common/PageTitle";
import { Button, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import { EDIT_COURSE, MANAGE_COURSES } from "../constant/path";
import CourseForm from "../components/common/CourseForm";
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// export function EditCourse({ signOut, user }: WithAuthenticatorProps) {
    export function EditCourse({ signOut, user }: WithAuthenticatorProps) {
    const translationNamespace = ["editCourseCalGrowsTranslations"];
    const { t } = useTranslation(translationNamespace);
    const metaDesc: string = t("metaDesc");
    const navigate = useNavigate();

    const [showBackModal, setShowBackModal] = useState(false as boolean);
    const handleCancelBack = () => {
        setShowBackModal(false);
    };
    return (
        <>
            <Helmet>
                <title>{t("browserTitle")}</title>
                <meta name="description" content={metaDesc} />
                <link rel="canonical" href={`${process.env.REACT_APP_URL}/${EDIT_COURSE}`} />
            </Helmet>
            <div className="main-content-wrapper" data-testid="edit-course-calGrows-view">
            {/* <p className="title-block">Hello, {user?.username}.<Button onClick={signOut}>Sign out</Button></p> */}
            <p className="title-block">Hello! <Button onClick={signOut}>Sign out</Button></p>
                <Container maxWidth="lg" sx={{ p: "25px" }}>
                    <Button onClick={() => setShowBackModal(true)}>{"< Back to Manage courses"}</Button>
                </Container>
                <PageTitle title={t("title")} />
                <CourseForm username={user?.signInDetails?.loginId} editMode handleBack={() => setShowBackModal(true)} />
            </div>
            <Dialog
                open={showBackModal}
                onClose={handleCancelBack}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Navigating back to Manage Courses`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to navigate away to the Manage Courses page?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelBack}>Cancel</Button>
                    <Button
                        onClick={() => navigate(`../${MANAGE_COURSES}`, { replace: true })}
                        variant={"contained"}
                        color={"primary"}
                        autoFocus
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default withAuthenticator(EditCourse);