import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface Visibility {
  isFilterVisible: boolean;
  isLoaderVisible: boolean | null;
}

const initialStateValue: Visibility = {
  isFilterVisible: true,
  isLoaderVisible: null
}


const visibilitySlice = createSlice({
  name: 'visibility',
  initialState: initialStateValue,
  reducers: {
    setIsFilterVisible: (state, action: PayloadAction<boolean>) => {
      state.isFilterVisible = action.payload;
    },
    setIsLoaderVisible: (state, action: PayloadAction<boolean>) => {
      state.isLoaderVisible = action.payload;
    }
  }
});

export const { setIsFilterVisible, setIsLoaderVisible } = visibilitySlice.actions;
export const getVisibility = (state: RootState) => state.visibility
export default visibilitySlice.reducer;