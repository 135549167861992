import HighlighterWrapper from "../../../common/HighlighterWrapper";

const RadioOption = (props: { searchTexts: string[]; option1?: string; option2?: string; option3?: string; option4?: string;  option5?: string;}) => {
  const { searchTexts, option1, option2, option3, option4, option5 } = props;
  return (
    <ul data-testid="radio-option-component">
      {option1 && (
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={option1.toString()} />
        </li>
      )}
      {option2 && (
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={option2.toString()} />
        </li>
      )}
      {option3 && (
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={option3.toString()} />
        </li>
      )}
       {option4 && (
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={option4.toString()} />
        </li>
      )}
       {option5 && (
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={option5.toString()} />
        </li>
      )}
    </ul>
  );
};

export default RadioOption;
