import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Radio } from "@mui/material";
import { SyntheticEvent } from "react";


function Dropdown(props: { opts: string[], name: string, title: string, onChange: any, selectedValue: string| null }) {
  const { opts, name, title, onChange, selectedValue } = props;

  const onTagsChange = (event: SyntheticEvent, value: string | null) => {
    event.preventDefault();
    onChange(value);
  }

  return (
    <Autocomplete
      multiple={false}
      id={name}
      options={opts}
      disableCloseOnSelect={false}
      defaultValue={selectedValue}
      value={selectedValue}
      getOptionLabel={(opt) => opt}
      onChange={onTagsChange}
      renderOption={(props, opt, { selected }) => (
        <li {...props}>
          <Radio style={{ marginRight: 8 }} value={opt} checked={selected} />
          {opt}
        </li>
      )}
      style={{ width: "100%" }}
      renderInput={(params) => 
      <TextField {...params} label={title} placeholder={title} />}
    />
  );
}

export default Dropdown;
