import HighlighterWrapper from "./HighlighterWrapper";

const ParagraphWrapper = (props: { searchTexts: string[]; textToHighlight: string; }) => {
  return (
    <p data-testid="paragraph-wrapper-component">
      <HighlighterWrapper {...props} />
    </p>
  );
};

export default ParagraphWrapper;
