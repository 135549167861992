import { Link } from "react-router-dom";
import { PROD_COURSE_CATALOG_PATH } from "../../../constant/siteUrl";
import HighlighterWrapper from "../../common/HighlighterWrapper";
import { extractContent } from "../utils/common";
import { CAREGIVER_TRAININGS_URL, FAQ_HOW_TO_APPLY_URL , FAQ_HOW_URL} from "../../../constant/path";
import { useTranslation } from "react-i18next";

const CQTemplate5 = (props: { searchText: string }) => {
  const { searchText } = props;
  const searchTexts = searchText.split(" ");
  const translationNamespace = ["faq"];
  const { t } = useTranslation(translationNamespace);
  const ac1 = t("cat3.details.ac1");
  const ac1001 = t("cat3.details.ac1001");
  const ac1002 = t("cat3.details.ac1002");

  const coursecatalogUrl = PROD_COURSE_CATALOG_PATH;


  const extractor = extractContent(ac1);
  const paragraph1: string[] = extractor!.input!.split(extractor![0]);

  const extractor1 = extractContent(ac1001);
  const extractorParaS1 = extractor1 === null || extractor1.input === undefined ? [] : extractor1.input.split(extractor1[0]);
  const extractorPara1 = extractorParaS1[0];
  const linkDisplayText1 = extractor1 === null ? "" : extractor1[1];

  const extractor2 = extractContent(ac1002);
  const extractorParaS2 = extractor2 === null || extractor2.input === undefined ? [] : extractor2.input.split(extractor2[0]);
  const extractorPara2 = extractorParaS2[0];
  const linkDisplayText2 = extractor2 === null ? "" : extractor2[1];

  return (
    <p>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={paragraph1[0]?.toString()} />
      <Link to={`../${CAREGIVER_TRAININGS_URL}`}>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={coursecatalogUrl?.toString()} />
      </Link>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={paragraph1[1]?.toString()} />
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={extractorPara1} />
      <Link to={`../${FAQ_HOW_TO_APPLY_URL}`}>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={linkDisplayText1?.toString()} />
      </Link>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={extractorPara2} />
      <Link to={`../${FAQ_HOW_URL}`}>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={linkDisplayText2?.toString()} />
      </Link>
    </p>
  );
};

export default CQTemplate5;
