import {
  Grid,
  Container,
  Typography,
  Link,
  List,
  ListItem,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { FAQ_URL, HSFORM_URL } from "../../constant/path";

export default function HomeFaq() {
  const translationNamespace = ["homeTranslations"];
  const { t } = useTranslation(translationNamespace);

  return (
    <div id="eligibility">
      <Container
        maxWidth="xl"
        sx={{ p: "100px" }}
        className="hm-learn-today-area"
      >
        <Grid container className="home-faq-section">
          <Grid item sm={12} md={12} lg={12}>
            <div>
              <Typography variant="h3">{t("homeFAQ1.title")}</Typography>
              <Typography variant="h6">{t("homeFAQ1.whoTitle")}</Typography>
              <div className="hm-topic-list">
                <List
                  sx={{
                    listStyleType: "disc",
                    pl: 2,
                    "& .MuiListItem-root": { display: "list-item" },
                  }}
                >
                  <ListItem>{t("homeFAQ1.who1")}</ListItem>
                  <ListItem>{t("homeFAQ1.who2")}</ListItem>
                  <ListItem>{t("homeFAQ1.who5")}</ListItem>
                  <ListItem>{t("homeFAQ1.who3")}</ListItem>
                  <ListItem>{t("homeFAQ1.who4")}</ListItem>
                </List>
              </div>

              <Typography variant="h6">{t("homeFAQ1.ihssTitle")}</Typography>
              <div>
                <List
                  sx={{
                    listStyleType: "disc",
                    pl: 2,
                    "& .MuiListItem-root": { display: "list-item" },
                  }}
                >
                  <ListItem>{t("homeFAQ1.ihss1")}</ListItem>
                  <ListItem>{t("homeFAQ1.ihss2")}</ListItem>
                </List>
              </div>

              <Typography variant="body1">
                <Trans
                  components={{
                    newLine: <br />,
                    italic: <i />,
                    small: <small />,
                    bold: <strong />,
                    UnderlinedText: (
                      <span style={{ textDecoration: "underline", fontStyle: "italic"}} />
                    ),
                  }}
                >
                  {t("homeFAQ1.detail")}
                </Trans>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container
        maxWidth="xl"
        sx={{ paddingBottom: "50px" }}
        className="hm-learn-today-area"
      >
        <Grid container className="home-faq-section">
          <Grid item sm={12} md={12} lg={12}>
            <div>
              <Typography variant="h3">{t("homeFAQ2.title")}</Typography>

              <Typography variant="h6">{t("homeFAQ2.notTitle")}</Typography>
              <div className="hm-topic-list">
                <List
                  sx={{
                    listStyleType: "disc",
                    pl: 2,
                    "& .MuiListItem-root": { display: "list-item" },
                  }}
                >
                  <ListItem>{t("homeFAQ2.not1")}</ListItem>
                  <ListItem>{t("homeFAQ2.not2")}</ListItem>
                  <ListItem>{t("homeFAQ2.not3")}</ListItem>
                  <ListItem>
                    <Trans
                      components={{
                        Link: (
                          <Link
                            href={FAQ_URL}
                            target="_blank"
                            color="inherit"
                            style={{ fontStyle: "italic" }}
                          />
                        ),
                      }}
                    >
                      {t("homeFAQ2.not4")}
                    </Trans>
                  </ListItem>
                </List>
              </div>

              <Typography variant="h6" sx={{ color: "#8C4799" }}>
                <Trans
                  components={{
                    Link: (
                      <Link
                        id="home_eligibility_support_form_Link"
                        href={HSFORM_URL}
                        target="_blank"
                        color="inherit"
                      />
                    ),
                    bold: <strong />,
                  }}
                >
                  {t("homeFAQ2.detail")}
                </Trans>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
