import { CALL_TO } from "../../../constant/other";
import HighlighterWrapper from "../../common/HighlighterWrapper";
import { SHARE_HS_FORMS_HREF } from "../../../constant/siteUrl";
import { extractContent } from "../utils/common";

const C1Q3 = (props: { searchText: string; paragraph: string; }) => {
  const { searchText, paragraph } = props;
  const searchTexts = searchText.split(" ");

  const sentencePart1 = paragraph.split("<a href=1-855-916-4091>1-855-916-4091</a>");
  const para1 = sentencePart1[0];
 
  const extractor = extractContent(sentencePart1[1]);
  const extractorPara = extractor!.input!.split(extractor![0]);
  const extractorPara1 = extractorPara[0];
  const linkDisplayText = extractor![1];

  return (
    <p data-testid="C1Q3-component">
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={para1} />
      <a href={`tel:${CALL_TO}`} target="_blank" rel="noopener noreferrer">
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={CALL_TO} />
      </a>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={extractorPara1} />
      <a href={SHARE_HS_FORMS_HREF} target="_blank" rel="noopener noreferrer">
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={linkDisplayText} />
      </a>
    </p>
  );
};

export default C1Q3;
