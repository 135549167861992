const circlePatternFourFaq = () => {
  return (
    <div className="faq-top-patterns" data-testid="circle-pattern-four-faq-component">
      <svg width="353" height="188" viewBox="0 0 353 188" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M50.9009 57.8993C50.9009 51.6721 45.8454 46.6208 39.6129 46.6208C33.3804 46.6208 28.3249 51.6721 28.3249 57.8993C28.3249 64.1266 33.3804 69.1778 39.6129 69.1778C45.8454 69.1778 50.9009 64.1266 50.9009 57.8993ZM47.5128 57.8993C47.5128 53.5382 43.9736 50.0019 39.6088 50.0019C35.244 50.0019 31.7047 53.5382 31.7047 57.8993C31.7047 62.2605 35.244 65.7967 39.6088 65.7967C43.9736 65.7967 47.5128 62.2605 47.5128 57.8993Z" fill="#FF8200" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.8039 57.8993C16.8039 53.5382 13.2647 50.0019 8.89989 50.0019C4.53508 50.0019 0.99585 53.5382 0.99585 57.8993C0.99585 62.2605 4.53508 65.7967 8.89989 65.7967C13.2647 65.7967 16.8039 62.2605 16.8039 57.8993ZM14.4336 57.8993C14.4336 54.849 11.9569 52.3744 8.90398 52.3744C5.85107 52.3744 3.37442 54.849 3.37442 57.8993C3.37442 60.9497 5.85107 63.4243 8.90398 63.4243C11.9569 63.4243 14.4336 60.9497 14.4336 57.8993Z" fill="#FF8200" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.8039 19.2984C16.8039 14.9373 13.2647 11.401 8.89989 11.401C4.53508 11.401 0.99585 14.9373 0.99585 19.2984C0.99585 23.6595 4.53508 27.1958 8.89989 27.1958C13.2647 27.1958 16.8039 23.6595 16.8039 19.2984Z" fill="#FF8200" />
        <path fillRule="evenodd" clipRule="evenodd" d="M50.9009 19.2984C50.9009 13.0712 45.8454 8.01991 39.6129 8.01991C33.3804 8.01991 28.3249 13.0712 28.3249 19.2984C28.3249 25.5257 33.3804 30.5769 39.6129 30.5769C45.8454 30.5769 50.9009 25.5257 50.9009 19.2984Z" fill="#60CDB8" />
        <path fillRule="evenodd" clipRule="evenodd" d="M98.6399 19.2985C98.6399 10.7559 91.7085 3.82623 83.1546 3.82623C74.6007 3.82623 67.6694 10.7518 67.6694 19.2985C67.6694 27.8451 74.6007 34.7706 83.1546 34.7706C91.7085 34.7706 98.6399 27.8451 98.6399 19.2985Z" fill="#072B42" />
        <path fillRule="evenodd" clipRule="evenodd" d="M282.689 94.924C282.689 103.467 289.62 110.396 298.174 110.396C306.728 110.396 313.66 103.471 313.66 94.924C313.66 86.3773 306.728 79.4518 298.174 79.4518C289.62 79.4518 282.689 86.3773 282.689 94.924ZM287.336 94.924C287.336 100.906 292.187 105.753 298.174 105.753C304.162 105.753 309.013 100.906 309.013 94.924C309.013 88.9418 304.162 84.0947 298.174 84.0947C292.187 84.0947 287.336 88.9418 287.336 94.924Z" fill="#FF8200" />
        <path fillRule="evenodd" clipRule="evenodd" d="M330.424 133.525C330.424 139.752 335.479 144.803 341.712 144.803C347.944 144.803 353 139.752 353 133.525C353 127.298 347.944 122.246 341.712 122.246C335.479 122.246 330.424 127.298 330.424 133.525ZM333.812 133.525C333.812 137.886 337.351 141.422 341.716 141.422C346.081 141.422 349.62 137.886 349.62 133.525C349.62 129.164 346.081 125.628 341.716 125.628C337.351 125.628 333.812 129.164 333.812 133.525Z" fill="#FF8200" />
        <path fillRule="evenodd" clipRule="evenodd" d="M330.424 172.122C330.424 178.349 335.479 183.4 341.712 183.4C347.944 183.4 353 178.353 353 172.122C353 165.89 347.944 160.843 341.712 160.843C335.479 160.843 330.424 165.894 330.424 172.122Z" fill="#60CDB8" />
        <path fillRule="evenodd" clipRule="evenodd" d="M282.689 172.122C282.689 180.664 289.62 187.594 298.174 187.594C306.728 187.594 313.66 180.668 313.66 172.122C313.66 163.575 306.728 156.65 298.174 156.65C289.62 156.65 282.689 163.575 282.689 172.122Z" fill="#072B42" />
        <path fillRule="evenodd" clipRule="evenodd" d="M266.517 95.1282C266.517 84.4704 257.869 75.8298 247.203 75.8298C236.536 75.8298 227.888 84.4704 227.888 95.1282C227.888 105.786 236.536 114.427 247.203 114.427C257.869 114.427 266.517 105.786 266.517 95.1282ZM260.722 95.1282C260.722 87.6677 254.669 81.6201 247.203 81.6201C239.736 81.6201 233.683 87.6677 233.683 95.1282C233.683 102.589 239.736 108.636 247.203 108.636C254.669 108.636 260.722 102.589 260.722 95.1282Z" fill="#FEC340" />
        <path fillRule="evenodd" clipRule="evenodd" d="M266.517 133.525C266.517 122.867 257.869 114.227 247.203 114.227C236.536 114.227 227.888 122.867 227.888 133.525C227.888 144.183 236.536 152.823 247.203 152.823C257.869 152.823 266.517 144.183 266.517 133.525ZM260.722 133.525C260.722 126.064 254.669 120.017 247.203 120.017C239.736 120.017 233.683 126.064 233.683 133.525C233.683 140.985 239.736 147.033 247.203 147.033C254.669 147.033 260.722 140.985 260.722 133.525Z" fill="#8C4799" />
        <path fillRule="evenodd" clipRule="evenodd" d="M150.621 57.8993C150.621 47.2415 141.973 38.6009 131.306 38.6009C120.64 38.6009 111.992 47.2415 111.992 57.8993C111.992 68.5571 120.64 77.1977 131.306 77.1977C141.973 77.1977 150.621 68.5571 150.621 57.8993Z" fill="#EF6079" />
        <path fillRule="evenodd" clipRule="evenodd" d="M150.621 19.2984C150.621 8.6406 141.973 0 131.306 0C120.64 0 111.992 8.6406 111.992 19.2984C111.992 29.9562 120.64 38.5968 131.306 38.5968C141.973 38.5968 150.621 29.9562 150.621 19.2984ZM144.826 19.2984C144.826 11.8379 138.773 5.79034 131.306 5.79034C123.84 5.79034 117.787 11.8379 117.787 19.2984C117.787 26.7589 123.84 32.8065 131.306 32.8065C138.773 32.8065 144.826 26.7589 144.826 19.2984Z" fill="#60CDB8" />
        <path fillRule="evenodd" clipRule="evenodd" d="M189.254 57.8993C189.254 47.2415 180.607 38.6009 169.94 38.6009C159.273 38.6009 150.625 47.2415 150.625 57.8993C150.625 68.5571 159.273 77.1977 169.94 77.1977C180.607 77.1977 189.254 68.5571 189.254 57.8993ZM183.459 57.8993C183.459 50.4388 177.407 44.3912 169.94 44.3912C162.473 44.3912 156.42 50.4388 156.42 57.8993C156.42 65.3598 162.473 71.4074 169.94 71.4074C177.407 71.4074 183.459 65.3598 183.459 57.8993Z" fill="#072B42" />
      </svg>
    </div>
  );
};

export default circlePatternFourFaq;
