import { CAL_GROWS_WEB_SITE, CAL_GROWS_WEB_SITE_URL } from "../../../constant/siteUrl";
import { extractContent } from "../../FAQ/utils/common";

const Template1 = (props: { paragraph: string }) => {
  const { paragraph } = props;
  const placeholder = CAL_GROWS_WEB_SITE;
  const href = CAL_GROWS_WEB_SITE_URL;

  const extractor = extractContent(paragraph);
  const paragraph1: string[] = extractor!.input!.split(extractor![0]);
  return (
    <p>
      <span>{paragraph1[0]?.toString()}</span>
      <a href={href}>
        <span>{placeholder?.toString()}</span>
      </a>
      <span>{paragraph1[1]?.toString()} </span>
    </p>
  );
};

export default Template1;
