import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import {
  Paper,
  Grid,
  InputBase,
  IconButton,
  Button,
  Container,
  FormLabel,
  Typography,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import { getSearchFilters, setTopics } from "../../store/reducer/searchFilters";
import coursesAPI from "../../api/course";
import {
  getApiStatus,
  setFiltersApi,
} from "../../store/reducer/apiStatus";
import { TopicsFilter } from "../../model/topicsFIlter";
import { useNavigate } from "react-router";
import { COURSE_CATALOG_URL_WITH_SCROLL } from "../../constant/path";
import DropdownMultiSelect from "../common/DropdownMultiSelect";
import SomethingWentWrong from "../common/SomethingWentWrong";
import { useTranslation } from "react-i18next";
import { resetSearchParameters, setSearchText, setTopic } from "../../store/reducer/searchParameters";

export default function SearchBox() {
  const [searchTextHome, setSearchTextHome] = useState<string>("")
  const [topicsHome, setTopicsHome] = useState<string[]>([])

  const translationNamespace = ["homeTranslations"];
  const { t } = useTranslation(translationNamespace);

  const searchFilters = useSelector(getSearchFilters);
  const apiStatus = useSelector(getApiStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleKeywordChanges = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTextHome(e.target.value);
  };

  const fetchFilters = async () => {
    const filterResponse = await coursesAPI.getFilters();
    if (filterResponse.ok) {
      const result = filterResponse.data;
      if (result) {
        dispatch(setTopics(result.topics));
      }
    } else {
      dispatch(setFiltersApi(false));
    }
  };

  const formatTopics = (topics: TopicsFilter[]) =>
    topics.map((obj) => obj.topic);

  useEffect(() => {
    fetchFilters();
  }, []);

  const loadCourses = (event: SyntheticEvent) => {
    event.preventDefault();
    dispatch(resetSearchParameters())
    dispatch(setSearchText(searchTextHome))
    dispatch(setTopic(topicsHome))
    navigate(COURSE_CATALOG_URL_WITH_SCROLL, {state: {searchCourses: true, keepPrevState: true}})
  };
  

  const listAllCourses = () => {
    navigate(COURSE_CATALOG_URL_WITH_SCROLL, {state: {fetchAllCourses: true}})
  };

  const searchTranslation = t("search");

  return (
    <>
      {apiStatus.filtersApi == true ? (
        <div className="position-relative find-course-section" data-testid="search-box-component">
          <Container maxWidth="xl" className="hm-search-course">
            <Grid container spacing={3} className="inner-search-box">
              <Grid item xs={12} className='pt-0'>
                <Typography variant="h3">{t("findACourse")}</Typography>
              </Grid>
              <Grid item lg={4} className="grid-inner">
                <Paper
                  component="form"
                  onSubmit={loadCourses}
                  sx={{
                    marginTop: "10px",
                    p: "8px 4px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <IconButton type="button" aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    id="search"
                    name="search"
                    onChangeCapture={handleKeywordChanges}
                    sx={{ ml: 1, flex: 1 }}
                    placeholder={searchTranslation}
                    inputProps={{ "aria-label": "search" }}
                    autoComplete="off"
                    required
                    value={searchTextHome}
                  />
                </Paper>
              </Grid>
              <Grid item lg={4} className="position-relative grid-inner">
                <FormLabel className="top-text">{t("filterBy")}:</FormLabel>
                <Box sx={{ marginTop: "10px" }}>
                  <DropdownMultiSelect
                    onChange={(value: string[]) => setTopicsHome(value)}
                    opts={
                      searchFilters.topics
                        ? formatTopics(searchFilters.topics)
                        : []
                    }
                    selectedValues={
                      topicsHome ? topicsHome : []
                    }
                    name="topics"
                    title={t("topic")}
                  />
                </Box>
              </Grid>
              <Grid item lg={3} className='d-flex align-items-center grid-inner'>
                <div className="position-relative d-flex align-items-center search-btn-block">
                  <Button
                    onClick={loadCourses}
                    variant="contained"
                    color="primary"
                    sx={{ width: "200px" }}
                    data-testid="search-translation-btn"
                    id="home_find_course_search_btn"

                  >
                    {searchTranslation}
                  </Button>
                  <Button
                    onClick={listAllCourses}
                    className="text-all-course"
                    data-testid="view-all-courses-btn"
                    id="home_find_course_view_courses_btn"
                  >
                    {t("viewAllCourses")}
                  </Button>                    
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <SomethingWentWrong />
      )}
    </>
  );
}
