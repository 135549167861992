import { Fragment, useEffect, useState } from "react";
import { Grid, Container, Typography, Button } from "@mui/material";
import coursesAPI from "../../api/course";
import { CourseSearchBody } from "../../model/courseSearchBody";
import ResultList from "./ResultList";
import FilterBox from "./FilterBox";
import { useDispatch, useSelector } from "react-redux";
import { getSearchParameters, resetSearchParameters, setPage } from "../../store/reducer/searchParameters";
import PaginationBox from "./PaginationBox";
import {
    getSearchResult,
    resetSearchResults,
    setCurrentPage,
    setPages,
    setSearchReults,
    setTotal,
} from "../../store/reducer/searchResult";
import { getApiStatus, setSearchApi } from "../../store/reducer/apiStatus";
import SomethingWentWrong from "../common/SomethingWentWrong";
import Loader from "../common/Loader";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import NoResult from "./NoResult";
import { getVisibility, setIsLoaderVisible } from "../../store/reducer/visibility";
import twelveHourToTwentyFourHour from "../../hooks/twelveHourToTwentyFourHour";
import triggerGTMEvent from "../../hooks/gtmEventTrigger";
import { COURSE_SEARCH_EVENT } from "../../constant/tagManagerEvent";
import { Course } from "../../model/course";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { EDIT_COURSE } from "../../constant/path";

export default function Search(props: { editMode?: boolean }) {
    const location = useLocation();
    const translationNamespace = ["caregiverTrainingsTranslations"];
    const { t } = useTranslation(translationNamespace);

    const {
        searchText,
        incentiveTrack,
        statuses,
        topics,
        formats,
        counties,
        languages,
        days,
        difficulties,
        trainingFocuses,
        occupationFocuses,
        startTime,
        endTime,
        pageSize,
        page,
    } = useSelector(getSearchParameters);
    const { currentPage, pages, results, total } = useSelector(getSearchResult);
    const apiStatus = useSelector(getApiStatus);
    const visibility = useSelector(getVisibility);

    const [courseToDelete, setCourseToDelete] = useState(undefined as any);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("useEffect fn");
        if (location.state) {
            if (location.state.keepPrevState) {
                if (!total) {
                    searchCourses(
                        searchText,
                        incentiveTrack,
                        formats,
                        languages,
                        counties,
                        statuses,
                        difficulties,
                        trainingFocuses,
                        occupationFocuses,
                        days,
                        topics,
                        startTime,
                        endTime,
                        page
                    );
                }
            } else {
                dispatch(resetSearchParameters());
                dispatch(resetSearchResults());
            }

            if (location.state.searchCourses) {
                searchCourses(
                    searchText,
                    incentiveTrack,
                    formats,
                    languages,
                    counties,
                    statuses,
                    difficulties,
                    trainingFocuses,
                    occupationFocuses,
                    days,
                    topics,
                    startTime,
                    endTime,
                    page
                );
            }

            if (location.state.fetchAllCourses) {
                dispatch(resetSearchParameters());
                dispatch(resetSearchResults());
                searchCourses("", "", "", [], [], [], [], [], [], [], [], "", "", 1);
            }
        } else {
            searchCourses(
                searchText,
                incentiveTrack,
                formats,
                languages,
                counties,
                statuses,
                difficulties,
                trainingFocuses,
                occupationFocuses,
                days,
                topics,
                startTime,
                endTime,
                page
            );
        }
    }, []);

    const callPage = (pageNo: number) => {
        searchCourses(
            searchText,
            incentiveTrack,
            formats,
            languages,
            counties,
            statuses,
            difficulties,
            trainingFocuses,
            occupationFocuses,
            days,
            topics,
            startTime,
            endTime,
            pageNo
        );
        const section = "search";
        const element = document.getElementById(section);
        if (element) {
            const offset = 80;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition + window.pageYOffset - offset;
            const offsetRound: number = Math.round(offsetPosition);

            window.scroll(0, offsetRound);
        }
    };

    const searchCourses = async (
        searchText: string,
        incentiveTrack: string | null,
        formats: string | null,
        languages: string[],
        counties: string[],
        statuses: string[],
        difficulties: string[],
        trainingFocuses: string[],
        occupationFocuses: string[],
        days: string[],
        topics: string[],
        startTime: string,
        endTime: string,
        pageNo: number
    ) => {
        dispatch(setIsLoaderVisible(true));
        console.log("searchCourses fn");
        const formatsProcessed = !formats ? [] : [formats];

        const bodydata: CourseSearchBody = {
            searchText: searchText,
            filter: {
                formats: formatsProcessed,
                incentiveTrack: incentiveTrack,
                languages: languages,
                counties: counties,
                statuses: statuses,
                days: days,
                topics: topics,
                difficulties: difficulties,
                trainingFocuses: trainingFocuses,
                occupationFocuses: occupationFocuses,
                startTime: twelveHourToTwentyFourHour(startTime),
                endTime: twelveHourToTwentyFourHour(endTime),
            },
            pagination: {
                paging: true,
                pageSize: pageSize,
                page: pageNo,
            },
        };

        // This will trigger custom event to google Tag Manager to capture search text
        triggerGTMEvent(COURSE_SEARCH_EVENT, { searchText: searchText });

        const response = await coursesAPI.searchCourses(bodydata);
        if (response.ok) {
            const data = response.data;
            const results = data?.results;
            if (results) {
                dispatch(setSearchReults(results));
                dispatch(setTotal(data.total));
                dispatch(setCurrentPage(data.currentPage));
                dispatch(setPage(data.currentPage));
                dispatch(setPages(data.pages));
                dispatch(setSearchApi(true));
            }
        } else {
            dispatch(setSearchApi(false));
        }

        dispatch(setIsLoaderVisible(false));
    };

    const handleEditCourse = async (course: Course) => {
        navigate(`../${EDIT_COURSE}`, { state: { course }, replace: true });
    };

    const handleDeleteCourse = async () => {
        const response = await coursesAPI.deleteCourse({ _id: courseToDelete?._id });
        if (response.ok) {
            callPage(1);
            setCourseToDelete(undefined);
            // setCourse(JSON.parse(JSON.stringify(initialStateValue)));
            // setCourseCreationStatus("success");
        } else {
            // setCourseCreationStatus("error");
        }
    };

    const onDelete = async (course: Course) => {
        setCourseToDelete(course);
    };

    const handleCancelDelete = () => {
        setCourseToDelete(undefined);
    };

    return (
        <Fragment>
            <div className="course-search-section" id="search">
                {apiStatus.filtersApi == true ? (
                    <Fragment>
                        <Container maxWidth="xl">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" sx={{ marginBottom: "20px" }}>
                                        {t("courseSearch")}{" "}
                                    </Typography>
                                    <Typography variant="body2" sx={{ marginBottom: "20px" }}>
                                        {props?.editMode ? t("courseManageDescription") : t("courseSearchDescription")}{" "}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                        {apiStatus.searchApi != false ? (
                            <Container maxWidth="xl">
                                <Grid container className="m-pr-0" spacing={3} sx={{ p: "12px 24px" }}>
                                    <FilterBox
                                        onClick={(
                                            searchText: string,
                                            incentiveTrack: string,
                                            formats: string,
                                            languages: string[],
                                            counties: string[],
                                            statuses: string[],
                                            difficulties: string[],
                                            trainingFocuses: string[],
                                            occupationFocuses: string[],
                                            days: string[],
                                            topics: string[],
                                            startTime: string,
                                            endTime: string,
                                            page: number
                                        ) =>
                                            searchCourses(
                                                searchText,
                                                incentiveTrack,
                                                formats,
                                                languages,
                                                counties,
                                                statuses,
                                                difficulties,
                                                trainingFocuses,
                                                occupationFocuses,
                                                days,
                                                topics,
                                                startTime,
                                                endTime,
                                                page
                                            )
                                        }
                                    />
                                    <Grid item xs className="result-wrapper">
                                        {visibility.isLoaderVisible ? (
                                            <Loader />
                                        ) : (
                                            <>
                                                {total ? (
                                                    <>
                                                        <ResultList
                                                            results={results}
                                                            editMode={props?.editMode}
                                                            handleDeleteCourse={onDelete}
                                                            handleEditCourse={handleEditCourse}
                                                        />
                                                        <PaginationBox
                                                            count={pages}
                                                            page={currentPage}
                                                            onClick={(pageNo: number) => callPage(pageNo)}
                                                        />
                                                    </>
                                                ) : (
                                                    <>{total == 0 && <NoResult />}</>
                                                )}
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            </Container>
                        ) : (
                            <SomethingWentWrong />
                        )}
                    </Fragment>
                ) : (
                    <SomethingWentWrong />
                )}
            </div>
            <Dialog
                open={Boolean(courseToDelete)}
                onClose={handleCancelDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{`Delete course: ${courseToDelete?.number || ""}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the course?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete}>Cancel</Button>
                    <Button onClick={handleDeleteCourse} variant={"contained"} color={"error"} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
