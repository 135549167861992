import { Typography, Link } from "@mui/material";
import Topic from "./Topic";
import React, { useState } from "react";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useTranslation } from "react-i18next";

const TOPIC_LIMIT = 6; // default viewable topic limit

export default function CourseTopics(props: { topics: string[] }) {
    const { topics } = props;
    const translationNamespace = ["courseDetailTranslations"];
    const { t } = useTranslation(translationNamespace);

    const [viewableTopics, setViewableTopics] = useState<number>(TOPIC_LIMIT)
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    // Handle topics toggle event.
    const toggleTopics = () => {
        if (isExpanded) {
            setViewableTopics(TOPIC_LIMIT)
        } else {
            setViewableTopics(topics.length)
        }
        setIsExpanded(!isExpanded)
    }

    // check is topic list expandable
    const isExpandable = () => topics && topics.length > TOPIC_LIMIT

    return (
        <div data-testid='course-topics-component'>
            {
                topics.slice(0, viewableTopics).map((topic, i) => {
                    return <Topic key={i} topic={topic} />;
                })
            }
            {
                isExpandable() &&
                <Link onClick={toggleTopics} sx={{ marginTop: 1, textDecoration: 'none', color: 'black', marginBottom: 3 }}>
                    {isExpanded ?

                        <Typography>{t("viewLess")} <KeyboardArrowUpIcon sx={{ verticalAlign: 'middle' }} /></Typography> :
                        <Typography>{t("viewMore")} <KeyboardArrowDownIcon sx={{ verticalAlign: 'middle' }} /></Typography>
                    }
                </Link>
            }
        </div>
    );
}
