// import timezone from "./timezone";

const timeToMins = (time:string) => { 
    const timeOfDay = time.slice(-2)
    const timeOnly = time.slice(0, -2);

    const timeArray = timeOnly.split(":")
    let hour: number = +timeArray[0]
    const min: number = +timeArray[1]
    if(timeOfDay=="pm" && hour!=12){
        hour = hour + 12
    }
    // return ((hour * 60) + min + timezone().offset)   
    return ((hour * 60) + min)   

};

export default timeToMins