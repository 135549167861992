import Highlighter from "react-highlight-words";

const HighlighterWrapper = (props: { searchTexts: string[]; textToHighlight: string; }) => {
    const {searchTexts, textToHighlight} = props
  return (
    <Highlighter
      data-testid="highlighter-wrapper-component"
      highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
      searchWords={searchTexts}
      autoEscape
      textToHighlight={textToHighlight ? textToHighlight.toString() : ""}
    />
  );
};

export default HighlighterWrapper;
