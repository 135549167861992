import { QuestionAnswer } from "../FAQ/QuestionCategory";
import AccordingWrapper from "../FAQ/AccordingWrapper";
import { useTranslation } from "react-i18next";
import CQTemplate1 from "../FAQ/Answer/CQTemplate1";
import Template1 from "./templete/Template1";
import Template2 from "./templete/Template2";

const FAQ = () => {
  const translationNamespace = ["courseDetailTranslations"];
  const { t } = useTranslation(translationNamespace);
  const Q001 = t("Q001");
  const A001P1 = t("A001P1");
  const A001P2 = t("A001P2");
  const Q002 = t("Q002");
  const A002 = t("A002");
  const Q003 = t("Q003");
  const A003 = t("A003");
  const Q004 = t("Q004");
  const A004 = t("A004");
  const Q005 = t("Q005");
  const A005 = t("A005");
  const Q006 = t("Q006");
  const A006 = t("A006");
  const Q007 = t("Q007");
  const A007 = t("A007");
  const Q008 = t("Q008");
  const A008 = t("A008");
  const Q009 = t("Q009");
  const A009 = t("A009");

  const details: QuestionAnswer[] = [
    {
      question: Q001,
      answer: A001P1 + A001P2,
      component: <CQTemplate1 paragraph1={A001P1} paragraph2={A001P2} searchText={""} />
    },
    {
      question: Q002,
      answer: A002,
    },
    {
      question: Q003,
      answer: A003,
    },
    {
      question: Q004,
      answer: A004,
    },
    {
      question: Q005,
      answer: A005,
      component: <Template1 paragraph={A005}/>
    },
    {
      question: Q006,
      answer: A006,
    },
    {
      question: Q007,
      answer: A007,
    },
    {
      question: Q008,
      answer: A008,
    },
    {
      question: Q009,
      answer: A009,
      component:<Template2 paragraph={A009}/>
    },
  ];
  return (
    <>
      {details.map((i: QuestionAnswer) => (
        <AccordingWrapper
          key={i.question}
          question={i.question}
          answer={i.answer}
          AnswerComp={i.component}
          searchTexts={[]}
        />
      ))}
    </>
  );
};

export default FAQ;
