import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategoriesFilter } from '../../model/categoriesFIlter';
import { CountyFilter } from '../../model/countyFilter';
import { Filters } from '../../model/filters';
import { FormatFilter } from '../../model/formatFilter';
import { IncentiveTrackFilter } from '../../model/incentiveTrackFilter';
import { LanguageFilter } from '../../model/languageFilter';
import { StatusFilter } from '../../model/statusFilter';
import { TopicsFilter } from '../../model/topicsFIlter';
import { DifficultyFilter } from '../../model/difficultyFilter';
import { TrainingFocusFilter } from '../../model/trainingFocusFilter';
import { OccupationFocusFilter } from '../../model/occupationFocusFilter';
import { RootState } from '../store';

const initialStateValue: Filters = {
  statuses: [],
  incentiveTracks: [],
  pathways: [],
  formats: [],
  counties: [],
  languages: [],
  topics: [],
  categories: [],
  difficulties: [],
  trainingFocuses: [],
  occupationFocuses: []
}


const searchFilterSlice = createSlice({
  name: 'searchFilters',
  initialState: initialStateValue,
  reducers: {
    setIncentiveTracks: (state, action: PayloadAction<IncentiveTrackFilter[]>) => {
      state.incentiveTracks = action.payload;
    },
    setStatuses: (state, action: PayloadAction<StatusFilter[]>) => {
      state.statuses = action.payload;
    },
    setTopics: (state, action: PayloadAction<TopicsFilter[]>) => {
      state.topics = action.payload;
    },
    setFormats: (state, action: PayloadAction<FormatFilter[]>) => {
      state.formats = action.payload;
    },
    setCounties: (state, action: PayloadAction<CountyFilter[]>) => {
      state.counties = action.payload;
    },
    setLanguages: (state, action: PayloadAction<LanguageFilter[]>) => {
      state.languages = action.payload;
    },
    setCategories: (state, action: PayloadAction<CategoriesFilter[]>) => {
      state.categories = action.payload;
    },
    setDifficulties: (state, action: PayloadAction<DifficultyFilter[]>) => {
      state.difficulties = action.payload;
    },
    setTrainingFocuses: (state, action: PayloadAction<TrainingFocusFilter[]>) => {
      state.trainingFocuses = action.payload;
    },
    setOccupationFocuses: (state, action: PayloadAction<OccupationFocusFilter[]>) => {
      state.occupationFocuses = action.payload;
    }
  }
});

export const { setIncentiveTracks, setStatuses, setTopics, setFormats, setCounties, setLanguages, setCategories, setDifficulties, setTrainingFocuses, setOccupationFocuses } = searchFilterSlice.actions;
export const getSearchFilters = (state: RootState) => state.searchFilters
export default searchFilterSlice.reducer;