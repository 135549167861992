import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import tick from "../../images/tick.svg";

export default function Topic(props: { topic: string }) {
  const translationNamespace = ["courseDetail"];
  const { t } = useTranslation(translationNamespace);
  const topics = t("topics")
  const { topic } = props;
  return (
    <Box sx={{ display: "flex", alignItems:"center", paddingBottom:"14px" }} data-testid="topic-component">
      <img src={tick} style={{ paddingRight:"12px" }} alt={topics}/>
      <Typography sx={{ fontSize: "18px", fontWeight:"600" }}>{topic}</Typography>
    </Box>
  );
}
