import { Grid, Container, Typography, List, ListItem } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import image from "../../images/areYouEligible.webp";
// import CirclePatternTwo from "../common/circlePatternTwo/circlePatternTwo";
import { FAQ_URL } from "../../constant/path";
import { Link } from "react-router-dom";

export default function AreYouEligible() {
  const translationNamespace = ["getPaidTranslations"];
  const { t } = useTranslation(translationNamespace);
  const title = t("areYouEligible.title");

  return (
    <Container
      maxWidth="xl"
      sx={{ p: "40px", paddingTop: "0" }}
      className="incentive-container"
      id="eligible"
      data-testid="are-you-eligible-component"
    >
      <Grid container className="eligible-section">
        <Grid item md={12} lg={7}>
          <div>
            <Typography variant="h3" className="h3-title-text">
              {title}
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                "& .MuiListItem-root": { display: "list-item" },
              }}
            >
              <ListItem>{t("areYouEligible.point1")}</ListItem>
              <ListItem>{t("areYouEligible.point2")}</ListItem>
              <ListItem>{t("areYouEligible.point3")}</ListItem>
              <ListItem>{t("areYouEligible.point4")}</ListItem>
              <ListItem>{t("areYouEligible.point5")}</ListItem>
            </List>

            <Typography variant="body1" className="eligible-info-text">
              <Trans components={{ 
                Link1: <Link to={`../${FAQ_URL}`} />, 
                bold: <strong />, 
                br: <br />,
                italic: <i />,
                small: <small />
                }}>
                {t("areYouEligible.description2")}
              </Trans>
            </Typography>

            <Typography variant="body2" className="eligible-info-text-mobile">
              <Trans components={{ 
                br: <br />,
                italic: <i />,
                small: <small />
                }}>
                {t("areYouEligible.description")}  
              </Trans>
            </Typography>

            <Typography variant="body2" className="eligible-info-text-mobile">
            <Trans components={{ 
              bold: <strong />,
              italic: <i />,
              small: <small /> 
              }}>
              {t("areYouEligible.condition")}
              </Trans>
            </Typography>
          </div>
        </Grid>
        <Grid item md={12} lg={5}>
          <div className="learn-today-bg eligible-img-area">
            <Link to="https://youtu.be/ge3FWTCzXiI?si=L7H6s2pk4Qk74Eyp" target="_blank" rel="noopener noreferrer" style={{ width: "120%", display: 'flex', justifyContent: 'right' }}>
              <img src={image} className="eligible-img" alt={title} />
            </Link>
            <Link to="https://youtu.be/KUhBsCrh3fU?si=VvC9_R28z8Meu2Qc" target="_blank" rel="noopener noreferrer" style={{ width: "120%", display: 'flex', justifyContent: 'right' }}>
              <Typography mt={2}>
              Video - ¿Soy elegible para pagos de incentivo de CalGrows?
              </Typography>
            </Link>
            {/* <div className="smoke-gradient">
              <svg
                viewBox="0 0 508 385"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0H507.603V385H0V0Z"
                  fill="url(#paint0_linear_40_2927)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_40_2927"
                    x1="159.875"
                    y1="181.014"
                    x2="374.027"
                    y2="377.887"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop offset="0.108021" stopColor="white" stopOpacity="0" />
                    <stop
                      offset="0.459237"
                      stopColor="white"
                      stopOpacity="0.489725"
                    />
                    <stop
                      offset="0.662118"
                      stopColor="white"
                      stopOpacity="0.881877"
                    />
                    <stop offset="1" stopColor="white" />
                  </linearGradient>
                </defs>
              </svg>
              <CirclePatternTwo />
            </div> */}
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" className="eligible-info-text">  
          <Trans components={{ 
              bold: <strong />,
              italic: <i />,
              small: <small /> 
              }}>
            {t("areYouEligible.description")}
            </ Trans>
          </Typography>
          <Typography variant="body2" className="eligible-info-text">
          <Trans components={{ 
              bold: <strong />,
              italic: <i />,
              small: <small /> 
              }}>
              {t("areYouEligible.condition")}
              </ Trans>
            </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
