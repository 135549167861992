import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Course } from "../../model/course";
import { Schedule } from "../../model/schedule";
import formatTime from "../../hooks/formatTime";
import Popup from "./popup";
import { useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Sessions(props: { course: Course }) {
  const { course } = props;
  const translationNamespace = ["courseDetailTranslations"];
  const { t } = useTranslation(translationNamespace);
  const [howToRegister, setHowToRegister] = useState<string[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  const openTextArray = ['Abierto', "Open", "打开", "Բաց", "Открыть", "열려 있는", "Mở"]

  const getDate = (schedules: Schedule[]) => {
    const dates: string[] = [];
    if (schedules && schedules.length > 0) {
      schedules.forEach((schedule: Schedule) => {
        dates.push(schedule.date!="Invalid date" ? schedule.date: t("na"));
      });
      return dates.join(",\n ");
    } else {
      return t("na");
    }
  };

  const getTime = (schedules: Schedule[]) => {
    const times: string[] = [];
    if (schedules && schedules.length > 0) {
      schedules.forEach((schedule: Schedule) => {
        if (schedule.startTime && schedule.endTime) {
          times.push(
            `${formatTime(schedule.startTime)}-${formatTime(schedule.endTime)}`
          );
        }
        else{
          times.push(t('na'))
        }
      });
      return times.join(",\n ");
    } else {
      return t("na");
    }
  };

  const handlePopup = (instructions: string[]) => {
    setHowToRegister(instructions);
    setOpen(!open);
  };

  return (
    <>
      <Grid item sm={12} className="session-table-area">
        <Typography
          className="title-session"
          sx={{
            fontSize: "30px",
            fontFamily: "AvenirNext-Bold",
          }}
        >
          {t("allSessions")}
        </Typography>
      </Grid>
      <Grid
        item
        sm={12}
        className="session-table-area"
        display={{ xs: "none", lg: "block" }}
      >
        <TableContainer component={Paper} className="table-container-session">
          <Table sx={{ minWidth: 700 }} aria-label="Courses">
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("status")}</StyledTableCell>
                <StyledTableCell>{t("session")}</StyledTableCell>
                <StyledTableCell>{t("format")}</StyledTableCell>
                <StyledTableCell>{t("date")}</StyledTableCell>
                <StyledTableCell>{t("time")}</StyledTableCell>
                <StyledTableCell>{t("duration")}</StyledTableCell>
                <StyledTableCell>{t("language")}</StyledTableCell>
                <StyledTableCell>{t("register")}</StyledTableCell>
              </TableRow>
            </TableHead>
            {course.sessionAvailability == true && (
              <TableBody>
                {course.sessions.map((session, index) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold" }}
                          className={`${
                            openTextArray.includes(session.registrationStatus)
                              ? "status-open"
                              : "status-closed"
                          }`}
                        >
                          {session.registrationStatus}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body1">{session.code}</Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {session.formats? session.formats.join(", "): ""}
                        </Typography>
                        <Typography variant="body1">
                          {session.geography &&
                            session.geography.city &&
                            `${session.geography.city}, `}
                          {session.geography &&
                            session.geography.county &&
                            `${session.geography.county}`}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <Typography variant="body1">
                          {getDate(session.schedules)}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        <Typography variant="body1">
                          {getTime(session.schedules)}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        {course.durationHours && (
                          <Typography variant="body1">
                            {`${course.durationHours} `}
                          </Typography>
                        )}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography variant="body1">
                          {session.languages? session.languages.join(", "): ""}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        {openTextArray.includes(session.registrationStatus) ? (
                          <Button
                            variant="contained"
                            onClick={
                              session.registrationInstructions &&
                              (() =>
                                handlePopup(session.registrationInstructions))
                            }
                          >
                            {t("register")}
                          </Button>
                        ) : (
                          <Button variant="outlined" disabled>
                            {session.registrationStatus}
                          </Button>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>

      <Grid
        item
        sm={12}
        className="session-table-area m-session-blocks mt-0"
        sx={{ marginTop: "40px" }}
        display={{ xs: "block", lg: "none" }}
      >
        {course.sessionAvailability == true && (
          <>
            {course.sessions.map((session, index) => {
              return (
                <Grid
                  container
                  key={index}
                  className="session-details-area"
                  sx={{ marginBottom: "32px" }}
                >
                  <Grid item sm={3}>
                    <Button
                      aria-label="Registration Status"
                      className={`btn-status-session ${openTextArray.includes(session.registrationStatus) ? 'open': 'closed'}`}
                      variant="contained"
                      color="primary"
                      size="large"
                    >
                      {session.registrationStatus}
                    </Button>
                  </Grid>
                  <Grid item sm={9} className="pl-15">
                    <Typography
                      variant="h3"
                      sx={{ color: "#8C4799", fontSize: "24px !important" }}
                    >
                      {session.code}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "16px !important" }}
                    >
                      <b>{session.formats? session.formats.join(", "): ""}&nbsp;</b>
                      {session.geography &&
                        session.geography.city &&
                        `${session.geography.city}, `}
                      {session.geography &&
                        session.geography.county &&
                        `${session.geography.county}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className="mt-32 date-time-text">
                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                      {t("date")}
                    </Typography>
                    <Typography variant="body1">
                      {getDate(session.schedules)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} className="mt-32 date-time-text">
                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                      {t("time")}
                    </Typography>
                    <Typography variant="body1">
                      {getTime(session.schedules)}
                    </Typography>
                  </Grid>
                  <Grid item sm={6} className="mt-32 date-time-text">
                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                      {t("language")}
                    </Typography>
                    {session.languages? session.languages.join(", "): ""}
                  </Grid>
                  <Grid item sm={6} className="mt-32 date-time-text">
                    <Typography variant="h6" sx={{ fontSize: "14px" }}>
                      {t("duration")}
                    </Typography>
                    <Typography variant="body1">
                      {`${course.durationHours} `}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    sm={12}
                    sx={{
                      marginTop: "32px",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    {openTextArray.includes(session.registrationStatus) ? (
                      <Button
                        variant="contained"
                        onClick={
                          session.registrationInstructions &&
                          (() => handlePopup(session.registrationInstructions))
                        }
                        className="btn-cta-session"
                      >
                        {t("register")}
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        disabled
                        className="btn-cta-session closed"
                      >
                        {session.registrationStatus}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              );
            })}
          </>
        )}
      </Grid>

      {course.sessionAvailability == false && (
        <div
          dangerouslySetInnerHTML={{
            __html: course.sessionAvailabilityDescription,
          }}
          className="session-offer-notify"
        />
      )}

      <Popup
        instructions={howToRegister}
        open={open}
        onClick={() => {
          handlePopup([]);
        }}
      />
    </>
  );
}
