
import { useEffect } from 'react';
import { useLocation} from 'react-router-dom';

export default function ScrollToTop() {
    const pathname = useLocation()
    
    useEffect(() => {
        if(pathname.hash == ""){
            window.scroll(0,0)
        }
        else{
            const section = pathname.hash.replace('#','');
            const element = document.getElementById(section);
            if (element) {
                
            const offset = 80;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - offset;
                const offsetRound:number = Math.round(offsetPosition)
                
                window.scroll(0, offsetRound)
            }
        }
    }, [pathname]);

    return (
        <></>
    )
    
}
