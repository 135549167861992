import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface UserLanguage {
  userLanguage: string
}

const initialStateValue: UserLanguage = {
  userLanguage: "en"
}


const userLanguageSlice = createSlice({
  name: 'userLanguage',
  initialState: initialStateValue,
  reducers: {
    setUserLanguage: (state, action: PayloadAction<string>) => {
      state.userLanguage = action.payload;
    }
  }
});

export const { setUserLanguage } = userLanguageSlice.actions;
export const getUserLanguage = (state: RootState) => state.userLanguage
export default userLanguageSlice.reducer;