import Banner from '../components/common/Banner';
import { Trans, useTranslation } from "react-i18next";
import incentivePaymentBanner from "../images/incentivePaymentBanner.webp"
import PageTitle from '../components/common/PageTitle';
// import InfoCards from '../components/incentivePayment/InfoCards';
import AreYouEligible from '../components/incentivePayment/areYouEliglble';
import IncentiveTracks from '../components/incentivePayment/IncentiveTracks';
import InfoContainerOther from '../components/home/InfoContainerOther';
import { GOOGLE_FORM_URL } from '../constant/path';
import { GET_PAID_URL } from '../constant/path';
import IncentiveClaim from '../components/incentivePayment/IncentiveClaim';
import { Helmet } from 'react-helmet';
import { Typography, Alert, Container } from "@mui/material";

export default function GetPaid() {
    const translationNamespace = ['getPaidTranslations']
    const { t } = useTranslation(translationNamespace);
    const metaDesc: string = t('metaDesc')
    return (
        <>
            <Helmet>
                <title>{t('browserTitle')}</title>
                <meta name="description" content={metaDesc} />
                <link rel="canonical" href={`${process.env.REACT_APP_URL}/${GET_PAID_URL}`} />
            </Helmet>
            <div className="main-content-wrapper">
                <Container maxWidth="xl">
 <Alert 
        variant="filled" 
        severity="info"
        color='warning'
        >
            <Typography variant="h5">
          Do not miss out! - All CalGrows Incentive Claims MUST be submitted by 8/31 for payment.</Typography>
        </Alert>
                </Container>
             
                <PageTitle title={t('title')} />
                <Banner
                    imageUrl={incentivePaymentBanner}
                    title={t("banner.title")}
                    subTitle={t("banner.subTitle")}
                    description={<Trans>{t("banner.description")}</Trans>}
                />
                <AreYouEligible />
                {/* <IncentiveLevels /> */}
                <IncentiveTracks />
                <IncentiveClaim />
                <InfoContainerOther
                    buttonId='get_paid_google_btn'
                    title='Did you find what you were looking for?'
                    buttonText='Let Us Know'
                    url={`../${GOOGLE_FORM_URL}`} />
            </div>
        </>

    );
}

