import { CAL_GROWS_WEB_SITE, CAL_GROWS_WEB_SITE_URL, SHARE_HS_FORMS_HREF } from "../../../constant/siteUrl";
import { extractContent } from "../../FAQ/utils/common";
import HighlighterWrapper from "../../common/HighlighterWrapper";

const Template2 = (props: { paragraph: string }) => {
  const { paragraph } = props;

  const hs_href = SHARE_HS_FORMS_HREF;

  const cal_grows_placeholder = CAL_GROWS_WEB_SITE;
  const cal_grows_href = CAL_GROWS_WEB_SITE_URL;

  const extractor = extractContent(paragraph);
  const extractorParas = extractor!.input!.split(extractor![0]);
  const hs_placeholder = extractor![1];

  const extractorRest = extractContent(extractorParas[1]);
  const extractorRestParas = extractorRest!.input!.split(extractorRest![0]);
  return (
    <>
      <span>{extractorParas[0].toString()}</span>
      <a href={hs_href} target="_blank" rel="noopener noreferrer">
        <HighlighterWrapper searchTexts={[]} textToHighlight={hs_placeholder.toString()} />
      </a>
      <span>{extractorRestParas[0].toString()}</span>
      <a href={cal_grows_href}>
        <HighlighterWrapper searchTexts={[]} textToHighlight={cal_grows_placeholder.toString()} />
      </a>
      <span>{extractorRestParas[1].toString()}</span>
    </>
  );
};

export default Template2;
