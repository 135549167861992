import { Grid, Container } from '@mui/material';
import { useTranslation } from "react-i18next";
import InfoCard from '../common/InfoCard';
// import { CAREGIVER_COACHING_URL, COURSE_CATALOG_URL_WITH_SCROLL, GET_PAID_URL } from '../../constant/path';
import { COURSE_CATALOG_URL_WITH_SCROLL, GET_PAID_URL } from '../../constant/path';
// import trainingIcon from '../../images/trainingIcon.svg'
// import coachingIcon from '../../images/coachingIcon.svg'
// import incentiveTraining from '../../images/incentiveTraining.svg'
import incentiveStep1 from '../../images/incentiveStep1.svg';
import incentiveStep2 from '../../images/incentiveStep2.svg';
import incentiveStep3 from '../../images/incentiveStep3.svg';
import { resetSearchParameters } from '../../store/reducer/searchParameters';
import { useDispatch } from 'react-redux';
import { resetSearchResults } from '../../store/reducer/searchResult';

export default function InfoCards() {
    const translationNamespace = ['homeTranslations']
    const { t } = useTranslation(translationNamespace);
    const buttonText1 = t("infoCard1.buttonText")
    const buttonText2 = t("infoCard2.buttonText")
    // const buttonText3 = t("infoCard3.buttonText")
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(resetSearchParameters());
        dispatch(resetSearchResults());
    };

    return (
        <Container maxWidth="xl" sx={{ padding: "50px" }} data-testid="info-cards-component">
            <Grid container spacing={2} className="incentive-payments-info-cards" direction="row" alignItems="flex-start">
                <Grid item md={12} lg={4} >
                    <InfoCard 
                        title={t('infoCard1.title')}
                        description={t("infoCard1.description")}
                        imageUrl={incentiveStep1}
                        buttonText={buttonText1}
                        link={COURSE_CATALOG_URL_WITH_SCROLL}
                        actionId='home__info_card_search_course_btn'
                        onClick={() => { handleClick() }} />
                </Grid>
                <Grid item md={12} lg={4}>
                    <InfoCard
                        title={t('infoCard2.title')}
                        description={t("infoCard2.description")}
                        imageUrl={incentiveStep2}
                        buttonText={buttonText2}
                        actionId='home__info_card_submit_claim_btn'
                        link={GET_PAID_URL}
                         />
                </Grid>
                <Grid className="info-cards-nobutton" item md={12} lg={4}>
                    <InfoCard
                        
                        title={t('infoCard3.title')}
                        description={t("infoCard3.description")}
                        // actionId='home__info_card_get_connected_btn'
                        imageUrl={incentiveStep3}
                        
                        // buttonText={buttonText3}
                        // link={CAREGIVER_COACHING_URL}
                        />
                </Grid>
            </Grid>
        </Container>
    );
}

