import { Session } from "../model/session";

const otherFormatTranslations = [
    "In Person",
    "En persona",
    "Անձամբ",
    "대면",
    "Лично",
    "Trực tiếp",
    "面对面",
    "Hybrid",
    "Híbrido",
    "Հիբրիդ",
    "잡종",
    "Гибридный",
    "Hỗn hợp",
    "杂交种",
];

export const formatName = (name: string) => {
    const nameProcessed = name.toLowerCase().replace(/[&#,+()/|$~%.'":*?<>{}-]/g, "");
    const array: string[] = nameProcessed.split(" ");
    array.forEach((e) => {
        if (e == "") {
            array.splice(array.indexOf(e), 1);
        }
    });

    return array.join("-");
};

const getOnlineUrl = (language: string) => {
    let onlineUrl = "";

    if (language == "zh") {
        onlineUrl = "在线的";
    }
    if (language == "es") {
        onlineUrl = "en-línea";
    }
    if (language == "en") {
        onlineUrl = "online";
    }
    if (language == "hy") {
        onlineUrl = "առցանց";
    }
    if (language == "ko") {
        onlineUrl = "온라인";
    }
    if (language == "ru") {
        onlineUrl = "Онлайн";
    }
    if (language == "vi") {
        onlineUrl = "trực-tuyến";
    }
    return onlineUrl;
};
export const formatTrainingFormat = (formats: string[], language: string) => {
    const newFormats: string[] = [];
    formats.forEach((format) => {
        if (!otherFormatTranslations.includes(format)) {
            if (!newFormats.includes(getOnlineUrl(language))) {
                newFormats.push(getOnlineUrl(language));
            }
        }
    });
    return newFormats.join("-");
};

const generateUrl = (
    courseName: string,
    courseNumber: string,
    sessions: Session[],
    formats: string[],
    language: string
) => {
    const formattedCourseName = formatName(courseName);
    const formattedTrainingFormat = formatTrainingFormat(formats, language);

    return `./${formattedCourseName}/${courseNumber}/${formattedTrainingFormat}`;
};

export default generateUrl;
