import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface searchParams {
  searchText: string;
  incentiveTrack: string | null;
  statuses: string[];
  categories: string[];
  topics: string[];
  formats: string | null;
  counties: string[];
  languages: string[];
  days: string[];
  startTime: string;
  endTime: string;  
  paging: boolean;
  page: number;
  pageSize: number;
  difficulties: string[];
  trainingFocuses: string[];
  occupationFocuses: string[];
}

const initialStateValue: searchParams = {
  searchText: "",
  incentiveTrack: null,
  statuses: [],
  categories: [],
  topics: [],
  formats: null,
  counties: [],
  languages: [],
  days: [],
  startTime: "",
  endTime: "",
  paging: true,
  page: 1,
  pageSize: 12,
  difficulties: [],
  trainingFocuses: [],
  occupationFocuses: []
}


const searchParameterSlice = createSlice({
  name: 'searchParameters',
  initialState: initialStateValue,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setincentiveTrack: (state, action: PayloadAction<string | null>) => {
      state.incentiveTrack = action.payload;
    },
    setStatus: (state, action: PayloadAction<string[]>) => {
      state.statuses = action.payload;
    },
    setCategory: (state, action: PayloadAction<string[]>) => {
      state.categories = action.payload;
    },
    setTopic: (state, action: PayloadAction<string[]>) => {
      state.topics = action.payload;
    },
    setFormat: (state, action: PayloadAction<string | null>) => {
      state.formats = action.payload;
    },
    setCounty: (state, action: PayloadAction<string[]>) => {
      state.counties = action.payload;
    },
    setLanguage: (state, action: PayloadAction<string[]>) => {
      state.languages = action.payload;
    },
    setDay: (state, action: PayloadAction<string[]>) => {
      state.days = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setTimeRange: (state, action: PayloadAction<string[]>) => {
      state.startTime = action.payload[0];
      state.endTime = action.payload[1];
    },
    setDifficulty: (state, action: PayloadAction<string[]>) => {
      state.difficulties = action.payload;
    },
    setTrainingFocus: (state, action: PayloadAction<string[]>) => {
      state.trainingFocuses = action.payload;
    },
    setOccupationFocus: (state, action: PayloadAction<string[]>) => {
      state.occupationFocuses = action.payload;
    },
    resetSearchParameters: (state) => {
      state.searchText = "";
      state.statuses = [];
      state.categories = [];
      state.counties = [];
      state.topics = [];
      state.formats = null;
      state.incentiveTrack = null;
      state.languages = [];
      state.days = [];
      state.startTime = "";
      state.endTime = "";
      state.page = 1;
      state.paging= true,
      state.pageSize = 12;
      state.difficulties = [];
      state.trainingFocuses = [];
      state.occupationFocuses = [];
    },
  }
});

export const { setSearchText, setincentiveTrack, setStatus, setCategory, setTopic, setFormat, setCounty, setLanguage, setDay, setTimeRange, setPage, setDifficulty, setTrainingFocus, setOccupationFocus, resetSearchParameters } = searchParameterSlice.actions;
export const getSearchParameters = (state: RootState) => state.searchParameters
export default searchParameterSlice.reducer;