// import { Box, Typography } from "@mui/material";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import noResult from "../../images/noResult.svg";

export default function NoResult() {
  const translationNamespace = ["caregiverTrainingsTranslations"];
  const { t } = useTranslation(translationNamespace);
  const noResultFound = t("noResultFound")
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#EEEEEE",
      }}
      data-testid="no-result-component"
    >
      <Box sx={{ textAlign: "center" }}>
        <img src={noResult} width="115" alt={noResultFound}/> <br />
        <span style={{ fontWeight: "600" }}>
          {noResultFound}
        </span> <br /> {t("noResultFoundDescription")}
      </Box>
    </Box>
  );
}
