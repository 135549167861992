import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Course } from '../../model/course';
import { RootState } from '../store';

interface courseType {
  course: Course;
}

const initialStateValue: courseType = {
  course: {
    _id: "",
    name: "",
    number: "",
    description: "",
    formattedDescription: "",
    primaryPathway: "",
    isActive: true,
    incentiveEligibility: "",
    registrationInstructions: [],
    sessions: [],
    isAvailable: true,
    durationHours: '',
    topics: [],
    sessionAvailabilityDescription: "",
    sessionAvailability: true,
    prerequisticsDesc: "",
    trainingPartnerName: "",
    incentiveTypeName: "",        
    difficulties: [],
    trainingFocuses: [],
    occupationFocuses: []
  }
}


const courseSlice = createSlice({
  name: 'course',
  initialState: initialStateValue,
  reducers: {
    setCourse: (state, action: PayloadAction<Course>) => {
      state.course = action.payload;
    }
  }
});

export const { setCourse } = courseSlice.actions;
export const getCourse = (state: RootState) => state.course
export default courseSlice.reducer;