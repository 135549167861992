import { Trans, useTranslation } from "react-i18next";
import {CAREGIVER_TRAININGS_URL, INCENTIVE_CLAIM_URL } from "../constant/path";

import {Helmet} from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../components/common/PageTitle";
import { Button, Container, Grid, Link, Typography } from "@mui/material";

export default function FAQSinglePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const paths = location.pathname.split('/').filter(i => i)
  const camelCase = paths[paths.length-1].toLowerCase().trim().split(/[.\-_\s]/g).reduce((string, word) => string + word[0].toUpperCase() + word.slice(1));
  const translationNamespace = ['FAQSinglePageTranslations'];
  const { t } = useTranslation(translationNamespace);
  const metaDesc: string = t(`${camelCase}.metaDesc`)

  const answer: string[] = []
  for (let index = 1; index <= Number(t(`${camelCase}.paragraphCount`)); index++) {
    answer.push(t(`${camelCase}.answerPara${index}`))
  }
  
  return (
    <>
      <Helmet>
        <title>{t(`${camelCase}.browserTitle`)}</title>
        <meta name="description" content={metaDesc} />
      </Helmet>
      <div className="main-content-wrapper">
        <PageTitle title={t("title")} />
        <Container
          maxWidth="xl"
          sx={{ p: '40px', paddingTop: '0' }}
          className="incentive-container"
          id="eligible"
        >
          <Grid container className="eligible-section">
          <Typography variant='h2' className='faq-question'>{t(`${camelCase}.question`)}</Typography> 
            {answer.map((para, i) =>
              <Typography variant="body2" key={i} sx={{marginBottom: "30px"}}>
                <Trans
                  components={{
                    Link1: (
                      <Link
                        href={INCENTIVE_CLAIM_URL}
                        target="_blank"
                        color="inherit"
                      />
                    ),
                    Link2: (
                      <Link
                        onClick={()=>{navigate(`/${CAREGIVER_TRAININGS_URL}`)}}
                        color="inherit"
                      />
                    )
                  }}
                >{para}</Trans>
              </Typography>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            sx={{ justifyContent: "center", alignItems: "center" }}
            className="connect-info-section"
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate("/");
              }}
            >
              {t('goToHome')}
            </Button>
          </Grid>
        </Container>
      </div>
    </>
  );
}
