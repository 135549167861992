import { Link } from "react-router-dom";
import HighlighterWrapper from "../../common/HighlighterWrapper";
import { extractContent } from "../utils/common";
import { FAQ_FORMAT_URL, FAQ_DURATION_URL } from "../../../constant/path";
import { useTranslation } from "react-i18next";

const CQTemplate8 = (props: { searchText: string }) => {
  const { searchText } = props;
  const searchTexts = searchText.split(" ");
  const translationNamespace = ["faq"];
  const { t } = useTranslation(translationNamespace);
  const ac10 = t("cat3.details.ac10");
  const ac10001 = t("cat3.details.ac10001");

  const extractor1 = extractContent(ac10);
  const extractorParaS1 = extractor1 === null || extractor1.input === undefined ? [] : extractor1.input.split(extractor1[0]);
  const extractorPara1 = extractorParaS1[0];
  const linkDisplayText1 = extractor1 === null ? "" : extractor1[1];

  const extractor2 = extractContent(ac10001);
  const extractorParaS2 = extractor2 === null || extractor2.input === undefined ? [] : extractor2.input.split(extractor2[0]);
  const extractorPara2 = extractorParaS2[0];
  const linkDisplayText2 = extractor2 === null ? "" : extractor2[1];

  return (
    <p>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={extractorPara1?.toString()} />
      <Link to={`../${FAQ_FORMAT_URL}`}>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={linkDisplayText1?.toString()} />
      </Link>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={extractorPara2?.toString()} />
      <Link to={`../${FAQ_DURATION_URL}`}>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={linkDisplayText2?.toString()} />
      </Link>
    </p>
  );
};

export default CQTemplate8;
