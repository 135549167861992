import { Button, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ResultCount from "./ResultCount";


function FilterHead(props: { direction: string, filterCount: number, resultCount: number | null,  onClick: any, isLoaderVisible: boolean| null}) {
  const translationNamespace = ['caregiverTrainingsTranslations']
  const { t } = useTranslation(translationNamespace);

  const {direction, filterCount, resultCount, onClick, isLoaderVisible} = props


  const applyFilterCount = (count: number)=>{  
      if (count > 0){
        return ` (${count})`
      }
      else{
        return ""
      }
  }

  return (
    <Grid container sx={{ alignItems:"center", paddingLeft:"0px", marginBottom:"20px"}} data-testid="filter-head-component">
      <Grid item sm={2} className="filter-collapse-btn">
        <Button aria-label="toogle" variant="contained" color="primary" sx={{borderRadius:"6px", minWidth:"34px", width:"34px", height:"44px"}} size="small" onClick={() => onClick()}>
          {direction=="right" ? <ArrowRightIcon /> : <ArrowLeftIcon />}
        </Button>
      </Grid>        
      <Grid item sm={5}>          
        <Typography variant="h6" sx={{fontWeight:"700", fontSize:"24px"}}>{t('filters') + applyFilterCount(filterCount)}</Typography>
      </Grid>
      <Grid item sm={5} sx={{ textAlign:"right", fontWeight:"400", fontSize:"18px"}}>
      {resultCount != null && (
        <>
          {
            !isLoaderVisible && (<ResultCount count={resultCount} />)
          }
        </>              
      )}
      </Grid>
    </Grid>
  );
}

export default FilterHead;
