import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface ApiStatus {
  filtersApi: boolean | null;
  searchApi: boolean | null;
  courseDetailApi: boolean | null
}

const initialStateValue: ApiStatus = {
  filtersApi: true,
  searchApi: null,
  courseDetailApi: true
}


const apiStatusSlice = createSlice({
  name: 'apiStatus',
  initialState: initialStateValue,
  reducers: {
    setFiltersApi: (state, action: PayloadAction<boolean>) => {
      state.filtersApi = action.payload;
    },
    setSearchApi: (state, action: PayloadAction<boolean>) => {
      state.searchApi = action.payload;
    },
    setCourseDetailApi: (state, action: PayloadAction<boolean>) => {
      state.courseDetailApi = action.payload;
    },
  }
});

export const { setFiltersApi, setSearchApi, setCourseDetailApi } = apiStatusSlice.actions;
export const getApiStatus = (state: RootState) => state.apiStatus
export default apiStatusSlice.reducer;