import { Box, Button, Grid, Link, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { COURSE_CATALOG_URL_WITH_SCROLL, ABOUT_CAL_GROWS } from "../../constant/path";
import { resetSearchParameters, setincentiveTrack } from "../../store/reducer/searchParameters";
import TrackLevelCard from "./TrackLevelCard";

export default function TrackLevelWrapper() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const translationNamespace = ["getPaidTranslations"];
  const { t } = useTranslation(translationNamespace);
  const requirementLevel1: [] | any = t("incentiveTrack1Level1.requirements");
  const requirementLevel2: [] | any = t("incentiveTrack1Level2.requirements");
  const requirementLevel3: [] | any = t("incentiveTrack1Level3.requirements");
  const track2RequirementLevel1: [] | any = t(
    "incentiveTrack2Level1.requirements"
  );
  const track2RequirementLevel2: [] | any = t(
    "incentiveTrack2Level2.requirements"
  );
  const track2RequirementLevel3: [] | any = t(
    "incentiveTrack2Level3.requirements"
  );

  const handleClick = (value: string) => {
    dispatch(resetSearchParameters())
    dispatch(setincentiveTrack(value))
    navigate(`../${COURSE_CATALOG_URL_WITH_SCROLL}`, {state: {searchCourses: true, keepPrevState: true}})
  }

  return (
    <Grid container spacing={4} sx={{ paddingBottom: "50px" }}>
      <Grid item sm={12} md={6}>
        <Box
          border={2}
          sx={{
            width: "100%",
            borderColor: "#202020",
            backgroundColor: "#fff",
            padding: "45px",
            boxSizing: "border-box",
          }}
          className="level-info-card track-card"
        >
          <Typography
            variant="h4"
            sx={{ paddingBottom: "50px", textAlign: "center", width: "100%" }}
          >
            {t("incentiveTrack1.track")} {t("incentiveTrack1.number")}:{" "}
            {t("incentiveTrack1.title")}
          </Typography>
          <TrackLevelCard
            level={1}
            title={t("incentiveTrack1Level1.title")}
            description={t("incentiveTrack1Level1.description")}
            requirements={requirementLevel1}
            amount="$30/hr Up to $1500"
            condition={t("incentiveTrack1Level1.condition")}
          />
          <hr
            style={{
              marginBottom: "50px",
              marginTop: "50px",
              borderWidth: "1px",
              borderColor: "#000",
            }}
          />
          <TrackLevelCard
            level={2}
            title={t("incentiveTrack1Level2.title")}
            requirements={requirementLevel2}
            description={t("incentiveTrack1Level2.description")}
            amount="$500"
            condition={""}
          />
          <hr
            style={{
              marginBottom: "50px",
              marginTop: "50px",
              borderWidth: "1px",
              borderColor: "#000",
            }}
          />
          <TrackLevelCard
            level={3}
            title={t("incentiveTrack1Level3.title")}
            requirements={requirementLevel3}
            description={t("incentiveTrack1Level3.description")}
            amount="$500"
            condition={""}
          />
          <Grid item sm={12} sx={{ paddingTop: "50px" }}>
            <Button
              variant="contained"
              id="view_track1_course_btn"
              sx={{
                display: "flex",
                margin: "0 auto",
                padding: "6px 35px",
              }}
              onClick= {()=>handleClick(t("track1.btnValue"))}
            >
              {t("track1.btnText")}
            </Button>
          </Grid>
          <Grid item sm={12} sx={{ paddingTop: "70px" }}>
            <Typography variant="body2" className="font-italic">
            <Trans
              components={{
                Link: (
                  <Link
                    href={`./${ABOUT_CAL_GROWS}`}
                    color="inherit"
                    id="get_paid_track1_learn_more_btn"
                  />
                ),
                bold: <strong />
              }}
            >{t("track1Condition.text")}</Trans>
            
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid item sm={12} md={6}>
        <Box
          border={2}
          sx={{
            width: "100%",
            borderColor: "#202020",
            backgroundColor: "#fff",
            padding: "45px",
            boxSizing: "border-box",
          }}
          className="level-info-card"
        >
          <Typography
            variant="h4"
            sx={{ paddingBottom: "50px", textAlign: "center", width: "100%" }}
          >
            {t("incentiveTrack2.track")} {t("incentiveTrack2.number")}:{" "}
            {t("incentiveTrack2.title")}
          </Typography>
          <TrackLevelCard
            level={1}
            title={t("incentiveTrack2Level1.title")}
            requirements={track2RequirementLevel1}
            description={t("incentiveTrack2Level1.description")}
            amount="$500*"
            condition={t("incentiveTrack2Level1.condition")}
          />
          <hr
            style={{
              marginBottom: "50px",
              marginTop: "50px",
              borderWidth: "1px",
              borderColor: "#000",
            }}
          />
          <TrackLevelCard
            level={2}
            title={t("incentiveTrack2Level2.title")}
            requirements={track2RequirementLevel2}
            description={t("incentiveTrack2Level2.description")}
            amount="$500"
            condition={""}
          />
          <hr
            style={{
              marginBottom: "50px",
              marginTop: "40px",
              borderWidth: "1px",
              borderColor: "#000",
            }}
          />
          <TrackLevelCard
            level={3}
            title={t("incentiveTrack2Level3.title")}
            requirements={track2RequirementLevel3}
            description={t("incentiveTrack2Level3.description")}
            amount="$2000"
            condition={""}
          />
          <Grid item sm={12} sx={{ paddingTop: "50px" }}>
            <Button
              variant="contained"
              id="view_track2_course_btn"
              sx={{
                display: "flex",
                margin: "0 auto",
                padding: "6px 35px",
              }}
              onClick= {()=>handleClick(t("track2.btnValue"))}
            >
              {t("track2.btnText")}
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
}
