import { useTranslation } from "react-i18next";
import HighlighterWrapper from "../../common/HighlighterWrapper";
import ParagraphWrapper from "../../common/ParagraphWrapper";
import { extractContent } from "../utils/common";
import { CAREGIVER_TRAININGS_URL } from "../../../constant/path";
import { Link } from "react-router-dom";

const C2Q4 = (props: { searchText: string; }) => {
    const { searchText } = props;
    const searchTexts = searchText.split(" ");
    const translationNamespace = ["faq"];
    const { t } = useTranslation(translationNamespace);
    const ab1a1p1 = t("cat2.details.ab1a1p1");
    const ab1a1p2 = t("cat2.details.ab1a1p2");
    const ab1a1p3 = t("cat2.details.ab1a1p3");
    const ab1a1p4 = t("cat2.details.ab1a1p4");
    const ab1a1p5 = t("cat2.details.ab1a1p5");

    const extractor1 = extractContent(ab1a1p1);
    const extractorParaS1 = extractor1 === null || extractor1.input === undefined ? [] : extractor1.input.split(extractor1[0]);
    const extractorPara1 = extractorParaS1[0];
    const linkDisplayText1 = extractor1 === null ? "" : extractor1[1];
  
    const extractor2 = extractContent(ab1a1p2);
    const extractorParaS2 = extractor2 === null || extractor2.input === undefined ? [] : extractor2.input.split(extractor2[0]);
    const extractorPara2 = extractorParaS2[0];
    const linkDisplayText2 = extractor2 === null ? "" : extractor2[1];

    return (
      <>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={extractorPara1?.toString()} />
      <Link to={`../`}>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={linkDisplayText1?.toString()} />
      </Link>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={extractorPara2?.toString()} />
      <Link to={`../${CAREGIVER_TRAININGS_URL}`}>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={linkDisplayText2?.toString()} />
      </Link>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={ab1a1p3?.toString()} />
      <ParagraphWrapper searchTexts={searchTexts} textToHighlight={ab1a1p4?.toString()} />
      <ParagraphWrapper searchTexts={searchTexts} textToHighlight={ab1a1p5?.toString()} />
      </>
    );
  };


export default C2Q4