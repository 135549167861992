import { Link } from "react-router-dom";
import HighlighterWrapper from "../../common/HighlighterWrapper";
import ParagraphWrapper from "../../common/ParagraphWrapper";
import { extractContent } from "../utils/common";
import RadioOption from "./common/RadioOption";

const C5Q002 = (props: {
  searchText: string;
  paragraph1: string;
  paragraph2: string;
  paragraph3: string;
  paragraph4: string;
  paragraph5: string;
  paragraph6: string;
  href: string;
}) => {
  const { searchText, paragraph1, paragraph2, paragraph3, paragraph4, paragraph5, paragraph6, href } = props;
  const searchTexts = searchText.split(" ");

  const extractor = extractContent(paragraph6);
  const footer = extractor=== null || extractor.input=== undefined ? [] : extractor.input.split(extractor[0]);
  const footerp1 = footer[0];
  const footerp2 = footer[1];
  const linkDisplayText = extractor=== null ? "" : extractor[1];

  return (
    <>
      <ParagraphWrapper searchTexts={searchTexts} textToHighlight={paragraph1?.toString()} />
      <RadioOption searchTexts={searchTexts} option1={paragraph2} option2={paragraph3} option3={paragraph4} option4={paragraph5}/>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={footerp1?.toString()} />
      <Link to={href}>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={linkDisplayText.toString()} />
      </Link>

      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={footerp2?.toString()} />
    </>
  );
};

export default C5Q002;
