import { Link } from "react-router-dom";
import HighlighterWrapper from "../../common/HighlighterWrapper";
import { extractContent } from "../utils/common";
import { FAQ_IS_FREE_URL } from "../../../constant/path";
import { useTranslation } from "react-i18next";

const CQTemplate10 = (props: { searchText: string }) => {
  const { searchText } = props;
  const searchTexts = searchText.split(" ");
  const translationNamespace = ["faq"];
  const { t } = useTranslation(translationNamespace);
  const ac1a1 = t("cat3.details.ac1a1");

  const extractor = extractContent(ac1a1);
  const extractorParaS1 = extractor === null || extractor.input === undefined ? [] : extractor.input.split(extractor[0]);
  const extractorPara1 = extractorParaS1[0];
  const linkDisplayText1 = extractor === null ? "" : extractor[1];

  return (
    <p>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={extractorPara1?.toString()} />
      <Link to={`../${FAQ_IS_FREE_URL}`}>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={linkDisplayText1?.toString()} />
      </Link>
    </p>
  );
};

export default CQTemplate10;
