import { useTranslation } from "react-i18next";
import HighlighterWrapper from "../../common/HighlighterWrapper";
import ParagraphWrapper from "../../common/ParagraphWrapper";
import { extractContent } from "../utils/common";
import { Link } from "react-router-dom";
import { FAQ_WHAT_URL } from "../../../constant/path";

const C1Q1 = (props: { searchText: string; paragraph: string; }) => {
  const { searchText, paragraph } = props;
  const searchTexts = searchText.split(" ");
  const translationNamespace = ["faq"];
  const { t } = useTranslation(translationNamespace);
  const aa1p1 = t("cat1.details.aa1p1");
  const aa1p2 = t("cat1.details.aa1p2");
  const aa1p3 = t("cat1.details.aa1p3");
  const aa1p4 = t("cat1.details.aa1p4");
  // const aa1p5 = t("cat1.details.aa1p5");
  const aa1p6 = t("cat1.details.aa1p6");
  const aa1p7 = t("cat1.details.aa1p7");

  const extractor = extractContent(paragraph);
  const extractorPara = extractor === null || extractor.input === undefined ? [] : extractor.input.split(extractor[0]);
  const extractorPara1 = extractorPara[0];
  const linkDisplayText = extractor === null ? "" : extractor[1];

  return (
    <>
      <ParagraphWrapper searchTexts={searchTexts} textToHighlight={aa1p1?.toString()} />
      <ParagraphWrapper searchTexts={searchTexts} textToHighlight={aa1p2?.toString()} />
      <ParagraphWrapper searchTexts={searchTexts} textToHighlight={aa1p3?.toString()} />
      <ul>
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={aa1p4?.toString()} />
        </li>
        {/* <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={aa1p5?.toString()} />
        </li> */}
        <li>
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={aa1p6?.toString()} />
        </li>
      </ul>
      <ParagraphWrapper searchTexts={searchTexts} textToHighlight={aa1p7?.toString()} />
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={extractorPara1} />
      <Link to={`../${FAQ_WHAT_URL}`}>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={linkDisplayText?.toString()} />
      </Link>
    </>
  );
};

export default C1Q1;
