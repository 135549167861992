import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export default function Popup(props: {instructions: string[], open: boolean, onClick: any}) {
    const translationNamespace = ['courseDetailTranslations']
    const { t } = useTranslation(translationNamespace);
    
    const {instructions, open, onClick} = props
    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        onClick(false);
    };

    return (
        <div>
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="how-to-register"
                className='register-modal-session'
                data-testid="popup-component"
            >
                <DialogTitle id="how-to-register" sx={{ color:"#8C4799", fontSize:"24px", textAlign:"center" }}>
                    {t('howToRegister')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color:"#000", lineHeight:"24px" }}>  
                        {
                            instructions.map((item: string, index)=>{
                                return (<div style={{marginTop: '10px'}} key={index} dangerouslySetInnerHTML={{__html: item}} />)
                            })
                        }                      
                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus sx={{ color:"#000", fontSize:"18px", textAlign:"center", margin:"0 auto", textDecoration:"underline", p:"2px 20px" }}>                        
                        {t('close')} X
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}