import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useState } from 'react';
import { TimeRange } from '../../model/timeRange';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import timezone from '../../hooks/timezone';
import timeToMins from '../../hooks/timeToMins';
import minsToTime from '../../hooks/minsToTime';

export default function RangeSlider(props: { onChange: any, selectedValues: string[] }) {
    const translationNamespace = ['caregiverTrainingsTranslations']
    const { t } = useTranslation(translationNamespace);
    const { onChange, selectedValues } = props


    // const [range, setRange] = useState<number[]>([480 + timezone().offset, 1080 + timezone().offset]);
    // const [offset, setOffset] = useState<number>(timezone().offset);
    // const [tz, setTz] = useState<string>(timezone().timezone);

    const [range] = useState<number[]>([480, 1260]);
    // const [offset] = useState<number>(timezone().offset);
    // const [tz] = useState<string>(timezone().timezone);

    const getCustomMarks = () => {
        const customMarks = range.map((r) => ({ value: r, label: minsToTime(r) }))
        return customMarks;
    }

    const handleChange = (event: Event, newValue: number | number[]) => {
        const time = (newValue as number[]).map((value) => minsToTime(value))

        const timeSlider: TimeRange = {
            startTime: time[0],
            endTime: time[1],
        }

        onChange(timeSlider)
    };

    const timeRangeTranslation = t('timeRange')

    return (
        <Box sx={{ width: "100%", marginTop: "5px" }} data-testid="range-slider-component">
            <Slider
                getAriaLabel={() => timeRangeTranslation}
                value={[(timeToMins(selectedValues[0] != "" ? selectedValues[0] : "8:00am")), (timeToMins(selectedValues[1] != "" ? selectedValues[1] : "9:00pm"))]}
                onChange={handleChange}
                valueLabelDisplay="auto"
                valueLabelFormat={minsToTime}
                getAriaValueText={minsToTime}
                marks={getCustomMarks()}
                max={range[1]}
                min={range[0]}
                step={30}
            />
            <Typography variant='caption'>{`${t('timeDisplay')}`}</Typography>
        </Box>
    );
}
