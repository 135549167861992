import { Course } from "../model/course";
import { CourseSearchBody } from "../model/courseSearchBody";
import { Filters } from "../model/filters";
import { SearchResult } from "../model/searchResult";
import apiClient from "./apiClient";

const searchCourses = (data: CourseSearchBody) => apiClient.post<SearchResult>("/course/search", data);
const getCourse = (number: string) => apiClient.get<Course>("/course/get/" + number);
const getPreviewCourse = (number: string) => apiClient.get<Course>("/course-preview/get/" + number);
const getFilters = () => apiClient.get<Filters>("/course/search/filter");
const createCourse = (data: any) => apiClient.post<any>("/course/add", data);
const createCoursePreview = (data: any) => apiClient.post<any>("/course-preview/add", data);
const deleteCourse = (data: any) => apiClient.post<any>("/course/delete", data);

export default {
    searchCourses,
    getFilters,
    getCourse,
    getPreviewCourse,
    createCourse,
    createCoursePreview,
    deleteCourse
};
