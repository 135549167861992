import { useState, useEffect } from 'react'

export const useDebounce = (value: any, milliSeconds: number, defaultState: any = '') => {
	const [debouncedValue, setDebouncedValue] = useState(defaultState)
	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), milliSeconds || 1000)

		return () => {
			clearTimeout(timer)
		};

	}, [value, milliSeconds])

	return debouncedValue
};