import { Link } from "react-router-dom";
import { PROD_COURSE_CATALOG_PATH } from "../../../constant/siteUrl";
import HighlighterWrapper from "../../common/HighlighterWrapper";
import { extractContent } from "../utils/common";
import { CAREGIVER_TRAININGS_URL } from "../../../constant/path";

const CQTemplate3 = (props: { searchText: string; paragraph: string }) => {
  const { searchText, paragraph } = props;
  const searchTexts = searchText.split(" ");

  const coursecatalogUrl = PROD_COURSE_CATALOG_PATH;


  const extractor = extractContent(paragraph);
  const paragraph1: string[] = extractor=== null || extractor.input=== undefined ? [] : extractor.input.split(extractor[0]);

  return (
    <p data-testid="CQTemplate3-component">
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={paragraph1[0]?.toString()} />
      <Link to={`../${CAREGIVER_TRAININGS_URL}`}>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={coursecatalogUrl?.toString()} />
      </Link>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={paragraph1[1]?.toString()} />
    </p>
  );
};

export default CQTemplate3;
