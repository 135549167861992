const circlePatternFourFaqVerticle = () => {
  return (
    <div className="banner-patterns">
      <svg
        width="276"
        height="379"
        viewBox="0 0 276 379"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.2" clipPath="url(#clip0_231_902)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M104.039 273.871C94.8292 273.871 87.3583 281.334 87.3583 290.544C87.3583 299.754 94.8248 307.217 104.039 307.217C113.254 307.217 120.72 299.754 120.72 290.544C120.72 281.334 113.254 273.871 104.039 273.871ZM104.039 278.874C97.5896 278.874 92.3639 284.097 92.3639 290.544C92.3639 296.99 97.5896 302.213 104.039 302.213C110.489 302.213 115.715 296.99 115.715 290.544C115.715 284.097 110.489 278.874 104.039 278.874Z"
            fill="#E87722"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.4226 325.271C55.7089 325.271 50.263 330.715 50.263 337.425C50.263 344.136 55.7089 349.579 62.4226 349.579C69.1364 349.579 74.5823 344.136 74.5823 337.425C74.5823 330.715 69.1364 325.271 62.4226 325.271ZM62.4226 328.919C57.7208 328.919 53.9083 332.73 53.9083 337.43C53.9083 342.129 57.7208 345.94 62.4226 345.94C67.1245 345.94 70.937 342.129 70.937 337.43C70.937 332.73 67.1245 328.919 62.4226 328.919Z"
            fill="#E87722"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.4227 361.984C57.7209 361.984 53.9084 365.795 53.9084 370.494C53.9084 375.194 57.7209 379.004 62.4227 379.004C67.1246 379.004 70.9371 375.194 70.9371 370.494C70.9371 365.795 67.1246 361.984 62.4227 361.984ZM62.4227 364.536C59.1341 364.536 56.4662 367.203 56.4662 370.49C56.4662 373.777 59.1341 376.443 62.4227 376.443C65.7114 376.443 68.3793 373.777 68.3793 370.49C68.3793 367.203 65.7114 364.536 62.4227 364.536Z"
            fill="#E87722"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.8061 361.984C16.1042 361.984 12.2917 365.795 12.2917 370.494C12.2917 375.194 16.1042 379.004 20.8061 379.004C25.5079 379.004 29.3204 375.194 29.3204 370.494C29.3204 365.795 25.5079 361.984 20.8061 361.984Z"
            fill="#E87722"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.8061 325.271C14.0923 325.271 8.6464 330.715 8.6464 337.425C8.6464 344.136 14.0923 349.579 20.8061 349.579C27.5198 349.579 32.9657 344.136 32.9657 337.425C32.9657 330.715 27.5198 325.271 20.8061 325.271Z"
            fill="#60CDB8"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.8061 273.871C11.5961 273.871 4.12511 281.334 4.12511 290.544C4.12511 299.754 11.5917 307.217 20.8061 307.217C30.0205 307.217 37.4871 299.754 37.4871 290.544C37.4871 281.334 30.0205 273.871 20.8061 273.871Z"
            fill="#426DA9"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M102.34 75.7041C111.55 75.7041 119.021 68.241 119.021 59.0311C119.021 49.8211 111.554 42.358 102.34 42.358C93.1255 42.358 85.6589 49.8211 85.6589 59.0311C85.6589 68.241 93.1255 75.7041 102.34 75.7041ZM102.34 70.7009C108.79 70.7009 114.015 65.4776 114.015 59.0311C114.015 52.5845 108.79 47.3613 102.34 47.3613C95.8903 47.3613 90.6645 52.5845 90.6645 59.0311C90.6645 65.4776 95.8903 70.7009 102.34 70.7009Z"
            fill="#E87722"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M104.039 217.902C92.5489 217.902 83.2333 227.213 83.2333 238.698C83.2333 250.183 92.5489 259.495 104.039 259.495C115.53 259.495 124.845 250.183 124.845 238.698C124.845 227.213 115.53 217.902 104.039 217.902ZM104.039 224.142C95.996 224.142 89.476 230.659 89.476 238.698C89.476 246.738 95.996 253.255 104.039 253.255C112.083 253.255 118.603 246.738 118.603 238.698C118.603 230.659 112.083 224.142 104.039 224.142Z"
            fill="#E87722"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M102.56 93.1164C91.0697 93.1164 81.754 102.428 81.754 113.913C81.754 125.398 91.0697 134.709 102.56 134.709C114.051 134.709 123.366 125.398 123.366 113.913C123.366 102.428 114.051 93.1164 102.56 93.1164ZM102.56 99.3561C94.5168 99.3561 87.9967 105.873 87.9967 113.913C87.9967 121.952 94.5168 128.469 102.56 128.469C110.603 128.469 117.124 121.952 117.124 113.913C117.124 105.873 110.603 99.3561 102.56 99.3561Z"
            fill="#FBDE40"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.4227 217.902C50.9322 217.902 41.6165 227.213 41.6165 238.698C41.6165 250.183 50.9322 259.495 62.4227 259.495C73.9131 259.495 83.2288 250.183 83.2288 238.698C83.2288 227.213 73.9131 217.902 62.4227 217.902Z"
            fill="#EF6079"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M104.039 134.709C92.5489 134.709 83.2333 144.02 83.2333 155.505C83.2333 166.99 92.5489 176.301 104.039 176.301C115.53 176.301 124.845 166.99 124.845 155.505C124.845 144.02 115.53 134.709 104.039 134.709Z"
            fill="#EF6079"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.8061 217.902C9.31565 217.902 3.8147e-06 227.213 3.8147e-06 238.698C3.8147e-06 250.183 9.31565 259.495 20.8061 259.495C32.2966 259.495 41.6122 250.183 41.6122 238.698C41.6122 227.213 32.2966 217.902 20.8061 217.902ZM20.8061 224.142C12.7628 224.142 6.24271 230.659 6.24271 238.698C6.24271 246.738 12.7628 253.255 20.8061 253.255C28.8494 253.255 35.3695 246.738 35.3695 238.698C35.3695 230.659 28.8494 224.142 20.8061 224.142Z"
            fill="#60CDB8"
          />
          <path
            d="M83.2333 197.102C83.2333 208.587 92.5485 217.898 104.039 217.898C115.53 217.898 124.845 208.587 124.845 197.102C124.845 185.616 115.53 176.305 104.039 176.305C92.5485 176.305 83.2333 185.616 83.2333 197.102Z"
            fill="#FBDE40"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.4227 176.305C50.9322 176.305 41.6165 185.617 41.6165 197.102C41.6165 208.587 50.9322 217.898 62.4227 217.898C73.9131 217.898 83.2288 208.587 83.2288 197.102C83.2288 185.617 73.9131 176.305 62.4227 176.305ZM62.4227 182.545C54.3793 182.545 47.8593 189.062 47.8593 197.102C47.8593 205.141 54.3793 211.658 62.4227 211.658C70.466 211.658 76.9861 205.141 76.9861 197.102C76.9861 189.062 70.466 182.545 62.4227 182.545Z"
            fill="#426DA9"
          />
        </g>
        <defs>
          <clipPath id="clip0_231_902">
            <rect
              width="379"
              height="276"
              fill="white"
              transform="translate(276) rotate(90)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default circlePatternFourFaqVerticle;
