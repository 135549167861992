import { useTranslation } from "react-i18next";
import HighlighterWrapper from "../../common/HighlighterWrapper";
import ParagraphWrapper from "../../common/ParagraphWrapper";

const C2Q1 = (props: { searchText: string; }) => {
    const { searchText } = props;
    const searchTexts = searchText.split(" ");
    const translationNamespace = ["faq"];
    const { t } = useTranslation(translationNamespace);
    const aa1p1 = t("cat2.details.ab1p1");
    const aa1p2 = t("cat2.details.ab1p2");
    const aa1p3 = t("cat2.details.ab1p3");
    const aa1p4 = t("cat2.details.ab1p4");
    const aa1p5 = t("cat2.details.ab1p5");
    const aa1p6 = t("cat2.details.ab1p6");
    const aa1p7 = t("cat2.details.ab1p7");
    const aa1p8 = t("cat2.details.ab1p8");
    const ab1p8001 = t("cat2.details.ab1p8001");
    const ab1p8002 = t("cat2.details.ab1p8002");
    const aa1p9 = t("cat2.details.ab1p9");
    const aa1p10 = t("cat2.details.ab1p10");
  
    return (
      <>
        <ParagraphWrapper searchTexts={searchTexts} textToHighlight={aa1p1?.toString()} />
        <ul>
        <li>
            <HighlighterWrapper searchTexts={searchTexts} textToHighlight={aa1p2?.toString()} />
          </li>
          <li>
            <HighlighterWrapper searchTexts={searchTexts} textToHighlight={aa1p3?.toString()} />
          </li>
          <li>
            <HighlighterWrapper searchTexts={searchTexts} textToHighlight={aa1p4?.toString()} />
          </li>
          <li>
            <HighlighterWrapper searchTexts={searchTexts} textToHighlight={aa1p5?.toString()} />
          </li>
          <li>
            <HighlighterWrapper searchTexts={searchTexts} textToHighlight={aa1p6?.toString()} />
          </li>
          <li>
            <HighlighterWrapper searchTexts={searchTexts} textToHighlight={aa1p7?.toString()} />
          </li>
          <li>
            <HighlighterWrapper searchTexts={searchTexts} textToHighlight={aa1p8?.toString()} />
          </li>
          <li>
            <HighlighterWrapper searchTexts={searchTexts} textToHighlight={ab1p8001?.toString()} />
          </li>
          <li>
            <HighlighterWrapper searchTexts={searchTexts} textToHighlight={ab1p8002?.toString()} />
          </li>
          <li>
            <HighlighterWrapper searchTexts={searchTexts} textToHighlight={aa1p9?.toString()} />
          </li>
        </ul>
        <ParagraphWrapper searchTexts={searchTexts} textToHighlight={aa1p10?.toString()} />
      </>
    );
  };

export default C2Q1