import { useTranslation } from "react-i18next";
import { List } from "reselect/es/types";
import EmptyData from "../common/EmptyData";
import QuestionCategory, { QuestionAnswer } from "./QuestionCategory";
import CirclePatternFourFaqVerticle from "../common/circlePatternFourFaqVerticle/circlePatternFourFaqVerticle";
import { Container } from "@mui/system";
import C1Q1 from "./Answer/C1Q1";
import C1Q3 from "./Answer/C1Q3";
import C2Q1 from "./Answer/C2Q1";
// import C2Q2 from "./Answer/C2Q2";
import C2Q3 from "./Answer/C2Q3";
import C2Q4 from "./Answer/C2Q4";
import CQTemplate1 from "./Answer/CQTemplate1";
import CQTemplate3 from "./Answer/CQTemplate3";
import CQTemplate5 from "./Answer/CQTemplate5";
import CQTemplate6 from "./Answer/CQTemplate6";
import CQTemplate7 from "./Answer/CQTemplate7";
import CQTemplate8 from "./Answer/CQTemplate8";
import CQTemplate9 from "./Answer/CQTemplate9";
import CQTemplate10 from "./Answer/CQTemplate10";
import CQTemplate11 from "./Answer/CQTemplate11";
import C6Q from "./Answer/C6Q";
import CQTemplate2 from "./Answer/CQTemplate2";
import { IHSS_PROGRAM_URL } from "../../constant/siteUrl";
// import { MAIL_TO } from "../../constant/other";
import {
  // coachingType,
  coursesType,
  eligibilityType,
  gettingProgramInformationType,
  incentiveType,
} from "../../constant/faqCategory";
import CQTemplate4 from "./Answer/CQTemplate4";
import C5Q002 from "./Answer/C5Q002";
import C5Q003 from "./Answer/C5Q003";
// import { ABOUT_CAL_GROWS, INCENTIVE_CLAIM_URL, GET_PAID_URL } from "../../constant/path";
import { ABOUT_CAL_GROWS, INCENTIVE_CLAIM_URL } from "../../constant/path";
import C5Q006 from "./Answer/C5Q006";
import C5Q005 from "./Answer/C5Q005";
import C2Q014 from "./Answer/C2Q014";
import C5Q13 from "./Answer/C5Q13";

const removeHtmlTags = (str: string) => str.replace(/<[^>]+>/g, "");

const QuestionCategoryList = (props: { selectedCategory: string[]; searchText: string }) => {
  const { selectedCategory, searchText } = props;
  const translationNamespace = ["faq"];
  const { t } = useTranslation(translationNamespace);
  const gettingProgramInformation = t("cat1.name");
  const eligibility = t("cat2.name");
  const courses = t("cat3.name");
  // const coaching = t("cat4.name");
  const incentivetypes = t("cat5.name");
  // const mailto = MAIL_TO;
  const ihssProgramUrl = IHSS_PROGRAM_URL;

  const qa1 = t("cat1.details.qa1");
  const aa1p1 = t("cat1.details.aa1p1");
  const aa1p2 = t("cat1.details.aa1p2");
  const aa1p3 = t("cat1.details.aa1p3");
  const aa1p4 = t("cat1.details.aa1p4");
  const aa1p5 = t("cat1.details.aa1p5");
  const aa1p6 = t("cat1.details.aa1p6");
  const aa1p7 = t("cat1.details.aa1p7");
  const aa1p8 = t("cat1.details.aa1p8");
  const aa1 = [aa1p1, aa1p2, aa1p3, aa1p4, aa1p5, aa1p6, aa1p7, aa1p8].join(" ");
  const qa200 = t("cat1.details.qa200");
  const aa200 = t("cat1.details.aa200");
  const qa2 = t("cat1.details.qa2");
  const aa2 = t("cat1.details.aa2");
  const qa3 = t("cat1.details.qa3");
  const aa3 = t("cat1.details.aa3");

  const qb1 = t("cat2.details.qb1");
  const ab1p1 = t("cat2.details.ab1p1");
  const ab1p2 = t("cat2.details.ab1p2");
  const ab1p3 = t("cat2.details.ab1p3");
  const ab1p4 = t("cat2.details.ab1p4");
  const ab1p5 = t("cat2.details.ab1p5");
  const ab1p6 = t("cat2.details.ab1p6");
  const ab1p7 = t("cat2.details.ab1p7");
  const ab1p8 = t("cat2.details.ab1p8");
  const ab1p8001 = t("cat2.details.ab1p8001");
  const ab1p8002 = t("cat2.details.ab1p8002");
  const ab1p9 = t("cat2.details.ab1p9");
  const ab1p10 = t("cat2.details.ab1p10");
  const ab1 = [ab1p1, ab1p2, ab1p3, ab1p4, ab1p5, ab1p6, ab1p7, ab1p8, ab1p8001, ab1p8002, ab1p9, ab1p10].join(" ");

  const qb1a1 = t("cat2.details.qb1a1");
  const ab1a1p1 = t("cat2.details.ab1a1p1");
  const ab1a1p2 = t("cat2.details.ab1a1p2");
  const ab1a1p3 = t("cat2.details.ab1a1p3");
  const ab1a1p4 = t("cat2.details.ab1a1p4");
  const ab1a1p5 = t("cat2.details.ab1a1p5");
  const ab1a1 = [ab1a1p1, ab1a1p2, ab1a1p3, ab1a1p4, ab1a1p5].join(" ");

  const qb2 = t("cat2.details.qb2");
  const ab2p1 = t("cat2.details.ab2p1");
  const ab2p2 = t("cat2.details.ab2p2");
  const ab2 = [ab2p1, ab2p2].join(" ");
  const qb001 = t("cat2.details.qb001");
  const ab001 = t("cat2.details.ab001");
  const qb3 = t("cat2.details.qb3");
  const ab3 = t("cat2.details.ab3");
  const qb4 = t("cat2.details.qb4");
  const ab4 = t("cat2.details.ab4");
  const qb5 = t("cat2.details.qb5");
  const ab5 = t("cat2.details.ab5");
  const qb6 = t("cat2.details.qb6");
  const ab6p1 = t("cat2.details.ab6p1");
  const ab6p2 = t("cat2.details.ab6p2");
  const ab6 = [ab6p1, ab6p2].join(" ");
  const qb7 = t("cat2.details.qb7");
  const ab7 = t("cat2.details.ab7");
  const qb8 = t("cat2.details.qb8");
  const ab8 = t("cat2.details.ab8");
  const qb9 = t("cat2.details.qb9");
  const ab9 = t("cat2.details.ab9");
  const qd1 = t("cat2.details.qd1");
  const ad1 = t("cat2.details.ad1");
  const qd2 = t("cat2.details.qd2");
  const ad2 = t("cat2.details.ad2");
  const qc0q1 = t("cat3.details.qc0q1");
  const ac0q1 = t("cat3.details.ac0q1");
  const ac0q2 = t("cat3.details.ac0q2");
  const ac0 = [ac0q1, ac0q2].join(" ");

  const qc0p1 = t("cat3.details.qc0p1");
  const ac0p1 = t("cat3.details.ac0p1");
  
  const qc1 = t("cat3.details.qc1");
  const ac1000 = t("cat3.details.ac1");
  const ac1001 = t("cat3.details.ac1001");
  const ac1002 = t("cat3.details.ac1002");
  const ac1 = [ac1000, ac1001, ac1002].join(" ");
  const qc1a1 = t("cat3.details.qc1a1");
  const ac1a1 = t("cat3.details.ac1a1");
  const qc2 = t("cat3.details.qc2");
  const ac2 = t("cat3.details.ac2");
  const qc3 = t("cat3.details.qc3");
  const ac3 = t("cat3.details.ac3");
  const qc4 = t("cat3.details.qc4");
  const ac4000 = t("cat3.details.ac4");
  const ac4001 = t("cat3.details.ac4001");
  const ac4 = [ac4000, ac4001].join(" ");
  const qc5 = t("cat3.details.qc5");
  const ac5 = t("cat3.details.ac5");
  const qc6 = t("cat3.details.qc6");
  const ac6000 = t("cat3.details.ac6");
  const ac6001 = t("cat3.details.ac6001");
  const ac6 = [ac6000, ac6001].join(" ");
  const qc7 = t("cat3.details.qc7");
  const ac7 = t("cat3.details.ac7");
  const qc8 = t("cat3.details.qc8");
  const ac8 = t("cat3.details.ac8");
  const qc9 = t("cat3.details.qc9");
  const ac9 = t("cat3.details.ac9");
  const qc10 = t("cat3.details.qc10");
  const ac10000 = t("cat3.details.ac10");
  const ac10001 = t("cat3.details.ac10001");
  const ac10 = [ac10000, ac10001].join(" ");
  const qc11 = t("cat3.details.qc11");
  const ac11 = t("cat3.details.ac11");
  const qc12 = t("cat3.details.qc12");
  const ac12p1 = t("cat3.details.ac12p1");
  const ac12p2 = t("cat3.details.ac12p2");
  const ac12 = [ac12p1, ac12p2].join(" ");
  const qc13 = t("cat3.details.qc13");
  const ac13p1 = t("cat3.details.ac13p1");
  const ac13p2 = t("cat3.details.ac13p2");
  const ac13 = [ac13p1, ac13p2].join(" ");
  const qc14 = t("cat3.details.qc14");
  const ac14 = t("cat3.details.ac14");
  const qc15 = t("cat3.details.qc15");
  const ac15 = t("cat3.details.ac15");

  // const qd1 = t("cat4.details.qd1");
  // const ad1p1 = t("cat4.details.ad1p1");
  // const ad1p2 = t("cat4.details.ad1p2");
  // const ad1 = [ad1p1, ad1p2].join(" ");
  // const qd2 = t("cat4.details.qd2");
  // const ad2 = t("cat4.details.ad2");
  // const qd3 = t("cat4.details.qd3");
  // const ad3 = t("cat4.details.ad3");
  // const qd4 = t("cat4.details.qd4");
  // const ad4 = t("cat4.details.ad4");
  // const qd5 = t("cat4.details.qd5");
  // const ad5 = t("cat4.details.ad5p1");

  const qe001 = t("cat5.details.qe001");
  const ae001p1 = t("cat5.details.ae001p1");
  const ae001p2 = t("cat5.details.ae001p2");
  const ae001p3 = t("cat5.details.ae001p3");
  const ae001p4 = t("cat5.details.ae001p4");
  const ae001 = [ae001p1, ae001p2, ae001p3, ae001p4].join(" ");
  const qe002 = t("cat5.details.qe002");
  const ae002p1 = t("cat5.details.ae002p1");
  const ae002p2 = t("cat5.details.ae002p2");
  const ae002p3 = t("cat5.details.ae002p3");
  const ae002p4 = t("cat5.details.ae002p4");
  const ae002p5 = t("cat5.details.ae002p5");
  const ae002p6 = t("cat5.details.ae002p6");
  const ae002 = [ae002p1, ae002p2, ae002p3, ae002p4 ,ae002p5, ae002p6].join(" ");
  const qe003 = t("cat5.details.qe003");
  const ae003p1 = t("cat5.details.ae003p1");
  const ae003p2 = t("cat5.details.ae003p2");
  const ae003p3 = t("cat5.details.ae003p3");
  const ae003p4 = t("cat5.details.ae003p4");
  const ae003p5 = t("cat5.details.ae003p5");
  const ae003 = [ae003p1, ae003p2, ae003p3, ae003p4, ae003p5].join(" ");
  const qe2 = t("cat5.details.qe2");
  const ae2p1 = t("cat5.details.ae2p1");
  const ae2p2 = t("cat5.details.ae2p2");
  const ae2p3 = t("cat5.details.ae2p3");
  const ae2 = [ae2p1, ae2p2, ae2p3].join(" ");
  const qe3 = t("cat5.details.qe3");
  const ae3p1 = t("cat5.details.ae3p1");
  const ae3p2 = t("cat5.details.ae3p2");
  const ae3 = [ae3p1, ae3p2].join(" ");
  const qe4 = t("cat5.details.qe4");
  const ae4 = t("cat5.details.ae4");
  const ae4001 = t("cat5.details.ae4001");
  const qe5 = t("cat5.details.qe5");
  const ae5 = t("cat5.details.ae5");
  const qe6 = t("cat5.details.qe6");
  const ae6 = t("cat5.details.ae6");
  const qe7 = t("cat5.details.qe7");
  const ae7 = t("cat5.details.ae7");
  const qe8 = t("cat5.details.qe8");
  const ae8 = t("cat5.details.ae8");
  const qe9 = t("cat5.details.qe9");
  const ae9p1 = t("cat5.details.ae9p1");
  const ae9p2 = t("cat5.details.ae9p2");
  const ae9p3 = t("cat5.details.ae9p3");
  const ae9 = [ae9p1, ae9p2, ae9p3].join(" ");
  const qe10 = t("cat5.details.qe10");
  const ae10 = t("cat5.details.ae10");
  const qe11 = t("cat5.details.qe11");
  const ae11 = t("cat5.details.ae11");
  const qe12 = t("cat5.details.qe12");
  const ae12 = t("cat5.details.ae12");
  const qe13 = t("cat5.details.qe13");
  const ae13 = t("cat5.details.ae13");

  const gettingProgramInformationCategoryData = [
    { question: qa1, answer: aa1, component: <C1Q1 searchText={searchText} paragraph={aa1p8} /> },
    { question: qa200, answer: aa200 },
    { question: qa2, answer: aa2 },
    {
      question: qa3,
      answer: removeHtmlTags(aa3),
      component: <C1Q3 searchText={searchText} paragraph={aa3} />,
    },
  ];
  const eligibilityData = [
    { question: qb1, answer: ab1, component: <C2Q1 searchText={searchText} /> },
    { question: qb1a1, answer: ab1a1, component: <C2Q4 searchText={searchText} /> },
    { question: qb2, answer: removeHtmlTags(ab2), component: <C2Q3 searchText={searchText} /> },
    { question: qb001, answer: ab001 },
    { question: qb3, answer: ab3 },
    { question: qb4, answer: ab4 },
    { question: qb5, answer: ab5 },
    {
      question: qb6,
      answer: ab6,
      component: <CQTemplate1 searchText={searchText} paragraph1={ab6p1} paragraph2={ab6p2} />,
    },
    { question: qb7, answer: ab7 },
    { question: qb8, answer: ab8 },
    { question: qb9, answer: ab9 },
    { question: qd1, answer: ad1 },
    { 
      question: qd2, 
      answer: ad2,
      component:  <C2Q014 searchText={searchText} />,
    },
  ];
  const coursesCategoryData = [
    { question: qc0q1, answer: removeHtmlTags(ac0), component: <CQTemplate11 searchText={searchText} /> },
    { question: qc0p1, answer: removeHtmlTags(ac0p1), component: <CQTemplate9 searchText={searchText} /> },
    { question: qc1, answer: removeHtmlTags(ac1), component: <CQTemplate5 searchText={searchText} /> },
    { question: qc1a1, answer: ac1a1, component: <CQTemplate10 searchText={searchText} /> },
    { question: qc2, answer: ac2 },
    { question: qc3, answer: ac3 },
    { question: qc4, answer: ac4, component: <CQTemplate6 searchText={searchText} /> },
    { question: qc5, answer: ac5 },
    { question: qc6, answer: removeHtmlTags(ac6), component: <CQTemplate7 searchText={searchText} /> },
    { question: qc7, answer: removeHtmlTags(ac7), component: <C6Q searchText={searchText} paragraph1={ac7} /> },
    { question: qc8, answer: removeHtmlTags(ac8), component: <C6Q searchText={searchText} paragraph1={ac8} /> },
    { question: qc9, answer: removeHtmlTags(ac9), component: <CQTemplate3 searchText={searchText} paragraph={ac9} /> },
    {
      question: qc10,
      answer: removeHtmlTags(ac10),
      component: <CQTemplate8 searchText={searchText} />,
    },
    { question: qc11, answer: ac11 },
    {
      question: qc12,
      answer: removeHtmlTags(ac12),
      component: <C6Q searchText={searchText} paragraph1={ac12p1} paragraph2={ac12p2} />,
    },
    {
      question: qc13,
      answer: removeHtmlTags(ac13),
      component: <C6Q searchText={searchText} paragraph1={ac13p1} paragraph2={ac13p2} />,
    },
    { question: qc14, answer: ac14 },
    { question: qc15, answer: ac15 },
  ];
  // const coachingData = [
  //   {
  //     question: qd1,
  //     answer: ad1,
  //     component: <CQTemplate1 searchText={searchText} paragraph1={ad1p1} paragraph2={ad1p2} />,
  //   },
  //   { question: qd2, answer: ad2 },
  //   { question: qd3, answer: ad3 },
  //   { question: qd4, answer: ad4 },
  //   {
  //     question: qd5,
  //     answer: ad5,
  //     component: <C1Q3 searchText={searchText} paragraph={ad5} />,
  //   },
  // ];
  const incentivetypesData = [
    {
      question: qe001,
      answer: ae001,
      component: (
        <CQTemplate4
          searchText={searchText}
          paragraph1={ae001p1}
          paragraph2={ae001p2}
          paragraph3={ae001p3}
          paragraph4={ae001p4}
        />
      ),
    },
    {
      question: qe002,
      answer: removeHtmlTags(ae002),
      component: (
        <C5Q002
          searchText={searchText}
          paragraph1={ae002p1}
          paragraph2={ae002p2}
          paragraph3={ae002p3}
          paragraph4={ae002p4}
          paragraph5={ae002p5}
          paragraph6={ae002p6}
          href={`../${ABOUT_CAL_GROWS}`}
        />
      ),
    },
    {
      question: qe003,
      answer: ae003,
      component: (
        <C5Q003
          searchText={searchText}
          paragraph1={ae003p1}
          paragraph2={ae003p2}
          paragraph3={ae003p3}
          paragraph4={ae003p4}
          paragraph5={ae003p5}
        />
      ),
    },
    {
      question: qe2,
      answer: ae2,
      component: (
        <CQTemplate2
          searchText={searchText}
          paragraph1={ae2p1}
          paragraph2={ae2p2}
          paragraph2Style={{ fontStyle: "italic" }}
          paragraph3={ae2p3}
          href={ihssProgramUrl}
        />
      ),
    },
    {
      question: qe3,
      answer: ae3,
      component: <C5Q005 searchText={searchText} paragraph1={ae3p1} paragraph2={ae3p2} />,
    },
    {
      question: qe4,
      answer: ae4,
      component: <C5Q006 searchText={searchText} paragraph1={ae4} paragraph2={ae4001} href={INCENTIVE_CLAIM_URL} />,
    },
    {
      question: qe5,
      answer: ae5,
    },
    { question: qe6, answer: ae6 },
    { question: qe7, answer: ae7 },
    { question: qe8, answer: ae8 },
    {
      question: qe9,
      answer: ae9,
      component: <CQTemplate2 searchText={searchText} paragraph1={ae9p1} paragraph2={ae9p2} paragraph3={ae9p3} />,
    },
    { question: qe10, answer: ae10 },
    {
      question: qe11,
      answer: ae11,
    },
    { question: qe12, answer: ae12 },
    { 
      question: qe13,
      answer: removeHtmlTags(ae13),
      component: <C5Q13 searchText={searchText} />,
    },
  ];

  const filtedGettingProgramInformationData = filterData(searchText, gettingProgramInformationCategoryData);
  const filtedCoursesData = filterData(searchText, coursesCategoryData);
  const filtedEligibilityData = filterData(searchText, eligibilityData);
  // const filtedCoachingData = filterData(searchText, coachingData);
  const filtedIncentivetypesData = filterData(searchText, incentivetypesData);

  const showEmptyData = (): boolean => {
    if (selectedCategory.length === 0) {
      return (
        filtedGettingProgramInformationData.length === 0 &&
        filtedCoursesData.length === 0 &&
        // filtedCoachingData.length === 0 &&
        filtedIncentivetypesData.length === 0
      );
    }
    if (selectedCategory.includes(gettingProgramInformationType)) {
      return filtedGettingProgramInformationData.length === 0;
    }
    if (selectedCategory.includes(eligibilityType)) {
      return filtedEligibilityData.length === 0;
    }
    if (selectedCategory.includes(coursesType)) {
      return filtedCoursesData.length === 0;
    }
    // if (selectedCategory.includes(coachingType)) {
    //   return filtedCoachingData.length === 0;
    // }
    if (selectedCategory.includes(incentiveType)) {
      return filtedIncentivetypesData.length === 0;
    }
    return false;
  };
  return (
    <div data-testid="question-category-list-component" className="faq-according-area position-relative">
      <Container>{showEmptyData() && <EmptyData />}</Container>
      <QuestionCategory
        type={gettingProgramInformationType}
        category={gettingProgramInformation}
        details={filtedGettingProgramInformationData}
        selectedCategory={selectedCategory}
        searchText={searchText}
      />
      <QuestionCategory
        type={eligibilityType}
        category={eligibility}
        details={filtedEligibilityData}
        selectedCategory={selectedCategory}
        searchText={searchText}
      />
      <QuestionCategory
        type={coursesType}
        category={courses}
        details={filtedCoursesData}
        selectedCategory={selectedCategory}
        searchText={searchText}
      />
      {/* <QuestionCategory
        type={coachingType}
        category={coaching}
        details={filtedCoachingData}
        selectedCategory={selectedCategory}
        searchText={searchText}
      /> */}
      <QuestionCategory
        type={incentiveType}
        category={incentivetypes}
        details={filtedIncentivetypesData}
        selectedCategory={selectedCategory}
        searchText={searchText}
      />
      <CirclePatternFourFaqVerticle />
    </div>
  );
};

export default QuestionCategoryList;
const filterData = (searchText: string, dataList: List<QuestionAnswer>) => {
  const keyArray = searchText.split(" ");
  return keyArray.length > 0
    ? dataList.filter((i) =>
        keyArray.some(
          (k) => i.question.toLowerCase().includes(k.toLowerCase()) || i.answer.toLowerCase().includes(k.toLowerCase())
        )
      )
    : dataList;
};
