import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Course from "./views/Course";
import {
    COURSE_CATALOG_DETAILS_URL,
    CAREGIVER_TRAININGS_URL,
    ROOT_URL,
    NOT_FOUND_URL,
    GET_PAID_URL,
    CAREGIVER_COACHING_URL,
    FAQ_URL,
    ABOUT_CAL_GROWS,
    INNOVATION_FUND_URL,
    COURSE_CATALOG_DETAILS_URL_WITH_FORMAT,
    TRAINING_PARTNERS_URL,
    COURSE_CATALOG_URL,
    INCENTIVE_PAYMENT_URL,
    CAREER_COACHING_URL,
    FAQ_ELIGIBILITY_URL,
    FAQ_DURATION_URL,
    FAQ_FINANCIAL_COMPENSATION_URL,
    FAQ_FORMAT_URL,
    FAQ_HOW_TO_APPLY_URL,
    FAQ_HOW_URL,
    FAQ_IS_FREE_URL,
    FAQ_LANGUAGE_URL,
    FAQ_WHAT_COURSES_URL,
    FAQ_WHAT_URL,
    FAQ_WORTH_URL,
    CREATE_COURSE,
    SURVEY_URL,
    SURVEY_ES_URL,
    MANAGE_COURSES,
    EDIT_COURSE,
    COURSE_PREVIEW_DETAILS_URL,
    COURSE_PREVIEW_DETAILS_URL_WITH_FORMAT,
} from "./constant/path";
import NotFound from "./views/NotFound";
import "./translations/i18n";
import Home from "./views/Home";
import GetPaid from "./views/GetPaid";
import CaregiverTrainings from "./views/CaregiverTrainings";
import FAQPage from "./views/FAQPage";
import CaregiverCoaching from "./views/CaregiverCoaching";
import { ThemeProvider } from "@mui/material/styles";
import calDefaultTheme from "./theme/calDefaultTheme";
import AboutCalGrows from "./views/AboutCalGrows";
import InnovationFund from "./views/InnovationFund";
import { Navigate } from "react-router-dom";
import FAQSinglePage from "./views/FAQSinglePage";

import React from "react";
import AppLayout from "./layout/AppLayout";
import CreateCourse from "./views/CreateCourse";
import Survey from "./views/Survey";
import SurveyEs from './views/SurveyEs';
import ManageCourses from "./views/ManageCourses";
import EditCourse from "./views/EditCourse";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import PreviewCourse from "./views/PreviewCourse";

Amplify.configure({
    Auth: {
        Cognito: {
            //  Amazon Cognito User Pool ID
            userPoolId: "us-east-1_BMXGQMpL7",
            // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
            userPoolClientId: "17elthkaid81s960hgfe7n8jl0",
            // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
            // identityPoolId: '1lmkcrvtfmovvsb2k0g57i3894con5ur8uo67qecqikrbjucsek7',
            // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
            // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
            signUpVerificationMethod: "code", // 'code' | 'link'
            loginWith: {
                // OPTIONAL - Hosted UI configuration
                oauth: {
                    domain: "https://calgrows.auth.us-east-1.amazoncognito.com",
                    scopes: ["email", "openid"],
                    redirectSignIn: ["https://www.calgrows.org/manage-courses"],
                    redirectSignOut: ["https://www.calgrows.org"],
                    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
                },
            },
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={calDefaultTheme}>
            <Router>
                <Routes>
                    <Route
                        // path='/:lang?/*'
                        path="/"
                        element={<AppLayout />}
                    >
                        <Route path={ROOT_URL} element={<Home />} />
                        <Route path={CAREGIVER_TRAININGS_URL} element={<CaregiverTrainings />} />
                        <Route path={COURSE_CATALOG_DETAILS_URL} element={<Course />} />
                        <Route path={COURSE_CATALOG_DETAILS_URL_WITH_FORMAT} element={<Course />} />
                        <Route path={GET_PAID_URL} element={<GetPaid />} />
                        <Route path={CAREGIVER_COACHING_URL} element={<CaregiverCoaching />} />
                        <Route path={FAQ_URL} element={<FAQPage />} />
                        <Route path={ABOUT_CAL_GROWS} element={<AboutCalGrows />} />
                        <Route path={INNOVATION_FUND_URL} element={<InnovationFund />} />
                        <Route path={NOT_FOUND_URL} element={<NotFound />} />
                        <Route path={SURVEY_URL} element={<Survey />} />
                        <Route path={SURVEY_ES_URL} element={<SurveyEs />} />
            {/* Create course  page*/}
                        <Route path={CREATE_COURSE} element={<CreateCourse />} />
                        <Route path={MANAGE_COURSES} element={<ManageCourses />} />
                        <Route path={EDIT_COURSE} element={<EditCourse />} />
                        <Route path={COURSE_PREVIEW_DETAILS_URL} element={<PreviewCourse />} />
                        <Route path={COURSE_PREVIEW_DETAILS_URL_WITH_FORMAT} element={<PreviewCourse />} />

                        {/* Set redirection for old links */}
                        <Route path={TRAINING_PARTNERS_URL} element={<Navigate to={`/${ABOUT_CAL_GROWS}`} />} />
                        <Route path={COURSE_CATALOG_URL} element={<Navigate to={`/${CAREGIVER_TRAININGS_URL}`} />} />
                        <Route path={INCENTIVE_PAYMENT_URL} element={<Navigate to={`/${GET_PAID_URL}`} />} />
                        <Route path={CAREER_COACHING_URL} element={<Navigate to={`/${CAREGIVER_COACHING_URL}`} />} />
                        <Route path={FAQ_ELIGIBILITY_URL} element={<FAQSinglePage />} />
                        <Route path={FAQ_DURATION_URL} element={<FAQSinglePage />} />
                        <Route path={FAQ_FINANCIAL_COMPENSATION_URL} element={<FAQSinglePage />} />
                        <Route path={FAQ_FORMAT_URL} element={<FAQSinglePage />} />
                        <Route path={FAQ_HOW_TO_APPLY_URL} element={<FAQSinglePage />} />
                        <Route path={FAQ_HOW_URL} element={<FAQSinglePage />} />
                        <Route path={FAQ_IS_FREE_URL} element={<FAQSinglePage />} />
                        <Route path={FAQ_LANGUAGE_URL} element={<FAQSinglePage />} />
                        <Route path={FAQ_WHAT_COURSES_URL} element={<FAQSinglePage />} />
                        <Route path={FAQ_WHAT_URL} element={<FAQSinglePage />} />
                        <Route path={FAQ_WORTH_URL} element={<FAQSinglePage />} />
                    </Route>
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
