// import { Grid, Container, Typography, Button } from "@mui/material";
import { Grid, Container, Typography, Button } from "@mui/material";
// import { useNavigate } from "react-router";
import MobilePatternBottom from "../common/mobilePatternBottom/MobilePatternBottom";
import MobilePatternTop from "../common/mobilePatternTop/MobilePatternTop";

export default function InfoContainer(props: {
  title: string;
  // description: string;
  buttonText: string;
  url: string;
  buttonId?: string
}) {
  const { title, buttonText, url, buttonId } = props;
  // const navigate = useNavigate();
  return (
    <div className="bg-aquamarine" data-testid="info-container-component">
      <Container
        maxWidth="xl"
        sx={{ p: "100px", backgroundColor: "#60CDB8" }}
        className="career-coach-area"
      >
        <Grid
          item
          sm={12}
          sx={{ justifyContent: "center", alignItems: "center" }}
          className="connect-info-section"
        >
          <Typography variant="h3">{title}</Typography>
          <Button
            id={buttonId}
            variant="contained"
            onClick={() => { window.open('https://forms.gle/yJktnaCDFuJyrrgb6','_blank');}}
          >
            {buttonText}
          </Button>
        </Grid>
        <div className="info-connect-patterns-bottom">
          <MobilePatternBottom />
        </div>
        <div className="info-connect-patterns-top">
          <MobilePatternTop />
        </div>
      </Container>
    </div>
  );
}
