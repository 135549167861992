import { Container } from '@mui/material';
import './training-programs.scss';
import { useTranslation, Trans } from 'react-i18next';
import ProgramCard from './ProgramCard';

const TrainingPrograms = () => {
  const translationNamespace = ['aboutCalGrowsTranslations'];
  const { t } = useTranslation(translationNamespace);

  const programCardOrgs = (i: number) => {
    switch (i) {
      case 1:
        return [
          "Agency on Aging, Area 4",
          "AgeWell PACE",
          "Always Best Care",
          "Alzheimer's Los Angeles",
          "Bend the Arc: A Jewish Partnership for Justice*",
          "Camarillo Health Care District",
          "Canary Health",
          "Dignity Health Global Education",
          "Diversity in Health Training Institute",
          "DrKumo Inc.",
          "El Sol",
          "Embodied Labs, Inc.",
          "Family Circle, Inc. Oxnard Family Circle ADHC",
          "Futuro Health",
          "Givers Health",
          "Healthipeople Corp.",
          "Home Instead",
          "Jewish Family Service of Los Angeles",
          "Marin Center for Independent Living",
          "New Sunrise an adult Day Health Care, Inc.",
          "Northern California Nursing Academy",
          "ONEgeneration",
          "OPICA",
          "Partners in Care Foundation",
          "Pima Medical Institute",
          "Pools of Hope*",
          "Poway Adult School",
          "San Diego State University Research Foundation",
          "Sebastopol Area Senior Center",
          "South Bay Home Care, Inc.",
          "Southern Caregiver Resource Center",
          "The Arc of California*",
          "Visiting Angels of the Central Coast",
          "More Coming Soon"
        ];
      case 2:
        return [
          "AgeWell PACE",
          "Anaheim Adult Daycare",
          "Cardea Health",
          "East Bay Innovations",
          "Eskaton EPI",
          "Home of Guiding Hands",
          "Humboldt Senior Resource Center Inc.",
          "Institute on Aging",
          "Mayfair Adult Daycare",
          "Mentia DTx, Inc.",
          "Napa Valley Support Services",
          "Nevvon USA LLC",
          "New Sunrise an adult Day Health Care, Inc.",
          "On Lok Senior Health Services",
          "ONEgeneration",
          "San Ysidro Health",
          "Senior Helpers East San Diego",
          "South Bay Home Care, Inc.",
          "Path Point",
          "Peninsula Volunteers, Inc.",
          "The Arc of Butte County",
          "The Arc of San Diego",
          "Vision y Compromiso",
          "Vocational Innovations Inc.", 
          "Welbe Health", 
          "Whittier Adult Day Health Center", 
          "More coming soon"
        ];
      case 3:
        return [
          'Center for Care Advancement', 'Homebridge', ];
      default:
        return [];
    }
  };

  const renderProgramCards = () => {
    const cards = [];
    for (let i = 1; i <= 3; i++) {
      cards.push(
        <ProgramCard
          key={`programCard${i}`}
          title={t(`programCard${i}.title`)}
          description={t(`programCard${i}.description`)}
          footnote={t(`programCard${i}.footnote`)}
          cardPrograms={programCardOrgs(i)}
        />
      );
    }
    return cards;
  };

  return (
    <div className="training-programs" data-testid="training-programs">
      <Container maxWidth="xl">
        <h2 className="training-programs__title">
          {t('trainingPrograms.title')}
        </h2>
        <div className="training-programs__content">
          <Trans components={{ li: <li />, ul: <ul />, p: <p />, b: <b /> }}>
            {t('trainingPrograms.description')}
          </Trans>
        </div>
        {renderProgramCards()}
      </Container>
    </div>
  );
};

export default TrainingPrograms;
