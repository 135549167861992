import Course from "./Course";
import { withAuthenticator, WithAuthenticatorProps } from "@aws-amplify/ui-react";
import { Button } from "@mui/material";

function PreviewCourse({ signOut, user }: WithAuthenticatorProps) {
    return (
        <>
            <p className="title-block">
                Hello! <Button onClick={signOut}>Sign out</Button>
            </p>
            <Course preview />
        </>
    );
}
export default withAuthenticator(PreviewCourse);
