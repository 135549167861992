import { useTranslation } from "react-i18next";
import ParagraphWrapper from "../../common/ParagraphWrapper";

const C5Q13 = (props: { searchText: string }) => {
  const { searchText } = props;
  const searchTexts = searchText.split(" ");
  const translationNamespace = ["faq"];
  const { t } = useTranslation(translationNamespace);
  const ae13 = t("cat5.details.ae13");
  return (
    <>
    <ParagraphWrapper searchTexts={searchTexts} textToHighlight={ae13?.toString()} />
    </>
  );
};

export default C5Q13;
