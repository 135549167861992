import { Container, Typography } from "@mui/material";
import "./about-main-components.scss"
import { useTranslation } from 'react-i18next';

interface ComponentItemProps {
    no: number;
    title: string;
    description: string;
}

const ComponentItem = (props: ComponentItemProps) => {
    const { no, title, description } = props
    return (
        <div className="component-item">
            <Typography variant="h5" sx={{ margin: '1.5rem 0' }}><span>{no}.</span> {title}</Typography>
            <Typography>{description}</Typography>
        </div>
    )
}



const AboutMainComponents = () => {
    const translationNamespace = ['aboutCalGrowsTranslations'];
    const { t } = useTranslation(translationNamespace);

    const renderComponentList = () => {
        const componentItems = []
        for (let i = 1; i <= 2; i++) {
            componentItems.push(<ComponentItem
                no={i}
                key={i}
                title={t(`mainComponents.item${i}.title`)}
                description={t(`mainComponents.item${i}.description`)}
            />)
        }
        return componentItems
    }

    return (
        <div className="about-main-components" data-testid="about-main-components">
            <Container maxWidth="xl">
                <h2 className="about-main-components__title">
                    {t('mainComponents.title')}
                </h2>
                <div className="components-list">
                    {renderComponentList()}
                </div>
                {/* <Typography>{t('mainComponents.footer.description')}</Typography> */}
            </Container>
        </div>
    )
}

export default AboutMainComponents;