import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface BreadcrumbValue {
  lastBreadcrumbItem: string
}

const initialStateValue: BreadcrumbValue = {
  lastBreadcrumbItem: ""
}


const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState: initialStateValue,
  reducers: {
    setLastBreadcrumbItem: (state, action: PayloadAction<string>) => {
      state.lastBreadcrumbItem = action.payload;
    }
  }
});

export const { setLastBreadcrumbItem } = breadcrumbSlice.actions;
export const getBreadcrumb = (state: RootState) => state.breadcrumb
export default breadcrumbSlice.reducer;