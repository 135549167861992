import { useState } from 'react';
import { Trans } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { INCENTIVE_PAYMENT_URL_WITH_SCROLL } from '../../constant/path';
import './program-card.scss';

export type ProgramCardProps = {
  cardPrograms: string[];
  title: string;
  footnote: string;
  description: string;
};
const ProgramCard = ({
  cardPrograms,
  title,
  description,
  footnote,
}: ProgramCardProps) => {
  const [showContent, setShowContent] = useState(true);

  const handleClick = () => {
    setShowContent(!showContent);
  };

  return (
    <div className="program-card" data-testid="program-card">
      <button
        aria-label={`click to ${
          showContent ? 'hide' : 'show'
        } ${title} content`}
        className="program-card__button-toggle"
        onClick={() => handleClick()}
      >
        <Trans>{title}</Trans>

        <div
          className={showContent ? 'arrow' : 'arrow arrow--down'}
          aria-label={showContent ? 'arrow up' : 'arrow down'}
        />
      </button>
      <div
        data-testid="program-card-content"
        className={
          showContent
            ? 'program-card__content program-card__content--show'
            : 'program-card__content'
        }
      >
        <p>
          <Trans
            components={{
              HashLink: <HashLink smooth to={`../${INCENTIVE_PAYMENT_URL_WITH_SCROLL}`}/>,
            }}
          >
            {description}
          </Trans>
        </p>
        <ul className="program-card__ul">
          {cardPrograms.map((program, index) => {
            return (
              <li className="program-card__li" key={index}>
                {program}
              </li>
            );
          })}
        </ul>
        <p className="program-card__footnote">
          <Trans>{footnote}</Trans>
        </p>
      </div>
    </div>
  );
};

export default ProgramCard;
