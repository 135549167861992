import { Grid, Container } from '@mui/material';
import { useTranslation } from "react-i18next";
import InfoCard from '../common/InfoCard';
import setGoal from '../../images/setGoal.svg';
import createAPlan from '../../images/createAPlan.svg';
import stayOnTrack from '../../images/stayOnTrack.svg';
import getHelp from '../../images/getHelp.svg';

export default function InfoCards() {    
    const translationNamespace = ['caregiverCoachingTranslations']
    const { t } = useTranslation(translationNamespace);
    return (
            <Container maxWidth="xl" sx={{padding: "50px"}} data-testid="info-cards-component">
                <Grid container spacing={2}>
                    <Grid item sm={12} md={6} lg={3}>
                        <InfoCard title={t('infoCard1.title')} description={t("infoCard1.description")} imageUrl={setGoal} color="#EEEAEA"/>                        
                    </Grid>
                    <Grid item sm={12} md={6} lg={3}>
                        <InfoCard title={t('infoCard2.title')} description={t("infoCard2.description")} imageUrl={createAPlan} color="#EEEAEA"/>                        
                    </Grid>
                    <Grid item sm={12} md={6} lg={3}>
                        <InfoCard title={t('infoCard3.title')} description={t("infoCard3.description")} imageUrl={stayOnTrack} color="#EEEAEA"/>                        
                    </Grid>
                    <Grid item sm={12} md={6} lg={3}>
                        <InfoCard title={t('infoCard4.title')} description={t("infoCard4.description")} imageUrl={getHelp} color="#EEEAEA"/>                        
                    </Grid>
                </Grid>
            </Container>            
    );
}

