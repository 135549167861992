import HighlighterWrapper from "../../common/HighlighterWrapper";
import ParagraphWrapper from "../../common/ParagraphWrapper";

const CQTemplate2 = (props: {
  searchText: string;
  paragraph1?: string;
  paragraph2?: string;
  paragraph2Style?: any;
  paragraph3?: string;
  paragraph4?: string;
  href?: string;
  linkText?: string;
}) => {
  const { searchText, paragraph1, paragraph2, paragraph2Style, paragraph3, paragraph4, href, linkText } = props;
  const searchTexts = searchText.split(" ");
  const showIhssProgramLink = typeof href === "string";
  const paragraph1Text = paragraph1 ? paragraph1 : "";
  const paragraph2Text = paragraph2 ? paragraph2 : "";
  const paragraph3Text = paragraph3 ? paragraph3 : "";
  const paragraph4Text = paragraph4 ? paragraph4 : "";
  const linkDisplayText = linkText ? linkText : href ? href : "";
  return (
    <>
      {paragraph1Text.length > 0 && (
          <ParagraphWrapper searchTexts={searchTexts} textToHighlight={paragraph1Text.toString()} />
      )}
      {paragraph2Text.length > 0 && (
          <div style={{...paragraph2Style}} ><ParagraphWrapper searchTexts={searchTexts} textToHighlight={paragraph2Text.toString()} /></div>
      )}
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={paragraph3Text.toString()} />
      {showIhssProgramLink && (
        <a href={href} target="_blank" rel="noopener noreferrer">
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={linkDisplayText.toString()} />
        </a>
      )}
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={paragraph4Text.toString()} />
    </>
  );
};

export default CQTemplate2;
