import { createTheme } from "@mui/material/styles";

const calDefaultTheme = createTheme({
  typography: {
    fontFamily: ["Avenir", "Arial"].join(","),
    h1: {
      fontFamily: ["AvenirNextBold", "Arial"].join(","),
    },
  },
  palette: {
    primary: {
      main: "#426DA9",
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: "40px",
          boxShadow: "none",
          lineHeight: "2.2",
          textTransform: "capitalize",
          fontSize: "1rem",
          fontWeight: "700",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          borderRadius: "3px",
          borderColor: "#EEEAEA",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: "1px solid rgba(0, 0, 0, 0.23);",
          boxShadow: "none",
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
          borderRadius: "3px",
          borderColor: "#EEEAEA",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 320,
      md: 767,
      lg: 900,
      xl: 1536,
    },
  },
});

export default calDefaultTheme;
