// import { Box, Grid, List, ListItem, Typography } from "@mui/material";
import { Grid, List, ListItem, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function TrackLevelCard(props: {
  level: number;
  title: string;
  requirements: string[];
  amount: string;
  condition: string;
  description: string
}) {
  const { title, requirements, level, amount, condition, description } = props;

  const translationNamespace = ["getPaidTranslations"];
  const { t } = useTranslation(translationNamespace);

  return (
    <div data-testid='track-level-card-component'>
      <Grid container>
        <Grid item xs={6}>
          <Typography variant="h4">
            {`${t("level")} ${level}`}:{" "}
            <span className="color-t1">{title}</span>
          </Typography>
        </Grid>
        <Grid item xs={6} className="d-flex flex-col justify-content-end">
          <div className="ms-auto amount-t1">
            <Typography variant="h5">
              {t("incentive")}:<span>{amount}</span>
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sx={{marginTop: "15px"}}>
          <Typography variant="body2">{description}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className="requirement-list-level">
        <Typography variant="body2">{t("requirements")}</Typography>
        <List
          sx={{
            listStyleType: "disc",
            pl: 2,
            "& .MuiListItem-root": { display: "list-item" },
          }}
        >
          {requirements.map((requirement, i) => (
            <ListItem key={i}>{requirement}</ListItem>
          ))}
        </List>
      </Grid>
      {condition != "" && (
        <Typography variant="body2" className="condition-t1">
          {condition}
        </Typography>
      )}
    </div>
  );
}
