export const ROOT_URL = "";
export const NOT_FOUND_URL = "*";
export const CAREGIVER_TRAININGS_URL = "caregiver-trainings";
export const COURSE_CATALOG_URL_WITH_SCROLL = `${CAREGIVER_TRAININGS_URL}#search`;
export const COURSE_CATALOG_DETAILS_URL = `${CAREGIVER_TRAININGS_URL}/:name/:number/`;
export const COURSE_CATALOG_DETAILS_URL_WITH_FORMAT = `${CAREGIVER_TRAININGS_URL}/:name/:number/:format`;
export const GET_PAID_URL = "get-paid";
export const INCENTIVE_PAYMENT_URL_WITH_SCROLL = `${GET_PAID_URL}#eligible`;
export const FAQ_URL = "faq";
export const CAREGIVER_COACHING_URL = "caregiver-coaching";
export const ABOUT_CAL_GROWS = "aboutcalgrows";
export const COURSE_CATALOG_URL = "course-catalog";
export const INCENTIVE_PAYMENT_URL = "incentive-payments";
export const CAREER_COACHING_URL = "career-coaching";
export const TRAINING_PARTNERS_URL = "trainingpartners";
export const ELIGIBILITY_URL = "#eligibility";
export const FAQ_ELIGIBILITY_URL = "faq/calgrows-eligibility";
export const FAQ_FINANCIAL_COMPENSATION_URL = "faq/calgrows-financial-compensation";
export const FAQ_HOW_TO_APPLY_URL = "faq/how-to-apply-for-calgrows-courses";
export const FAQ_IS_FREE_URL = "faq/is-calgrows-free";
export const FAQ_WHAT_URL = "faq/what-is-calgrows";
export const FAQ_WHAT_COURSES_URL = "faq/what-courses-does-calgrows-offer";
export const FAQ_WORTH_URL = "faq/are-calgrows-courses-worth-it";
export const FAQ_HOW_URL = "faq/how-do-calgrows-courses-work";
export const FAQ_DURATION_URL = "faq/calgrows-course-duration";
export const FAQ_FORMAT_URL = "faq/are-calgrows-courses-online-or-in-person";
export const FAQ_LANGUAGE_URL = "faq/are-calgrows-courses-available-in-different-languages-besides-english";
export const GOOGLE_FORM_URL = "https://forms.gle/yJktnaCDFuJyrrgb6";
export const TOS_URL = "https://aging.ca.gov/About_Us/Conditions_of_Use/";
export const PRIVACY_URL = "https://aging.ca.gov/About_Us/Privacy_Policy/";
export const CALGROWS_GRANTEE_TOOLKIT = "https://go.calgrows.org/calgrows-grantee-toolkit";
export const CALGROWS_OUTREACH_PARTNER_TOOLKIT = "https://go.calgrows.org/calgrows-outreach-partner-toolkit";
export const CDA_URL = "https://aging.ca.gov/";
export const INCENTIVE_CLAIM_URL = "https://app.bybeam.co/calgrows/eligibility";
export const PCG_URL = "https://www.publicconsultinggroup.com";
export const INNOVATION_FUND_URL = "innovationfund";
export const IN_THE_NEWS = "https://go.calgrows.org/in-the-news";
export const HCBS_URL = "https://www.dhcs.ca.gov/provgovpart/Pages/HCBS.aspx";
export const OUTREACH_PARTNERS_TOOLKIT_URL = "https://toolkit.calgrows.org";
export const MEDIA_FACT_SHEET_URL = "https://mcusercontent.com/416df564562f0ca16e54bd5ad/files/6e8e0897-6554-3561-8194-5f4ed2cbc086/CalGrows_Media_Fact_Sheet_Final_ADA.pdf";
export const HSFORM_URL = "mailto:help@calgrows.org";
export const CREATE_COURSE = "create-course";
export const MANAGE_COURSES = "manage-courses";
export const EDIT_COURSE = "edit-course";

export const SURVEY_URL = "survey";

export const COURSE_PREVIEW_DETAILS = "course-preview";
export const COURSE_PREVIEW_DETAILS_URL = `${COURSE_PREVIEW_DETAILS}/:name/:number/`;
export const COURSE_PREVIEW_DETAILS_URL_WITH_FORMAT = `${COURSE_PREVIEW_DETAILS}/:name/:number/:format`;
export const SURVEY_ES_URL = "survey-es"
