import { Grid } from "@mui/material";
// import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSearchParameters } from "../../store/reducer/searchParameters";
import { getSearchResult } from "../../store/reducer/searchResult";
import { getVisibility, setIsFilterVisible } from "../../store/reducer/visibility";
import FilterHead from "./FilterHead";
import Filters from "./Filters";
import FilterCollapse from "./FilterCollapse";
import { useState } from "react";

function FilterBox(props: { onClick: any }) {
    // const translationNamespace = ['caregiverTrainingsTranslations']
    // const { t } = useTranslation(translationNamespace);

    const { onClick: onSearch } = props;
    const [mobileToggle, setMobileToggle] = useState<boolean>(false);
    const [arrowDirection, setArrowDirection] = useState<string>("right");

    const {
        searchText,
        incentiveTrack,
        statuses,
        topics,
        formats,
        counties,
        languages,
        difficulties,
        trainingFocuses,
        occupationFocuses,
        days,
        startTime,
    } = useSelector(getSearchParameters);
    const searchResult = useSelector(getSearchResult);
    const visibility = useSelector(getVisibility);
    const dispatch = useDispatch();

    const handleToggle = () => {
        dispatch(setIsFilterVisible(!visibility.isFilterVisible));
    };

    const handleToggleMobile = () => {
        setMobileToggle(!mobileToggle);
        if (arrowDirection == "right") {
            setArrowDirection("left");
        } else {
            setArrowDirection("right");
        }
    };

    const filterCount = () => {
        let count = 0;
        if (searchText && searchText.length > 0) {
            count = count + 1;
        }
        if (counties && counties.length > 0) {
            count = count + 1;
        }
        if (incentiveTrack && incentiveTrack.length > 0) {
            count = count + 1;
        }
        if (days && days.length > 0) {
            count = count + 1;
        }
        if (statuses && statuses.length > 0) {
            count = count + 1;
        }
        if (formats && formats.length > 0) {
            count = count + 1;
        }
        if (languages && languages.length > 0) {
            count = count + 1;
        }
        if (topics && topics.length > 0) {
            count = count + 1;
        }
        if (difficulties && difficulties.length > 0) {
            count = count + 1;
        }
        if (trainingFocuses && trainingFocuses.length > 0) {
            count = count + 1;
        }
        if (occupationFocuses && occupationFocuses.length > 0) {
            count = count + 1;
        }
        if (startTime && startTime.length > 0) {
            count = count + 1;
        }

        return count;
    };

    return (
        <>
            {visibility.isFilterVisible ? (
                <Grid item xs={12} md={4} lg={3} className="course-search-filter" display={{ xs: "none", lg: "block" }}>
                    <FilterHead
                        direction="left"
                        onClick={() => handleToggle()}
                        resultCount={searchResult.total}
                        filterCount={filterCount()}
                        isLoaderVisible={visibility.isLoaderVisible}
                    />
                    <Filters
                        filterCount={filterCount()}
                        isLoaderVisible={visibility.isLoaderVisible}
                        onClick={(
                            searchText: string,
                            incentiveTrack: string,
                            formats: string,
                            languages: string[],
                            counties: string[],
                            statuses: string[],
                            difficulties: string[],
                            trainingFocuses: string[],
                            occupationFocuses: string[],
                            days: string[],
                            topics: string[],
                            startTime: string,
                            endTime: string,
                            page: number
                        ) =>
                            onSearch(
                                searchText,
                                incentiveTrack,
                                formats,
                                languages,
                                counties,
                                statuses,
                                difficulties,
                                trainingFocuses,
                                occupationFocuses,
                                days,
                                topics,
                                startTime,
                                endTime,
                                page
                            )
                        }
                    />
                </Grid>
            ) : (
                <FilterCollapse onClick={() => handleToggle()} filterCount={filterCount()} />
            )}
            <Grid item xs={12} className="course-search-filter" display={{ xs: "block", lg: "none" }}>
                <FilterHead
                    direction={arrowDirection}
                    onClick={() => handleToggleMobile()}
                    resultCount={searchResult.total}
                    filterCount={filterCount()}
                    isLoaderVisible={visibility.isLoaderVisible}
                />
                {mobileToggle && (
                    <Filters
                        filterCount={filterCount()}
                        isLoaderVisible={visibility.isLoaderVisible}
                        onClick={(
                            searchText: string,
                            incentiveTrack: string,
                            formats: string,
                            languages: string[],
                            counties: string[],
                            statuses: string[],
                            difficulties: string[],
                            trainingFocuses: string[],
                            occupationFocuses: string[],
                            days: string[],
                            topics: string[],
                            startTime: string,
                            endTime: string,
                            page: number
                        ) =>
                            onSearch(
                                searchText,
                                incentiveTrack,
                                formats,
                                languages,
                                counties,
                                statuses,
                                difficulties,
                                trainingFocuses,
                                occupationFocuses,
                                days,
                                topics,
                                startTime,
                                endTime,
                                page
                            )
                        }
                    />
                )}
            </Grid>
        </>
    );
}

export default FilterBox;
