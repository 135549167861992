import HighlighterWrapper from "../../common/HighlighterWrapper";
import ParagraphWrapper from "../../common/ParagraphWrapper";

const C5Q005 = (props: { searchText: string; paragraph1: string; paragraph2: string }) => {
  const { searchText, paragraph1, paragraph2 } = props;
  const searchTexts = searchText.split(" ");

  const bybeamText = "<a href={https://app.bybeam.co/calgrows}>app.bybeam.co/calgrows</a>";
  const href = "https://app.bybeam.co/calgrows";
  const hrefText = "app.bybeam.co/calgrows";

  const para1: string[] = paragraph1.split(bybeamText);
  return (
    <>
      <p data-testid="C5Q005-component">
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={para1[0].toString()} />
        <a href={href} target="_blank" rel="noopener noreferrer">
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={hrefText?.toString()} />
        </a>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={para1[1]?.toString()} />
      </p>
      <ParagraphWrapper searchTexts={searchTexts} textToHighlight={paragraph2.toString()} />
    </>
  );
};

export default C5Q005;
