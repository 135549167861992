
import { useEffect } from 'react';
import { useLocation} from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const _hsq = window._hsq = window._hsq || [];

export default function Hubspot() {
    const pathname = useLocation()
    
    useEffect(() => {
        _hsq.push(['setPath', pathname.pathname]);
        _hsq.push(['trackPageView']);
    }, [pathname]);

    return (
        <></>
    )
    
}
