import { Box, Link, Typography } from '@mui/material';

export default function ContactInfoCard(props:{imageUrl: string, title:string, value: string, link: string}) {
    const { imageUrl, title, value, link } = props;

    return (
        <Box sx={{ width: '100%',padding:"25px"}} className='d-flex flex-col align-items-center get-connected-info-card'>
            <img src={imageUrl} width="115" alt={title}/>
            <Typography variant='h6' className='fw-700'>{title}</Typography>
            {
                link != "" ? (
                    <Link underline="hover"
                        color="inherit"
                        href={link}
                        target="_blank"
                    >
                        <Typography variant='body2' className='fw-700'>{value}</Typography>
                    </Link>
                ):
                (
                    <Typography variant='body2' className='fw-700'>{value}</Typography>
                )
            }
            
            
        </Box>
    )
}