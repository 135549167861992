import ParagraphWrapper from "../../common/ParagraphWrapper";
import RadioOption from "./common/RadioOption";
const CQTemplate4 = (props: { searchText: string; paragraph1: string; paragraph2: string; paragraph3: string; paragraph4: string; }) => {
  const { searchText, paragraph1, paragraph2, paragraph3, paragraph4 } = props;
  const searchTexts = searchText.split(" ");
  return (
    <>
      <ParagraphWrapper searchTexts={searchTexts} textToHighlight={paragraph1?.toString()} />
      <RadioOption searchTexts={searchTexts} option1={paragraph2} option2={paragraph3} />
      <ParagraphWrapper searchTexts={searchTexts} textToHighlight={paragraph4?.toString()} />
    </>
  );
};

export default CQTemplate4;
