import { Grid, Container, Typography} from '@mui/material';

export default function PageTitle(props: {title: string}) {
    const {title} = props;
    return (
            <Container maxWidth="xl" sx={{p: "25px"}} data-testid="page-title-component">
                <Grid item xs={12}>
                    <Typography variant='h2' className='page-title'>{title}</Typography>                        
                </Grid>
            </Container>            
    );
}

