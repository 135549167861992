const twelveHourToTwentyFourHour = (time:string) => {
    if(time){
        const timeOfDay = time.slice(-2)
        const timeOnly = time.slice(0, -2);

        const timeArray = timeOnly.split(":")
        let hour: number = +timeArray[0]
        const min: number = +timeArray[1]
        if(timeOfDay=="pm" && hour!=12){
            hour = hour + 12
        }

        return (`${hour}:${min}`)
    }
    else{
        return ""
    }
    
    
};

export default twelveHourToTwentyFourHour