import { Box, Button, FormLabel, Grid, IconButton, InputBase, Paper } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CountyFilter } from "../../model/countyFilter";
import { FormatFilter } from "../../model/formatFilter";
import { LanguageFilter } from "../../model/languageFilter";
import { TimeRange } from "../../model/timeRange";
import { TopicsFilter } from "../../model/topicsFIlter";
import { DifficultyFilter } from "../../model/difficultyFilter";
import { TrainingFocusFilter } from "../../model/trainingFocusFilter";
import { OccupationFocusFilter } from "../../model/occupationFocusFilter";
import { getSearchFilters, setCategories, setCounties, setFormats, setIncentiveTracks, setLanguages, setStatuses, setTopics, setDifficulties, setTrainingFocuses, setOccupationFocuses } from "../../store/reducer/searchFilters";
import { setCounty, setDay, setFormat, setLanguage, setTopic, getSearchParameters, setSearchText, setStatus, resetSearchParameters, setTimeRange, setincentiveTrack, setDifficulty, setTrainingFocus, setOccupationFocus } from "../../store/reducer/searchParameters";
import Dropdown from "../common/Dropdown";
import DropdownMultiSelect from "../common/DropdownMultiSelect";
import TimeSlider from "../common/TimeSlider";
import coursesAPI from "../../api/course";
import { setFiltersApi } from "../../store/reducer/apiStatus";
import { StatusFilter } from "../../model/statusFilter";
import SearchIcon from '@mui/icons-material/Search';
import { useLocation } from "react-router";
import { IncentiveTrackFilter } from "../../model/incentiveTrackFilter";
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

function Filters(props: { onClick: any, isLoaderVisible: boolean | null, filterCount: number }) {
  const translationNamespace = ['caregiverTrainingsTranslations']
  const { t } = useTranslation(translationNamespace);
     
  const location = useLocation();
  
  const { onClick: onSearch, isLoaderVisible, filterCount} = props;

  const searchFilters = useSelector(getSearchFilters);
  const {searchText, incentiveTrack, statuses, topics, formats, counties, languages, days, startTime, endTime, difficulties, trainingFocuses, occupationFocuses } = useSelector(getSearchParameters);

  const dispatch = useDispatch();

  const [searchTextLocal, setSearchTextLocal] = useState<string>(searchText)
  const [incentiveTrackLocal, setIncentiveTrackLocal] = useState<string | null>(incentiveTrack? incentiveTrack: null)
  const [statusesLocal, setStatusesLocal] = useState<string[]>(statuses? statuses: [])
  const [topicsLocal, setTopicsLocal] = useState<string[]>(topics? topics: [])
  const [formatsLocal, setFormatsLocal] = useState<string | null>(formats? formats: null)
  const [countiesLocal, setCountiesLocal] = useState<string[]>(counties? counties: [])
  const [languagesLocal, setLanguagesLocal] = useState<string[]>(languages? languages: [])
  const [daysLocal, setDaysLocal] = useState<string[]>(days? days: [])
  const [timeRangeLocal, setTimeRangeLocal] = useState<string[]>(startTime? [startTime, endTime]: ["", ""])
  const [displayCounty, setDisplayCounty] = useState<boolean>(false)
  const [displayDayTime, setDisplayDayTime] = useState<boolean>(false)
  const [difficultiesLocal, setDifficultiesLocal] = useState<string[]>(difficulties? difficulties: [])
  const [trainingFocusesLocal, setTrainingFocusesLocal] = useState<string[]>(trainingFocuses? trainingFocuses: [])
  const [occupationFocusesLocal, setOccupationFocusesLocal] = useState<string[]>(occupationFocuses? occupationFocuses: [])
  const handleKeywordChanges = (e: ChangeEvent<HTMLInputElement>) => {
      setSearchTextLocal(e.target.value);
  }

  const fetchFilters = async () => {
    const filterResponse = await coursesAPI.getFilters();
    if (filterResponse.ok) {
      const result = filterResponse.data;
      if (result) {
        dispatch(setIncentiveTracks(result.incentiveTracks))
        dispatch(setStatuses(result.statuses))
        dispatch(setTopics(result.topics))
        dispatch(setCounties(result.counties))
        dispatch(setLanguages(result.languages))
        dispatch(setFormats(result.formats))
        dispatch(setCategories(result.categories))
        dispatch(setDifficulties(result.difficulties))
        dispatch(setTrainingFocuses(result.trainingFocuses))
        dispatch(setOccupationFocuses(result.occupationFocuses))
        dispatch(setFiltersApi(true))
      }
    }
    else {
        dispatch(setFiltersApi(false))
    }
  }

  const formatTrainingFormats = (formats: FormatFilter[]) => formats.map((obj) => (obj.format));
  const formatTopics = (topics: TopicsFilter[]) => topics.map((obj) => (obj.topic));
  const formatCounties = (counties: CountyFilter[]) => counties.map((obj) => (obj.name));
  const formatLanguages = (languages: LanguageFilter[]) => languages.map((obj) => (obj.name)).sort();
  const formatStatuses = (statuses: StatusFilter[]) => statuses.map((obj) => (obj.registrationStatus));
  const formatIncentiveTracks = (incentiveTracks: IncentiveTrackFilter[]) => incentiveTracks.map((obj) => (obj.name));
  const formatDifficulties = (difficulties: DifficultyFilter[]) => difficulties.map((obj) => (obj.difficulty));
  const formatTrainingFocuses = (trainingFocuses: TrainingFocusFilter[]) => trainingFocuses.map((obj) => (obj.trainingFocus));
  const formatOccupationFocuses = (occupationFocuses: OccupationFocusFilter[]) => occupationFocuses.map((obj) => (obj.occupationFocuses));

  useEffect(() => {        
      fetchFilters();  
      if(location.state){          
        if(!location.state.keepPrevState){
          handleReset()
        }

        if(location.state.fetchAllCourses){
          handleReset()
        }
    }   
  }, [])

  useEffect(() => {        
    handleFormatChange();     
  }, [formatsLocal])

  const handleTimeSlider = (value: TimeRange) => {
    setTimeRangeLocal([value.startTime, value.endTime])
  }

  const handleReset = ()=>{
    const count = filterCount
    dispatch(resetSearchParameters())
    setSearchTextLocal("")
    setStatusesLocal([])
    setIncentiveTrackLocal(null)
    setTopicsLocal([])
    setFormatsLocal(null)
    setCountiesLocal([])
    setLanguagesLocal([])
    setDifficultiesLocal([])
    setTrainingFocusesLocal([])
    setOccupationFocusesLocal([])
    setDaysLocal([])
    setTimeRangeLocal(["", ""])
    if(count>0){
      onSearch("", "", "", [], [], [], [],[], [], [],[], "", "", 1)
    }
  }

  const localFilterCount= ()=>{
    let count = 0
    if (searchTextLocal.length > 0){
      count =count+1
    }
    
    if (incentiveTrackLocal && incentiveTrackLocal.length > 0){
      count =count+1
    }
    if (countiesLocal.length > 0){
      count =count+1
    }
    if (daysLocal.length > 0){
      count =count+1
    }
    if (statusesLocal.length > 0){
      count =count+1
    }
    if (formatsLocal && formatsLocal.length > 0){
      count =count+1
    }
    if (languagesLocal.length > 0){
      count =count+1
    }
    if (topicsLocal.length > 0){
      count =count+1
    }
    if (difficultiesLocal.length > 0){
      count =count+1
    }
    if (trainingFocusesLocal.length > 0){
      count =count+1
    }
    if (occupationFocusesLocal.length > 0){
      count =count+1
    }
    if (timeRangeLocal[0].length > 0){
      count =count+1
    }

    return count
  }

  const applyFilter =()=>{
    dispatch(setCounty(countiesLocal))
    dispatch(setSearchText(searchTextLocal))
    dispatch(setincentiveTrack(incentiveTrackLocal))
    dispatch(setStatus(statusesLocal))
    dispatch(setTopic(topicsLocal))
    dispatch(setFormat(formatsLocal))
    dispatch(setLanguage(languagesLocal))
    dispatch(setDay(daysLocal))
    dispatch(setTimeRange(timeRangeLocal))
    dispatch(setDifficulty(difficultiesLocal))
    dispatch(setTrainingFocus(trainingFocusesLocal))
    dispatch(setOccupationFocus(occupationFocusesLocal))
    onSearch(searchTextLocal, incentiveTrackLocal, formatsLocal, languagesLocal, countiesLocal, statusesLocal, difficultiesLocal, trainingFocusesLocal, occupationFocusesLocal, daysLocal, topicsLocal, timeRangeLocal[0], timeRangeLocal[1], 1)
  }

  const daysString = t("daysString")
  const daysOpts = daysString.split(',');
  const searchTextPlaceholder = t("searchTextPlaceholder")

  const handleFormatChange = () => {    
    if (formatsLocal == t('inPerson') || formatsLocal == t('hybrid')) {      
      setDisplayCounty(true)
      setDisplayDayTime(true)
      if(timeRangeLocal[0]==""){        
        setTimeRangeLocal(["8:00", "21:00"])
      }
    }else{
      setDisplayCounty(false) 
      setCountiesLocal([])
      setDaysLocal([])
    }    

    if (formatsLocal == t('onlineInstructorLed')) {
      setDisplayDayTime(true)
      if(timeRangeLocal[0]==""){        
        setTimeRangeLocal(["8:00", "21:00"])
      }
    }
    else{
      setDaysLocal([])
    }

    if(formatsLocal != t('onlineInstructorLed') && formatsLocal != t('inPerson') && formatsLocal != t('hybrid')){
      setDisplayDayTime(false)       
      setTimeRangeLocal(["", ""])
    }
  }

  return ( 
    <Grid container sx={{marginTop: "10px"}}>    
      <Grid item xs={6}>
        <Button 
          onClick={() => handleReset()}
          className="reset-btn" 
          disabled={localFilterCount() == 0 ? true: false} 
          variant="contained" 
          color="primary" 
          sx={{ width: "134px", backgroundColor:"#D9D9D9", color:"#000", borderRadius:"18px" }}
        >
          {t('reset')}
        </Button>
      </Grid>
      <Grid item xs={6} sx={{textAlign:"right" }}>
        <Button id="caregiver_training_apply_filter_btn" onClick={() => applyFilter()} disabled={isLoaderVisible ? true: false} variant="contained" color="primary" sx={{ width: "134px", borderRadius:"18px"}}>
          {t('applyFilter')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ marginTop: "20px" }}>
          <FormLabel className="form-label">{t("courseSearchBy")}:</FormLabel>
          <Paper component="form" onSubmit={() => applyFilter()}
              sx={{ marginTop: "10px", p: '8px 4px', display: 'flex', alignItems: 'center'}}
          >
            <IconButton type="button" aria-label="search">
              <SearchIcon />
            </IconButton>
            <InputBase
              id="search"
              name="search"
              onChangeCapture={handleKeywordChanges}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              sx={{ ml: 1, flex: 1 }}
              placeholder={searchTextPlaceholder}
              inputProps={{ 'aria-label': 'search' }}
              autoComplete="off"
              required
              value={searchTextLocal}
            />
          </Paper>
        </Box>
        <Box sx={{ marginTop: "20px" }}>
          <FormLabel className="form-label">{t('incentiveTrack')}:</FormLabel>
          <Box sx={{ marginTop: "10px" }}>
            <Dropdown 
              onChange={(value: string | null) => setIncentiveTrackLocal(value)} 
              opts={searchFilters.incentiveTracks ? formatIncentiveTracks(searchFilters.incentiveTracks) : []} 
              selectedValue={incentiveTrackLocal} 
              name="incentive-track" 
              title={t('selectIncentiveTrack')} 
            />
          </Box>
        </Box>
        <Box sx={{ marginTop: "20px" }}>
          <FormLabel className="form-label">{t('courseRegistrationStatus')}:</FormLabel>
          <Box sx={{ marginTop: "10px" }}>
            <DropdownMultiSelect 
              onChange={(value: string[]) => setStatusesLocal(value)} 
              opts={searchFilters.statuses ? formatStatuses(searchFilters.statuses) : []} 
              name="statuses" 
              title={t('selectCourseRegistrationStatus')} 
              selectedValues={statusesLocal ? statusesLocal : []} 
            />
          </Box>
        </Box>
        <Box sx={{ marginTop: "20px" }}>
          <FormLabel className="form-label">{t('topics')}:</FormLabel>
          <Box sx={{ marginTop: "10px" }}>
            <DropdownMultiSelect 
              onChange={(value: string[]) => setTopicsLocal(value)} 
              opts={searchFilters.topics ? formatTopics(searchFilters.topics) : []} 
              selectedValues={topicsLocal ? topicsLocal : []} 
              name="topics" 
              title={t('selectTopic')} 
            />
          </Box>
        </Box>
        <Box sx={{ marginTop: "20px" }}>
          <FormLabel className="form-label">{t('trainingFormats')}:</FormLabel>
          <Box sx={{ marginTop: "10px" }}>
            <Dropdown 
              onChange={(value: string | null) => setFormatsLocal(value)} 
              opts={searchFilters.formats ? formatTrainingFormats(searchFilters.formats) : []} 
              selectedValue={formatsLocal} 
              name="training-format" 
              title={t('selectTrainingFormat')} 
            />
          </Box>
        </Box>
        {
          displayCounty == true  && (
            <Box sx={{ marginTop: "20px" }}>
              <FormLabel className="form-label">{t('counties')}:</FormLabel>
              <Box sx={{ marginTop: "10px" }}>
                <DropdownMultiSelect 
                  onChange={(value: string[]) => setCountiesLocal(value)} 
                  opts={searchFilters.counties ? formatCounties(searchFilters.counties) : []} 
                  selectedValues={countiesLocal ? countiesLocal : []} 
                  name="counties" 
                  title={t('selectCounty')} 
                />
              </Box>
            </Box>
          )
        }

        {
          displayDayTime == true && (
            <Box sx={{ marginTop: "20px" }}>
              <FormLabel className="form-label">{t('MeetingDaysAndTime')}:</FormLabel>
              <Box sx={{ marginTop: "10px" }}>
                <DropdownMultiSelect 
                  onChange={(value: string[]) => setDaysLocal(value)} 
                  opts={daysOpts} 
                  selectedValues={daysLocal ? daysLocal : []} 
                  name="days" 
                  title={t('selectDay')} 
                />
                <Box sx={{ padding: "0px 30px", marginTop: "20px" }}>
                  <TimeSlider selectedValues={timeRangeLocal ? timeRangeLocal : []} onChange={(value: TimeRange) => { handleTimeSlider(value) }} />
                </Box>
              </Box>
            </Box>
          )
        }
        <Box sx={{ marginTop: "20px" }}>
          <FormLabel className="form-label">{t('languages')}:</FormLabel>
          <Box sx={{ marginTop: "10px" }}>
            <DropdownMultiSelect 
              onChange={(value: string[]) => setLanguagesLocal(value)} 
              opts={searchFilters.languages ? formatLanguages(searchFilters.languages) : []} 
              selectedValues={languagesLocal ? languagesLocal : []} 
              name="language" 
              title={t('selectLanguage')} 
            />
          </Box>
    
        <Box sx={{ marginTop: "20px" }}>
        <FormLabel className="form-label" component="legend">{t('difficulties')}:</FormLabel>
          <Box sx={{ marginTop: "10px" }}>
            <DropdownMultiSelect 
              onChange={(value: string[]) => setDifficultiesLocal(value)} 
              opts={searchFilters.difficulties ? formatDifficulties(searchFilters.difficulties) : []} 
              selectedValues={difficultiesLocal ? difficultiesLocal : []}
              name="difficulty" 
              title={t('selectDifficulty')}
            />
          </Box>
        </Box>

        <Box sx={{ marginTop: "20px" }}>
        <FormLabel className="form-label" component="legend">{t('trainingFocus')}:</FormLabel>
          <Box sx={{ marginTop: "10px" }}>
            <DropdownMultiSelect 
              onChange={(value: string[]) => setTrainingFocusesLocal(value)} 
              opts={searchFilters.trainingFocuses ? formatTrainingFocuses(searchFilters.trainingFocuses) : []} 
              selectedValues={trainingFocusesLocal ? trainingFocusesLocal : []} 
              name="training-focus" 
              title={t('selectTrainingFocus')}
            />
          </Box>
        </Box>

        <Box sx={{ marginTop: "20px" }}>
          <FormLabel className="form-label" component="legend">{t('occupationFocuses')}:</FormLabel>
          <Box sx={{ marginTop: "10px" }}>
            <DropdownMultiSelect 
              onChange={(value: string[]) => setOccupationFocusesLocal(value)}  
              opts={searchFilters.occupationFocuses ? formatOccupationFocuses(searchFilters.occupationFocuses) : []} 
              selectedValues={occupationFocusesLocal ? occupationFocusesLocal : []} 
              name="occupation-focus" 
              title={t('selectOccupationFocus')}
            />
          </Box>
        </Box>

        </Box>
        </Grid>
    </Grid>
  );
}

export default Filters;
