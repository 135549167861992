import Banner from "../components/common/Banner";
import { Trans, useTranslation } from "react-i18next";
import careerCoachingBanner from "../images/careerCoachingBanner.webp";
import PageTitle from "../components/common/PageTitle";
import InfoCards from "../components/caregiverCoaching/InfoCards";
import GetConnected from "../components/caregiverCoaching/GetConnected";
import { Helmet } from "react-helmet";
import { CAREGIVER_COACHING_URL } from "../constant/path";
import { Typography, Alert, Container } from "@mui/material";

export default function CaregiverCoaching() {
  const translationNamespace = ["caregiverCoachingTranslations"];
  const { t } = useTranslation(translationNamespace);
  const metaDesc: string = t('metaDesc')
  return (
    <>
      <Helmet>
        <title>{t('browserTitle')}</title>
        <meta name="description" content={metaDesc} />
        <link rel="canonical" href={`${process.env.REACT_APP_URL}/${CAREGIVER_COACHING_URL}`}/>
      </Helmet>
      <div className="main-content-wrapper" data-testid="caregiver-coaching-view">
        <Container maxWidth="xl">
 <Alert 
        variant="filled" 
        severity="info"
        color='warning'
        >
            <Typography variant="h5">
          Do not miss out! - All CalGrows Incentive Claims MUST be submitted by 8/31 for payment.</Typography>
        </Alert>
        </Container>
       
        <PageTitle title={t("title")} />
        <Banner
          imageUrl={careerCoachingBanner}
          title={t("banner.title")}
          subTitle={t("banner.subTitle")}
          description={
            <Trans
              components={{ br: <br />, i: <i /> }}
            >
              {t("banner.description")}
            </Trans>
          }
        />
        <InfoCards />
        <GetConnected />
      </div>
    </>
  );
}
