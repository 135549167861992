import HighlighterWrapper from "../../common/HighlighterWrapper";
import { extractContent } from "../utils/common";
import { Link } from "react-router-dom";
import { FAQ_FINANCIAL_COMPENSATION_URL } from "../../../constant/path";

const C5Q006 = (props: {
  searchText: string;
  paragraph1: string;
  paragraph2: string;
  href:string;
}) => {
  const { searchText, paragraph1, paragraph2, href} = props;
  const searchTexts = searchText.split(" ");

  const extractor = extractContent(paragraph1);
  const footer =  extractor === null || extractor.input === undefined ? [] : extractor.input.split(extractor[0]);
  const footerp1 = footer[0];
  const footerp2 = footer[1];
  const linkDisplayText = extractor === null ? "" : extractor[1];

  const extractor1 = extractContent(paragraph2);
  const extractorParaS1 = extractor1 === null || extractor1.input === undefined ? [] : extractor1.input.split(extractor1[0]);
  const extractorPara1 = extractorParaS1[0];
  const linkDisplayText1 = extractor1 === null ? "" : extractor1[1];

  return (
    <>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={footerp1?.toString()} />
      <a href={href} target="_blank" rel="noopener noreferrer">
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={linkDisplayText.toString()} />
      </a>

      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={footerp2?.toString()} />
      
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={extractorPara1?.toString()} />
      <Link to={`../${FAQ_FINANCIAL_COMPENSATION_URL}`}>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={linkDisplayText1?.toString()} />
      </Link>
    </>
  );
};

export default C5Q006;
