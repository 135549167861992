const minsToTime = (mins: number) => { 
    let output = ""
    let timeOfDay = "am"

    const hoursFloat = mins/60
    let hours = Math.floor(hoursFloat);
    const rminutes = Math.round((hoursFloat - hours) * 60);

    if (hours>11){
        timeOfDay = "pm"
    }

    if (hours>12){
        hours = hours-12
    }

    if (rminutes == 0) {
        output = `${hours}:00${timeOfDay}`
    }
    else {
        output = `${hours}:${rminutes}${timeOfDay}`
    }
    return output; 
};

export default minsToTime