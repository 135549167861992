import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const Question = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary sx={{ paddingLeft:'0' }} expandIcon={<PlayArrowIcon sx={{ fontSize: "1.5rem" }} />} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

export default Question;
