import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import Backend from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';

import headerTranslationEnglish from "./en/headerTranslations.json";
import footerTranslationEnglish from "./en/footerTranslations.json";
import faqEnglish from "./en/faq.json";
import homeTranslationEnglish from "./en/homeTranslations.json";
import getPaidTranslationsEnglish from "./en/getPaidTranslations.json";
import caregiverTrainingsTranslationsEnglish from "./en/caregiverTrainingsTranslations.json";
import caregiverCoachingTranslationsEnglish from "./en/caregiverCoachingTranslations.json";
import courseDetailTranslationEnglish from "./en/courseDetailTranslations.json";
import aboutCalGrowsTranslationsEnglish from './en/aboutCalGrowsTranslations.json';
import createCourseCalGrowsTranslationsEnglish from './en/createCourseCalGrowsTranslations.json';
import surveyTranslationEnglish from "./en/surveyTranslations.json";import editCourseCalGrowsTranslationsEnglish from './en/editCourseCalGrowsTranslations.json'
import manageCourseCalGrowsTranslationsEnglish from './en/manageCourseCalGrowsTranslations.json'

import FAQSinglePageTranslationsEnglish from './en/FAQSinglePageTranslations.json';

i18next
  .use(ChainedBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    backend: {
      backends: [Backend],
      backendOptions: [
        {
          loadPath: `${process.env.REACT_APP_URL}/locales/{{lng}}/{{ns}}.json`,
        },
      ],
    },

    partialBundledLanguages: true,
    ns: [],
    resources: {},
    fallbackLng: "en",
    returnObjects: true,
    react: {
      useSuspense: false,
    },
  });

i18next.addResourceBundle("en", "headerTranslations", headerTranslationEnglish);
i18next.addResourceBundle("en", "footerTranslations", footerTranslationEnglish);
i18next.addResourceBundle("en", "faq", faqEnglish);
i18next.addResourceBundle("en", "homeTranslations", homeTranslationEnglish);
i18next.addResourceBundle("en", "getPaidTranslations", getPaidTranslationsEnglish);
i18next.addResourceBundle("en", "caregiverTrainingsTranslations", caregiverTrainingsTranslationsEnglish);
i18next.addResourceBundle("en", "caregiverCoachingTranslations", caregiverCoachingTranslationsEnglish);
i18next.addResourceBundle("en", "courseDetailTranslations", courseDetailTranslationEnglish);
i18next.addResourceBundle("en", "aboutCalGrowsTranslations", aboutCalGrowsTranslationsEnglish);
i18next.addResourceBundle("en", "createCourseCalGrowsTranslations", createCourseCalGrowsTranslationsEnglish);
i18next.addResourceBundle("en", "editCourseCalGrowsTranslations", editCourseCalGrowsTranslationsEnglish);
i18next.addResourceBundle("en", "manageCourseCalGrowsTranslations", manageCourseCalGrowsTranslationsEnglish);
i18next.addResourceBundle("en", "FAQSinglePageTranslations", FAQSinglePageTranslationsEnglish);
i18next.addResourceBundle("en", "surveyTranslations", surveyTranslationEnglish);


export default i18next;
