import { useState, useEffect } from "react";
import { Autocomplete, Box, Button, Chip, Container, Grid, InputLabel, TextField, Typography } from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Sessions from "./Sessions";
import moment, { Moment } from "moment";
import { getVisibility, setIsLoaderVisible } from "../../store/reducer/visibility";
import { useDispatch } from "react-redux";
import coursesAPI from "../../api/course";
import Alert from "@mui/material/Alert";
import { useSelector } from "react-redux";
import Loader from "./Loader";
import { Course } from "../../model/course";
import { useLocation, useNavigate } from "react-router";
import { COURSE_PREVIEW_DETAILS } from "../../constant/path";
import { formatName, formatTrainingFormat } from "../../hooks/generateUrl";
import { v4 as uuidv4 } from "uuid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const initialStateValue: Course = {
    _id: "",
    name: "",
    number: "",
    description: "",
    formattedDescription: "",
    primaryPathway: "",
    isActive: true,
    incentiveEligibility: "",
    registrationInstructions: [],
    sessions: [],
    isAvailable: true,
    durationHours: "",
    topics: [],
    sessionAvailabilityDescription: "",
    sessionAvailability: true,
    prerequisticsDesc: "",
    trainingPartnerName: "",
    incentiveTypeName: "",
    formats: [],
    languages: [],
};

const COURSE_FIELDS = [
    { key: "programName", label: "Program Name", type: "text", required: true },
    { key: "name", label: "Name of the course for public view", type: "text", required: true },
    { key: "number", label: "Course Number", type: "text", required: true },
    { key: "description", label: "Description of course", type: "text", required: true },
    {
        key: "incentiveTypeName",
        label: "Incentive Track",
        type: "autocomplete",
        options: [
            { title: "None", value: "None" },
            { title: "Track 1: Learn and Earn", value: "Track 1: Learn and Earn" },
            { title: "Track 2: Career Builder", value: "Track 2: Career Builder" },
        ],
        required: true,
    },
    {
        key: "primaryPathway",
        label: "Primary Pathway",
        type: "autocomplete",
        options: [
            {
                title: "General Health and Safety",
                value: "General Health and Safety",
            },
            {
                title: "Adult Education",
                value: "Adult Education",
            },
            {
                title: "Cognitive Impairments and Behavioral Health",
                value: "Cognitive Impairments and Behavioral Health",
            },
            {
                title: "Complex Physical Needs",
                value: "Complex Physical Needs",
            },
            {
                title: "Transition to Home and Community Based Living",
                value: "Transition to Home and Community Based Living",
            },
        ],
    },
    {
        key: "topics",
        label: "Core Competencies",
        type: "autocomplete",
        options: [
            {
                title: "Abuse and neglect",
                value: "Abuse and neglect",
            },
            {
                title: "Activities of daily living",
                value: "Activities of daily living",
            },
            {
                title: "Behaving professionally and ethically",
                value: "Behaving professionally and ethically",
            },
            {
                title: "Body mechanics",
                value: "Body mechanics",
            },
            {
                title: "Communication skills",
                value: "Communication skills",
            },
            {
                title: "Crisis prevention and intervention",
                value: "Crisis prevention and intervention",
            },
            {
                title: "Documentation, delegation and reporting",
                value: "Documentation, delegation and reporting",
            },
            {
                title: "Emergency response (falls, incidents, fires)",
                value: "Emergency response (falls, incidents, fires)",
            },
            {
                title: "End-of-life care, grief",
                value: "End-of-life care, grief",
            },
            {
                title: "Food, nutrition and meal preparation",
                value: "Food, nutrition and meal preparation",
            },
            {
                title: "Housekeeping",
                value: "Housekeeping",
            },
            {
                title: "Infection control and cleanliness",
                value: "Infection control and cleanliness",
            },
            {
                title: "Legal and ethical issues",
                value: "Legal and ethical issues",
            },
            {
                title: "Managing stress",
                value: "Managing stress",
            },
            {
                title: "Person-centered practices",
                value: "Person-centered practices",
            },
            {
                title: "Physical safety and accident/injury prevention",
                value: "Physical safety and accident/injury prevention",
            },
            {
                title: "Principles of teaching and learning",
                value: "Principles of teaching and learning",
            },
            {
                title: "Problem-solving",
                value: "Problem-solving",
            },
            {
                title: "Rehabilitation and restorative care",
                value: "Rehabilitation and restorative care",
            },
            {
                title: "Respecting differences",
                value: "Respecting differences",
            },
            {
                title: "Role of the direct care worker",
                value: "Role of the direct care worker",
            },
            {
                title: "Self-care",
                value: "Self-care",
            },
            {
                title: "Strengths-and solutions-focused practices",
                value: "Strengths-and solutions-focused practices",
            },
            {
                title: "Team building",
                value: "Team building",
            },
            {
                title: "Understanding aging",
                value: "Understanding aging",
            },
            {
                title: "Understanding cultural aspects of care",
                value: "Understanding cultural aspects of care",
            },
            {
                title: "Understanding dementia and Alzheimer's",
                value: "Understanding dementia and Alzheimer's",
            },
            {
                title: "Understanding developmental disabilities",
                value: "Understanding developmental disabilities",
            },
            {
                title: "Understanding physical disabilities",
                value: "Understanding physical disabilities",
            },
        ],
        multiple: true,
    },
    { key: "durationHours", label: "Total course duration (Hours)", type: "text", textType: "number", xs: 12, md: 6 },
    { key: "durationMinutes", label: "(Minutes)", type: "text", textType: "number", xs: 12, md: 6, max: 59 },
    { key: "courseCreatedDate", label: "Date the course is created", type: "date", required: true },
    { key: "trainingPartnerName", label: "Name of the training partner", type: "text" },
    {
        key: "isAvailable",
        label: "Course availability on the website",
        type: "autocomplete",
        options: [
            { title: "Available", value: true },
            { title: "Not available", value: false },
        ],
        required: true,
    },
    {
        key: "formats",
        label: "Course training format",
        type: "autocomplete",
        options: [
            {
                title: "Hybrid",
                value: "Hybrid",
            },
            {
                title: "In person",
                value: "In person",
            },
            {
                title: "Online (instructor led)",
                value: "Online (instructor led)",
            },
            {
                title: "Online (self-paced)",
                value: "Online (self-paced)",
            },
        ],
        multiple: true,
    },
    {
        key: "incentiveEligibility",
        label: "Is caregiver eligible for incentive or not",
        // type: "autocomplete",
        // options: [
        //     // { title: "Eligible", value: true },
        //     // { title: "Not eligible", value: false },
        // ],
        type: "text",
        required: true,
    },
    {
        key: "isTranslated",
        label: "Is the course translated by translation API",
        type: "autocomplete",
        options: [
            { title: "Yes", value: true },
            { title: "No", value: false },
        ],
        required: true,
    },
    // {
    //     key: "languages",
    //     label: "Languages the course will be offered in",
    //     type: "autocomplete",
    //     options: [
    //         {
    //             title: "Arabic",
    //             value: "Arabic",
    //         },
    //         {
    //             title: "Armenian",
    //             value: "Armenian",
    //         },
    //         {
    //             title: "Bengali",
    //             value: "Bengali",
    //         },
    //         {
    //             title: "Cantonese",
    //             value: "Cantonese",
    //         },
    //         {
    //             title: "English",
    //             value: "English",
    //         },
    //         {
    //             title: "English for language learners",
    //             value: "English for language learners",
    //         },
    //         {
    //             title: "Haitian Creole",
    //             value: "Haitian Creole",
    //         },
    //         {
    //             title: "Korean",
    //             value: "Korean",
    //         },
    //         {
    //             title: "Mandarin",
    //             value: "Mandarin",
    //         },
    //         {
    //             title: "Polish",
    //             value: "Polish",
    //         },
    //         {
    //             title: "Punjabi",
    //             value: "Punjabi",
    //         },
    //         {
    //             title: "Russian",
    //             value: "Russian",
    //         },
    //         {
    //             title: "Spanish",
    //             value: "Spanish",
    //         },
    //         {
    //             title: "Tagalog",
    //             value: "Tagalog",
    //         },
    //         {
    //             title: "Vietnamese",
    //             value: "Vietnamese",
    //         },
    //         {
    //             title: "Khmer",
    //             value: "Khmer",
    //         },
    //         {
    //             title: "Samoan",
    //             value: "Samoan",
    //         },
    //     ],
    //     multiple: true,
    // },
    {
        key: "courseElegibility",
        label: "Course eligibility requirements",
        placeholder: "Add any prerequisites or eligibility requirements the course may have.",
        type: "text",
    },
    {
        key: "sessionAvailability",
        label: "Are there one or more sessions for this course?",
        type: "autocomplete",
        options: [
            { title: "Yes", value: true },
            { title: "No", value: false },
        ],
        required: true,
    },
    { key: "sessionAvailabilityDescription", label: "Description about session availability in future", type: "text" },
    {
        key: "registrationStatus",
        label: "Course registration status",
        type: "autocomplete",
        options: [
            { title: "Open", value: "Open" },
            { title: "Closed", value: "Closed" },
            { title: "Coming soon", value: "Coming soon" },
        ],
    },
    { key: "sessions", label: "Sessions", type: "session" },

    // {
    //     key: "registrationInstructions",
    //     label: "Registration Instructions",
    //     type: "richtexteditor",
    //     options: [],
    //     multiple: true,
    //     freeSolo: true,
    //     autoSelect: true,
    // },
    { key: "prerequisticsDesc", label: "Prerequisites Description", type: "text" },
    {
        key: "courseDifficultyLevel",
        label: "Course difficulty level",
        type: "autocomplete",
        options: [
            { title: "Basic", value: "Basic" },
            { title: "Intermediate", value: "Intermediate" },
            { title: "Advanced", value: "Advanced" },
        ],
        required: true,
    },
    {
        key: "trainingFocus",
        label: "Training focus",
        type: "autocomplete",
        options: [
            { title: "Residential care", value: "Residential care" },
            { title: "Care in the home", value: "Care in the home" },
            { title: "Adult day services", value: "Adult day services" },
        ],
        required: true,
    },
    {
        key: "whoTheClassisDesignedFor",
        label: "Who the class is designed for",
        type: "autocomplete",
        options: [
            { title: "Community health workers", value: "Community health workers" },
            { title: "Transportation providers", value: "Transportation providers" },
            { title: "Adult day services staff", value: "Adult day services staff" },
            { title: "Family/friend unpaid caregiver", value: "Family/friend unpaid caregiver" },
            { title: "In-home care worker", value: "In-home care worker" },
            {
                title: "Assisted living/adult residential care worker",
                value: "Assisted living/adult residential care worker",
            },
            { title: "Anyone", value: "Anyone" },
        ],
        required: true,
    },
    // { key: "formattedDescription", label: "Formatted Description", type: "text" },
    // {
    //     key: "isActive",
    //     label: "Is Active",
    //     type: "autocomplete",
    //     options: [
    //         { title: "Yes", value: true },
    //         { title: "No", value: false },
    //     ],
    // },
];

const validateCourseData = (course: any) => {
    const validationData: any = {};
    validationData.number = !course.number;
    validationData.courseCreatedDate = !course.courseCreatedDate;
    validationData.description = !course.description;
    validationData.incentiveEligibility = !course.incentiveEligibility;
    validationData.incentiveTypeName = !course.incentiveTypeName;
    validationData.isTranslated = !course.isTranslated;
    validationData.isAvailable = !course.isAvailable;
    validationData.sessionAvailability = !course.sessionAvailability;
    validationData.name = !course.name;
    validationData.programName = !course.programName;
    validationData.courseDifficultyLevel = !course.courseDifficultyLevel;
    validationData.trainingFocus = !course.trainingFocus;
    validationData.whoTheClassisDesignedFor = !course.whoTheClassisDesignedFor;

    const areDirectFieldsValid = !Object.values(validationData).some(Boolean);

    validationData.sessions = course.sessions?.map((session: any) => ({
        code: !session.code,
        registrationStatus: !session.registrationStatus,
        schedules: session.schedules?.map((schedule: any) => ({
            date: !schedule.date,
            startTime: !schedule.startTime,
            endTime: !schedule.endTime,
            weekday: !schedule.weekday,
        })),
    }));

    const areSubFieldsValid = !validationData.sessions?.some((session: any) => {
        return (
            session.code ||
            session.registrationStatus ||
            session.schedules.some((schedule: any) => {
                return Object.values(schedule).some(Boolean);
            })
        );
    });
    console.log("validationData", validationData);
    // validationData.languages = !course.languages?.length;
    // validationData.formats = !course.formats?.length;
    // validationData.topics = !course.topics?.length;
    return { isValid: areDirectFieldsValid, validationData: validationData };
};

const transformToFormData = (course: any) => {
    const transformedFormData = JSON.parse(JSON.stringify(course));
    transformedFormData.number = `${transformedFormData.number}`;
    transformedFormData.courseCreatedDate = transformedFormData.createdAt
        ? moment(transformedFormData.createdAt)
        : transformedFormData.createdAt;
    transformedFormData.formats =
        transformedFormData.formats?.map((format: any) => ({
            title: format,
            value: format,
        })) || [];
    transformedFormData.incentiveTypeName = {
        title: transformedFormData.incentiveTypeName,
        value: transformedFormData.incentiveTypeName,
    };
    transformedFormData.durationMinutes =
        transformedFormData?.durationHours?.split(":")[1]?.length > 1
            ? transformedFormData?.durationHours?.split(":")[1]
            : +transformedFormData?.durationHours?.split(":")[1] * 10;
    transformedFormData.durationHours = +transformedFormData?.durationHours?.split(":")[0];
    transformedFormData.isAvailable = transformedFormData.isAvailable
        ? { title: "Available", value: true }
        : { title: "Not available", value: false };
    transformedFormData.isTranslated = transformedFormData.isTranslated
        ? { title: "Yes", value: true }
        : { title: "No", value: false };
    transformedFormData.languages =
        transformedFormData.languages?.map((language: any) => ({
            title: language,
            value: language,
        })) || [];
    transformedFormData.primaryPathway = {
        title: transformedFormData.primaryPathway,
        value: transformedFormData.primaryPathway,
    };
    // transformedFormData.registrationInstructions =
    //     transformedFormData.registrationInstructions?.map((ins: any) => ({
    //         title: ins,
    //         value: ins,
    //     })) || [];
    transformedFormData.registrationStatus = {
        title: transformedFormData.registrationStatus,
        value: transformedFormData.registrationStatus,
    };
    transformedFormData.sessionAvailability = transformedFormData.sessionAvailability
        ? { title: "Yes", value: true }
        : { title: "No", value: false };
    transformedFormData.topics =
        transformedFormData.topics?.map((topic: any) => ({
            title: topic,
            value: topic,
        })) || [];

    transformedFormData.sessions =
        transformedFormData.sessions?.map((session: any) => ({
            id: uuidv4(),
            code: session.code,
            formats: session.formats.map((format: any) => ({
                title: format,
                value: format,
            })),
            languages: session.languages?.map((language: any) => ({
                title: language,
                value: language,
            })),
            registrationInstructions: session.registrationInstructions?.toString(),
            zipcode: session.geography?.zip,
            county: session.geography?.county,
            city: session.geography?.city,
            registrationStatus: { title: session.registrationStatus, value: session.registrationStatus },
            seatCapacity: session.seatCapacity,
            schedules:
                session.schedules?.map((schedule: any) => {
                    let startTime;
                    if (schedule.startTime) {
                        startTime = moment();
                        const [startHours, startMinutes = "0"] = `${schedule.startTime}`.split(".");
                        startTime.hours(+startHours);
                        startTime.minutes(+startMinutes.length > 1 ? +startMinutes : +startMinutes * 10);
                    }

                    let endTime;
                    if (schedule.endTime) {
                        endTime = moment();
                        const [endHours, endMinutes = "0"] = `${schedule.endTime}`.split(".");
                        endTime.hours(+endHours);
                        endTime.minutes(endMinutes?.length > 1 ? +endMinutes : +endMinutes * 10);
                    }

                    return {
                        scheduleId: uuidv4(),
                        date: schedule.date ? moment(schedule.date) : schedule.date,
                        startTime: schedule.startTime ? startTime : schedule.startTime,
                        endTime: schedule.endTime ? endTime : schedule.endTime,
                        weekday: { title: schedule.weekday, value: schedule.weekday },
                        formatSubType: schedule.formatSubType,
                    };
                }) || [],
        })) || [];
    transformedFormData.courseDifficultyLevel = {
        title: transformedFormData.courseDifficultyLevel,
        value: transformedFormData.courseDifficultyLevel,
    };
    transformedFormData.trainingFocus = {
        title: transformedFormData.trainingFocus,
        value: transformedFormData.trainingFocus,
    };
    transformedFormData.whoTheClassisDesignedFor = {
        title: transformedFormData.whoTheClassisDesignedFor,
        value: transformedFormData.whoTheClassisDesignedFor,
    };
    return transformedFormData;
};

const transformCourseData = (course: any, editMode?: boolean, username?: string) => {
    const transformedCourseData = JSON.parse(JSON.stringify(course));
    transformedCourseData.name = `${transformedCourseData.name || ""}`.trim();
    transformedCourseData.number = `${transformedCourseData.number || ""}`.trim();
    transformedCourseData.createdAt = moment(transformedCourseData.courseCreatedDate).toDate();
    delete transformedCourseData.courseCreatedDate;
    transformedCourseData.formats = transformedCourseData.formats.map((format: any) => format.value);
    transformedCourseData.incentiveTypeName = transformedCourseData.incentiveTypeName?.value;
    transformedCourseData.isAvailable = Boolean(transformedCourseData.isAvailable?.value);
    transformedCourseData.isTranslated = Boolean(transformedCourseData.isTranslated?.value);
    // transformedCourseData.languages = transformedCourseData.languages.map((language: any) => language?.value);
    transformedCourseData.durationHours = [
        transformedCourseData.durationHours || "0",
        transformedCourseData.durationMinutes?.length === 1
            ? `0${transformedCourseData.durationMinutes}`
            : `${transformedCourseData.durationMinutes || 0}`,
    ].join(".");
    transformedCourseData.languages = [];
    transformedCourseData.sessions?.forEach((session: any) => {
        session.languages?.forEach(
            (language: any) =>
                transformedCourseData.languages.includes(language.value) ||
                transformedCourseData.languages.push(language.value)
        );
    });
    transformedCourseData.primaryPathway = transformedCourseData.primaryPathway?.value;
    // transformedCourseData.registrationInstructions = transformedCourseData.registrationInstructions
    //     ? [transformedCourseData.registrationInstructions]
    //     : [];
    transformedCourseData.registrationStatus = transformedCourseData.registrationStatus?.value;
    transformedCourseData.sessionAvailability = Boolean(transformedCourseData.sessionAvailability?.value);
    transformedCourseData.topics = transformedCourseData.topics.map((topic: any) => topic?.value);
    transformedCourseData.courseDifficultyLevel = transformedCourseData.courseDifficultyLevel?.value;
    transformedCourseData.trainingFocus = transformedCourseData.trainingFocus?.value;
    transformedCourseData.whoTheClassisDesignedFor = transformedCourseData.whoTheClassisDesignedFor?.value;

    transformedCourseData.sessions = transformedCourseData.sessions.map((session: any) => ({
        code: session.code,
        formats: session.formats.map((format: any) => format.value),
        languages: session.languages.map((language: any) => language.value),
        registrationInstructions: session.registrationInstructions ? [session.registrationInstructions] : [],
        geography: { zip: +session.zipcode, county: session.county, city: session.city },
        registrationStatus: session.registrationStatus?.value,
        seatCapacity: +session.seatCapacity,
        schedules: session.schedules?.map((schedule: any) => {
            let startTime: any = moment(schedule.startTime).minutes();
            if (startTime < 10) {
                startTime = `0${startTime}`;
            }
            let endTime: any = moment(schedule.endTime).minutes();
            if (endTime < 10) {
                endTime = `0${endTime}`;
            }
            return {
                date: schedule.date ? moment(schedule.date).toDate() : undefined,
                startTime: schedule.startTime ? +`${moment(schedule.startTime).hours()}.${startTime}` : undefined,
                endTime: schedule.endTime ? +`${moment(schedule.endTime).hours()}.${endTime}` : undefined,
                weekday: schedule.weekday?.value,
                formatSubType: schedule.formatSubType,
            };
        }),
    }));
    transformedCourseData.username = username;
    const timestamp = new Date().toISOString();
    transformedCourseData.lastModifiedDate = timestamp;
    if (!editMode) {
        transformedCourseData.createdDate = timestamp;
        delete transformedCourseData._id;
    }
    console.log("transformedCourseData", transformedCourseData);
    return transformedCourseData;
};

export const CourseForm = (props: { editMode?: boolean; handleBack: any; username?: string }) => {
    const [errorDetails, setErrorDetails] = useState({} as any);
    const [courseCreationStatus, setCourseCreationStatus] = useState(undefined as any);
    const [errorMessage, setErrorMessage] = useState("" as string);

    const location = useLocation();
    const visibility = useSelector(getVisibility);
    const navigate = useNavigate();

    const [course, setCourse] = useState(
        location.state?.course && props?.editMode ? transformToFormData(location.state?.course) : initialStateValue
    );

    // useEffect(() => {
    //     if (props.editMode) {
    //         console.log("location.state?.course", location.state?.course);
    //         if (location.state?.course?._id !== course?._id) {
    //             setCourse(transformToFormData(location.state?.course));
    //         }
    //     }
    // }, [location.state?.course]);

    const handleSelect = (selectedOptions: any, field: any) => {
        if (field.multiple) {
            setCourse({
                ...course,
                [field.key]: selectedOptions.map((_option: any) =>
                    typeof _option === "string" ? { title: _option, value: _option } : _option
                ),
            });
        } else {
            setCourse({ ...course, [field.key]: selectedOptions });
        }
    };

    const handleCreateCourse = async () => {
        setErrorDetails({});
        setErrorMessage("");
        // dispatch(setIsLoaderVisible(true));
        const validationResult = validateCourseData(course);
        console.log("validationResult", validationResult);
        if (validationResult?.isValid) {
            try {
                const response: any = await coursesAPI.createCourse(
                    transformCourseData(course, props.editMode, props?.username)
                );
                if (response.ok) {
                    setCourse(JSON.parse(JSON.stringify(initialStateValue)));
                    setCourseCreationStatus("success");
                } else {
                    console.log("response while saving course", response);
                    setCourseCreationStatus("error");
                    if (response?.data?.message) {
                        setErrorMessage(response.data.message);
                    }
                }
            } catch (e) {
                console.log("Error while saving course", e);
                setCourseCreationStatus("error");
            }
        } else {
            setErrorDetails(validationResult.validationData);
            setCourseCreationStatus("warning");
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
        // dispatch(setIsLoaderVisible(false));
    };

    const handlePreviewCourse = async () => {
        setErrorDetails({});
        // dispatch(setIsLoaderVisible(true));
        const validationResult = validateCourseData(course);
        if (validationResult?.isValid) {
            try {
                const transformedCourseData = transformCourseData(course, props.editMode, props?.username);
                const response = await coursesAPI.createCoursePreview(transformedCourseData);
                if (response.ok) {
                    window.open(
                        `${window.location.origin}/${COURSE_PREVIEW_DETAILS}/${formatName(course.name)}/${
                            course.number
                        }`
                        // /${formatTrainingFormat(transformedCourseData.formates, transformedCourseData.language)}
                        // {
                        //     replace: true,
                        // }
                    );
                    // setCourseCreationStatus("success");
                } else {
                    setCourseCreationStatus("error");
                }
            } catch (e) {
                console.log("Error while saving course", e);
                setCourseCreationStatus("error");
            }
        } else {
            setErrorDetails(validationResult.validationData);
            setCourseCreationStatus("warning");
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
        // dispatch(setIsLoaderVisible(false));
    };

    return (
        <>
            {courseCreationStatus && (
                <Container maxWidth="lg" sx={{ p: "25px" }}>
                    <Alert
                        variant="filled"
                        severity={courseCreationStatus as any}
                        onClose={() => setCourseCreationStatus(undefined)}
                    >
                        {courseCreationStatus === "success"
                            ? `The course is ${props.editMode ? "updated" : "created"} successfully`
                            : courseCreationStatus === "warning"
                            ? "Please fill all the required fields."
                            : `Failed to save course. ${errorMessage || ""}`}
                    </Alert>
                </Container>
            )}
            <Container maxWidth="lg" sx={{ p: "25px" }} data-testid="course-fields-component">
                {visibility.isLoaderVisible ? (
                    <Loader />
                ) : (
                    <Grid container spacing={3} key={courseCreationStatus}>
                        {COURSE_FIELDS.map((field: any) =>
                            field?.type === "text" ? (
                                <Grid key={field.key} item xs={field.xs || 12} md={field.md}>
                                    <InputLabel>
                                        {field.label}
                                        {field.required ? "*" : ""}:
                                    </InputLabel>
                                    <TextField
                                        error={errorDetails[field.key]}
                                        helperText={errorDetails[field.key] ? `Field is required` : undefined}
                                        fullWidth
                                        value={(course as any)[field.key] as string}
                                        onChange={(e: any) =>
                                            setCourse({
                                                ...course,
                                                [field.key]:
                                                    field.textType === "number" &&
                                                    e.target.value &&
                                                    field.max &&
                                                    +e.target.value > +field.max
                                                        ? field.max
                                                        : field.textType === "number"
                                                        ? e.target.value.split(".")[0]
                                                        : e.target.value,
                                            })
                                        }
                                        type={field.textType}
                                        placeholder={field.placeholder}
                                        InputProps={
                                            field.textType === "number"
                                                ? { inputProps: { min: 0, max: field.max } }
                                                : {}
                                        }
                                    />
                                </Grid>
                            ) : field?.type === "autocomplete" ? (
                                <Grid key={field.key} item xs={12}>
                                    <InputLabel>
                                        {field.label}
                                        {field.required ? "*" : ""}:
                                    </InputLabel>
                                    <Autocomplete
                                        freeSolo={field.freeSolo}
                                        multiple={field.multiple}
                                        autoSelect={field.autoSelect}
                                        disableCloseOnSelect={field.multiple}
                                        noOptionsText={field.placeholder}
                                        options={(field.options as any) || []}
                                        getOptionLabel={(option: any) => option.title || ""}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={field.placeholder || ""}
                                                error={errorDetails[field.key]}
                                                helperText={errorDetails[field.key] ? `Field is required` : undefined}
                                            />
                                        )}
                                        fullWidth
                                        value={course[field.key]}
                                        onChange={(e, values) => handleSelect(values, field)}
                                        isOptionEqualToValue={(option: any, value: any) =>
                                            option?.value === value?.value
                                        }
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => {
                                                return (
                                                    <Chip
                                                        sx={{ borderRadius: "4px" }}
                                                        variant="outlined"
                                                        label={typeof option === "string" ? option : option?.title}
                                                        {...getTagProps({ index })}
                                                        key={typeof option === "string" ? option : option?.value}
                                                    />
                                                );
                                            })
                                        }
                                    />
                                </Grid>
                            ) : field?.type === "richtexteditor" ? (
                                <Grid key={field.key} item xs={12}>
                                    <InputLabel>
                                        {field.label}
                                        {field.required ? "*" : ""}: Make sure your URL begins with https://
                                    </InputLabel>
                                    <ReactQuill
                                        theme="snow"
                                        value={course[field.key]}
                                        onChange={(value) => setCourse({ ...course, [field.key]: value })}
                                    />
                                    {/* <Autocomplete
                                        freeSolo={field.freeSolo}
                                        multiple={field.multiple}
                                        autoSelect={field.autoSelect}
                                        disableCloseOnSelect={field.multiple}
                                        noOptionsText={field.placeholder}
                                        options={(field.options as any) || []}
                                        getOptionLabel={(option: any) => option.title || ""}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={field.placeholder || ""}
                                                error={errorDetails[field.key]}
                                                helperText={errorDetails[field.key] ? `Field is required` : undefined}
                                            />
                                        )}
                                        fullWidth
                                        value={course[field.key]}
                                        onChange={(e, values) => handleSelect(values, field)}
                                        isOptionEqualToValue={(option: any, value: any) =>
                                            option?.value === value?.value
                                        }
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => {
                                                return (
                                                    <Chip
                                                        sx={{ borderRadius: "4px" }}
                                                        variant="outlined"
                                                        label={typeof option === "string" ? option : option?.title}
                                                        {...getTagProps({ index })}
                                                        key={typeof option === "string" ? option : option?.value}
                                                    />
                                                );
                                            })
                                        }
                                    /> */}
                                </Grid>
                            ) : field?.type === "date" ? (
                                <Grid key={field.key} item xs={12}>
                                    <InputLabel>
                                        {field.label}
                                        {field.required ? "*" : ""}:
                                    </InputLabel>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            sx={{
                                                width: "100%",
                                                "& fieldset": errorDetails[field.key] && {
                                                    borderColor: "red !important",
                                                },
                                            }}
                                            value={course[field.key]}
                                            onChange={(newValue: any) =>
                                                setCourse({ ...course, [field.key]: newValue })
                                            }
                                            maxDate={moment(new Date())}
                                        />
                                        <Typography variant="body1" color={"red"}>
                                            {errorDetails[field.key] ? "Field is required" : ""}
                                        </Typography>
                                    </LocalizationProvider>
                                </Grid>
                            ) : field?.type === "session" ? (
                                <Grid key={field.key} item xs={12}>
                                    <InputLabel>{field.label}:</InputLabel>
                                    <Sessions
                                        errorDetails={errorDetails?.sessions}
                                        value={course[field.key]}
                                        onChange={(value: any) => setCourse({ ...course, [field.key]: value })}
                                    />
                                </Grid>
                            ) : (
                                <></>
                            )
                        )}
                        <Grid
                            item
                            xs={12}
                            sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                        >
                            <Button onClick={props?.handleBack}>Cancel</Button>
                            <Button variant="contained" color="secondary" onClick={handlePreviewCourse}>
                                Preview
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleCreateCourse}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </>
    );
};

export default CourseForm;
