import { useTranslation } from "react-i18next";
import { SURVEY_ES_URL } from '../constant/path';
import { SURVEY_URL } from '../constant/path';
import { Helmet } from 'react-helmet';
import Typography from '@mui/material/Typography';

export default function SurveyEs() {
  const translationNamespace = ['surveyTranslations']
  const { t } = useTranslation(translationNamespace);
  const metaDesc: string = t('metaDesc')
  const htmlFile = `https://docs.google.com/forms/d/e/1FAIpQLSdiwyn8U_IrFHJ2jIKhwUqQnwp8GwjrIJOr38SgqGO_Kp6fmw/viewform?embedded=true`
  return (
    <>
      <Helmet>
        <title>{t('browserTitle')}</title>
        <meta name="description" content={metaDesc} />
        <link rel="canonical" href={`${process.env.REACT_APP_URL}/${SURVEY_ES_URL}`} />
      </Helmet>
      <div className="main-content-wrapper">
        <Typography variant="subtitle1" gutterBottom>
          <center><a href={SURVEY_URL}>In English</a></center>
        </Typography>        <iframe src={htmlFile} style={{ width: "100%", height: "1660px", border: 'none' }}></iframe>
      </div>
    </>
  );
}