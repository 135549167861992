const MobilePatternBottom = () => {
  return (
    <div className="banner-patterns mobile-patterns-bottom" data-testid="mobile-pattern-bottom-component">
      <svg
        width="58"
        height="91"
        viewBox="0 0 58 91"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58 78C58 70.8224 52.1811 65 45 65C37.8189 65 32 70.8189 32 78C32 85.181 37.8189 91 45 91C52.1811 91 58 85.181 58 78ZM54.099 78C54.099 72.9736 50.0264 68.901 45 68.901C39.9736 68.901 35.901 72.9736 35.901 78C35.901 83.0264 39.9736 87.099 45 87.099C50.0264 87.099 54.099 83.0264 54.099 78Z"
          fill="#E4874D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 45.5C18 40.2547 13.9692 36 9 36C4.03078 36 0 40.2547 0 45.5C0 50.7453 4.03078 55 9 55C13.9692 55 18 50.7453 18 45.5ZM15.2987 45.5C15.2987 41.8266 12.4768 38.8479 8.99674 38.8479C5.51665 38.8479 2.69479 41.8266 2.69479 45.5C2.69479 49.1734 5.51665 52.1521 8.99674 52.1521C12.4768 52.1521 15.2987 49.1734 15.2987 45.5Z"
          fill="#E4874D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M58 13C58 5.82238 52.1811 0 45 0C37.8189 0 32 5.81895 32 13C32 20.1811 37.8189 26 45 26C52.1811 26 58 20.1811 58 13Z"
          fill="#577CAE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35 44.8508C35 50.2911 39.3485 54.7017 44.7122 54.7017C50.0759 54.7017 54.4244 50.2911 54.4244 44.8508C54.4244 39.4106 50.0759 35 44.7122 35C39.3485 35 35 39.4106 35 44.8508ZM37.9141 44.8508C37.9141 48.659 40.9576 51.746 44.7122 51.746C48.4668 51.746 51.5103 48.659 51.5103 44.8508C51.5103 41.0426 48.4668 37.9557 44.7122 37.9557C40.9576 37.9557 37.9141 41.0426 37.9141 44.8508Z"
          fill="#E1C95D"
        />
      </svg>
    </div>
  );
};

export default MobilePatternBottom;
