import { Box, Pagination } from "@mui/material";
import { ChangeEvent } from "react";
import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router";
import { setPage } from "../../store/reducer/searchParameters";

export default function PaginationBox(props: { page: number, count: number, onClick: any }) {
    const { count, page, onClick} = props

    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const handleChange = (event: ChangeEvent<unknown>, value: number) => {
        dispatch(setPage(value))
        onClick(value)
    };

    return (
        <Box className='pagination-area' data-testid='pagination-box-component'>
            <Pagination
                count={count}
                size="large"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
                className='pagination-list'
            />
        </Box>
    );
}
