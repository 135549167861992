import {
  Container,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
  Alert,
} from "@mui/material";
import QuestionCategoryList from "../components/FAQ/QuestionCategoryList";
import { useTranslation } from "react-i18next";
import { ChangeEvent, useEffect, useState } from "react";
// import CategorySelector from "../components/FAQ/CategorySelector";
import InfoContainer from "../components/home/InfoContainer";
// import { HSFORM_URL, FAQ_URL } from "../constant/path";
import { FAQ_URL } from "../constant/path";
import CirclePatternFourFaq from "../components/common/circlePatternFourFaq/circlePatternFourFaq";
// import { coachingType, coursesType, gettingProgramInformationType, incentiveType, eligibilityType } from "../constant/faqCategory";
// import { coursesType, gettingProgramInformationType, incentiveType, eligibilityType } from "../constant/faqCategory";
import { Helmet } from "react-helmet";
import { useDebounce } from "../hooks/useDebounce";
import triggerGTMEvent from "../hooks/gtmEventTrigger";
import { FAQ_SEARCH_EVENT } from "../constant/tagManagerEvent";

const FAQPage = () => {
  const [searchText, setSearchText] = useState("");
  // const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [selectedCategory] = useState<string[]>([]);
  const translationNamespace = ["faq"];
  const { t } = useTranslation(translationNamespace);
  
  const debouncedSearchText = useDebounce(searchText, 1000)

  // const gettingProgramInformation = t("cat1.name");
  // const eligibility = t("cat2.name");
  // const courses = t("cat3.name");
  // const coaching = t("cat4.name");
  // const incentivetypes = t("cat5.name");

  const footerTitle = t("footerTitle");
  const footerDescription = t("footerDescription");
  // const footerActionBtntext = t("footerActionBtntext");

  useEffect(() => {
    if(debouncedSearchText && debouncedSearchText.trim().length > 0) {
      // This will trigger custom event to google Tag Manager to capture faq search text
      triggerGTMEvent(FAQ_SEARCH_EVENT, {searchText: searchText})
    }
  }, [debouncedSearchText])

  // const onCategoryClick = (btn: string) => {
  //   if (selectedCategory.includes(btn)) {
  //     setSelectedCategory([]);
  //   } else {
  //     setSelectedCategory([btn]);
  //   }
  // };

  const onChangeCapture = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  // const isSelected = (btn: string): boolean => selectedCategory.includes(btn);
  const metaDesc: string = t('metaDesc')
  return (
    <>
      <Helmet>
        <title>{t('browserTitle')}</title>
        <meta name="description" content={metaDesc} />
        <link rel="canonical" href={`${process.env.REACT_APP_URL}/${FAQ_URL}`}/>
      </Helmet>
      <div className="main-content-wrapper position-relative" data-testid="faq-page-view">
           <Container maxWidth="xl">
<Alert 
        variant="filled" 
        severity="info"
        color='warning'
        >
            <Typography variant="h5">
          Do not miss out! - All CalGrows Incentive Claims MUST be submitted by 8/31 for payment.</Typography>
        </Alert>
           </Container>
        
        <CirclePatternFourFaq />
        <Grid item xs={12} sx={{ paddingBottom: "50px", paddingTop: "30px" }}>
          <Typography
            variant="h3"
            className="h3-title-text"
            sx={{ textAlign: "center" }}
          >
            {t('title')}
          </Typography>
        </Grid>
        <Container maxWidth="lg">
          <Paper
            component="form"
            sx={{
              marginTop: "7px",
              marginBottom: "15px",
              p: "27px 24px",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F5F5F5",
              borderColor: "#000",
              borderRadius: "12px",
            }}
            className="faq-search-input"
          >
            <IconButton type="button" aria-label="search">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.3845 26.6L33 33M30.8667 15.9333C30.8667 24.1808 24.1808 30.8667 15.9333 30.8667C7.68588 30.8667 1 24.1808 1 15.9333C1 7.68588 7.68588 1 15.9333 1C24.1808 1 30.8667 7.68588 30.8667 15.9333Z"
                  stroke="black"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </IconButton>
            <InputBase
              id="search"
              name="search"
              data-testid="test-id-search-input"
              onChangeCapture={onChangeCapture}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              sx={{ ml: 1, flex: 1, color:'#000', fontSize:'18px', fontWeight:'700' }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              autoComplete="off"
              required
              className="search-input"
            />
          </Paper>
        </Container>
        <Container maxWidth="lg" sx={{ p: "50px" }}>
          <QuestionCategoryList
            selectedCategory={selectedCategory}
            searchText={searchText}
          />
        </Container>
        <InfoContainer
          title={footerTitle}
          description={footerDescription}
          // buttonText={footerActionBtntext}
          // link={HSFORM_URL}
          // buttonId="faq_get_connected_btn"
        />
      </div>
    </>
  );
};

export default FAQPage;
