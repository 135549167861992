import { Box, Button, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import { useLocation, useNavigate } from "react-router";

export default function InfoCard(props: {
  imageUrl: string;
  title: string;
  description: string;
  buttonText?: string;
  link?: string;
  onClick?: any;
  color?: string;
  actionId?: string
}) {
  const { imageUrl, title, description, buttonText, link, onClick, color, actionId } = props;
  const navigate = useNavigate();
  const currentLocation = useLocation().pathname.replace("/", "");
  return (
    <Box
      border={1}
      sx={{
        width: "100%",
        borderColor: "#000",
        // padding: "10px",
        boxSizing: "border-box",
        backgroundColor: color,
      }}
      className={"info-cards " + currentLocation} // we are using this value for dynamic style
      data-testid="info-card-container"
    >
      <div>
        <div>
          <img src={imageUrl} width="75" height="75" alt={title}/>
          <Typography variant="h4">
            <Trans components={{ newLine: <br /> }}>{title}</Trans>
          </Typography>
        </div>
        <Typography variant="body1" sx={{ marginTop: "15px" }}>
          {description}
        </Typography>
      </div>

      {link && (
        <Button
          variant="contained"
          size="large"
          id={actionId}
          onClick={() => {
            onClick && onClick();
            navigate(link);
          }}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
}
