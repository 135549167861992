import { useTranslation } from "react-i18next";
import HighlighterWrapper from "../../common/HighlighterWrapper";
import ParagraphWrapper from "../../common/ParagraphWrapper";
import EligibilityTable from "../../common/EligibilityTable";

const C2Q3 = (props: { searchText: string; }) => {
    const { searchText } = props;
    const searchTexts = searchText.split(" ");
    const translationNamespace = ["faq"];
    const { t } = useTranslation(translationNamespace);
    const ab2p3 = t("cat2.details.ab2p3");
    const ab2p4 = t("cat2.details.ab2p4");
    const ab2p5 = t("cat2.details.ab2p5");
    const ab2p6 = t("cat2.details.ab2p6");
    const ab2p7 = t("cat2.details.ab2p7");
    const ab2t1 = t("cat2.details.ab2t1");
    const ab2t2 = t("cat2.details.ab2t2");
  
    return (
      <>
        <ParagraphWrapper searchTexts={searchTexts} textToHighlight={ab2p3?.toString()} />
        <ParagraphWrapper searchTexts={searchTexts} textToHighlight={ab2p4?.toString()} />
        <ul>
          <li>
            <HighlighterWrapper searchTexts={searchTexts} textToHighlight={ab2p5?.toString()} />
          </li>
          <li>
            <HighlighterWrapper searchTexts={searchTexts} textToHighlight={ab2p6?.toString()} />
          </li>
        </ul>
        <ParagraphWrapper searchTexts={searchTexts} textToHighlight={ab2p7?.toString()} />
        <EligibilityTable job={ab2t1} eligibility={ab2t2}></EligibilityTable>
      </>
    );
  };

export default C2Q3