import { Container, Grid, Typography } from "@mui/material";
import { Fragment } from "react";

export default function SomethingWentWrong() {
    return (
        <Fragment>
            <Container maxWidth="xl" data-testid="something-went-wrong-component">
                <Grid container spacing={3} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    paddingTop: "100px"
                }}>
                    <Typography variant="h4">Something Went Wrong!</Typography>
                    <Typography variant="h6">
                        Please Try Again Later!
                    </Typography>
                </Grid>
            </Container>
        </Fragment >
    )

}
