// import { Grid, Typography } from "@mui/material";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

function ResultCount(props: { count: number | null }) {
  const translationNamespace = ['caregiverTrainingsTranslations']
  const { t } = useTranslation(translationNamespace);
  const { count } = props;

  return (
    <Typography variant="body1" sx={{ fontWeight:'400', fontSize:"18px"}} data-testid="result-count-component">{count == 0  ? "" : count==1 ? `${count} ${t("result")}` : `${count} ${t("results")}`}</Typography>
  );
}

export default ResultCount;