import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ELIGIBILITY_URL } from '../../constant/path';

function createData (job: string, eligibility: string,) {
  return { job, eligibility };
}

const EligibilityTable = (props: { job: any; eligibility: any; }) => {
  const { job, eligibility } = props;
  const rows = [];
  for (const item of job) {
    rows.push(createData(job[job.indexOf(item)], eligibility[job.indexOf(item)]));
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">

        <TableHead sx={{ 'background-color':'gray' }}>
          <TableRow sx={{ border: 2 }}>
            <TableCell sx={{ border: 2 }}>Job(s)</TableCell>
            <TableCell sx={{ border: 2 }} align="center">Eligible</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.job} sx={{ border: 2 }}>
              <TableCell component="th" scope="row">
                <Trans
                  components={{
                    Link1: (
                      <Link
                        to={`../${ELIGIBILITY_URL}`}
                        id="eligibility_link"
                        rel="noopener noreferrer"
                        color="inherit"
                      />
                    ),
                  }}
                >
                {row.job}
              </Trans>
              </TableCell>
              <TableCell sx={{ border: 2 }} align="center">{row.eligibility}</TableCell>
            </TableRow>
          ))}
        </TableBody>

      </Table>
    </TableContainer>
  );
}

export default EligibilityTable;
