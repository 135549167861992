import { Grid, Container, Typography, List, ListItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import TrackLevelWrapper from "./TrackLevelWrapper";

export default function IncentiveTracks() {
  const translationNamespace = ["getPaidTranslations"];
  const { t } = useTranslation(translationNamespace);
  const incentiveTrackList: [] | any = t("incentiveTracks.list");
  return (
    <div className="get-connect-today-section bg-light-yellow">
      <Container maxWidth="xl" sx={{ p: "50px" }}>
        <Typography
          variant="h3"
          className=""
          sx={{ pl: "40px", color: "#8C4799", textAlign: "left" }}
        >
          {t("incentiveTracks.title")}
        </Typography>
        <Grid container spacing={2} sx={{ justifyContent: "left" }}>
          <Grid item sm={12} md={6} sx={{ paddingBottom: "50px" }}>
            <Typography
              variant="body2"
              className="condition-text"
              sx={{ pl: "40px", textAlign: "left" }}
            >
              {t("incentiveTracks.paragraph")}
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 10,
                "& .MuiListItem-root": { display: "list-item" },
              }}
            >
              {incentiveTrackList.map((item: any, i: number) => (
                <ListItem key={i}>{item}</ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
        <TrackLevelWrapper/>
      </Container>
    </div>
  );
}
