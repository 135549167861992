import {
    Grid,
    Container,
    Typography,
  } from "@mui/material";
  import image from "../../images/testimonialMiaFrayseth.webp";
  import { Link } from "react-router-dom";
  // import CirclePatternFivePaymentsLearn from "../common/circlePatternFivePaymentsLearn/circlePatternFivePaymentsLearn";

  export default function Testimonial() {
    return (
      <Container
        maxWidth="xl"
        sx={{ p: "50px" }}
      >        
        <Grid container className="what-will-learn-section">

          <Grid item sm={12} md={12} lg={6}>
            <Typography variant="h3">Success Stories</Typography>
            <Typography variant="body2">
            Join thousands of caregivers who have transformed their lives through CalGrows trainings! We’re thrilled to share their inspiring success stories with you. Discover how people just like you have benefited from CalGrows training programs and incentive payments. Be inspired and explore the possibilities for your own growth—visit our site and continue your journey today!
            </Typography>
          </Grid>

          <Grid item sm={12} md={12} lg={6}>
            <div className="learn-today-bg">
            <Link to="https://youtube.com/playlist?list=PLXexWQOeFtkalNyQcP4z0jFd4XlVgsUJd&si=NeNHPB2HNsmhHbMc" target="_blank" rel="noopener noreferrer">
            <img src={image} width='520px' alt='testimonial'/>
            </Link>
            </div>
          </Grid>
           
        

        </Grid>
            {/* <div className="learn-today-bg">
            <CirclePatternFivePaymentsLearn />
            </div> */}
      </Container>
    );
  }
  