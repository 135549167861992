import * as React from "react";
import { Alert, AppBar, Box, Container, Link, Toolbar, Typography, Menu, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { useNavigate, useMatch } from "react-router";
// import TranslationDropdown from "./TranslationDropdown";
import {
  // CAREGIVER_COACHING_URL,
  CAREGIVER_TRAININGS_URL,
  GET_PAID_URL,
  FAQ_URL,
  ROOT_URL,
  CALGROWS_OUTREACH_PARTNER_TOOLKIT,
  COURSE_CATALOG_DETAILS_URL,
  COURSE_CATALOG_URL_WITH_SCROLL
} from "../../constant/path";
import logo from "../../images/cal-grows-logo.svg";
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from "react-router-dom";
// import Alert from '@mui/material/Alert';

export default function MenuBar() {
  const translationNamespace = ["headerTranslations"];
  const { t } = useTranslation(translationNamespace);

  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  
  const isCourseDetailsRoute = useMatch(COURSE_CATALOG_DETAILS_URL)

  const handleClick = (url: string)=>{
    handleCloseNavMenu();
    if (isCourseDetailsRoute && url==CAREGIVER_TRAININGS_URL) {
      navigate(COURSE_CATALOG_URL_WITH_SCROLL, {state: {keepPrevState: true}});
    } else {      
      navigate(url);
    }
    
  }
  return (
    <AppBar
      position="static"
      color="inherit"
      elevation={1}
      component="nav"
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
      className="site-header"
    >
      {open && <Alert
        className="top-news-alert-banner"
        icon={false}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
            className='close-icon-alert'
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{
          mb: 2,
          borderRadius: "0",
          border: "none",
          backgroundColor: "#8C47994D",
          color: "#000",
          textAlign: "center",
        }}
      >
        {t("resourcesForPartner")} {t("click")}
        <Link target="_blank" href={CALGROWS_OUTREACH_PARTNER_TOOLKIT} sx={{ fontWeight: "700", color: "#000" }}>
          {t("here")}
        </Link>
      </Alert>}
      <Container maxWidth="xl" className="app-bar-container">
        <Toolbar sx={{ flexWrap: "nowrap", width:"100%" }} className="header-inner-container">
          <Box className="brand-block">
            <Link
              color="inherit"
              variant="button"
              sx={{ p: 0, paddingLeft:0 }}
              onClick={() => {
                navigate(ROOT_URL);
              }}
              className="brand-link"
            >
              <img src={logo} className="logo-img" width="265" height="48" alt={`CalGrows ${t('home')}`}/>
            </Link>
          </Box>
          <Box sx={{ flexGrow: 1, display: { lg: 'flex', xl: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
              className="menu-btn-icon"
            >
              <MenuIcon  className="hm-icon"/>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >              
              <MenuItem 
                id="header_home_link"
                onClick={() => {
                  handleClick(ROOT_URL);
                }}>
                <Typography textAlign="center">{t("home")}</Typography>
              </MenuItem>
              <MenuItem
                id="header_caregiver_training_link"
                onClick={() => {
                  handleClick(CAREGIVER_TRAININGS_URL);
                }}>
                <Typography textAlign="center">{t("caregiverTrainings")}</Typography>
              </MenuItem>
              <MenuItem 
                id="header_get_paid_link"
                onClick={() => {
                  handleClick(GET_PAID_URL);
                }}>
                <Typography textAlign="center">{t("getPaid")}</Typography>
              </MenuItem>
              {/* <MenuItem 
                id="header_caregiver_coaching_link"
                onClick={() => {
                  handleClick(CAREGIVER_COACHING_URL);
                }}>
                <Typography textAlign="center">{t("caregiverCoaching")}</Typography>
              </MenuItem> */}
              <MenuItem 
                id="header_faq_link"
                onClick={() => {
                  handleClick(FAQ_URL);
                }}>
                <Typography textAlign="center">{t("faq")}</Typography>
              </MenuItem>
              {/* <TranslationDropdown />               */}
            </Menu>
          </Box>
          <Box className="nav-list" sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <NavLink
              color="inherit"
              end
              id="header_home_link"
              to={ROOT_URL}
            >
              {t("home")}
            </NavLink>
            <NavLink
              color="inherit"
              end
              id="header_caregiver_training_link"
              to={CAREGIVER_TRAININGS_URL}
            >
              {t("caregiverTrainings")}
            </NavLink>
            <NavLink
              color="inherit"
              id="header_get_paid_link"
              to={GET_PAID_URL}
            >
              {t("getPaid")}
            </NavLink>
            {/* <NavLink
              color="inherit"
              id="header_caregiver_coaching_link"
              to={CAREGIVER_COACHING_URL}
            >
              {t("caregiverCoaching")}
            </NavLink> */}
            <NavLink
              color="inherit"
              id="header_faq_link"
              to={FAQ_URL}
            >
              {t("faq")}
            </NavLink>
            {/* <TranslationDropdown /> */}
          </Box>
          
        </Toolbar>
      
      </Container>
     
    </AppBar>
     
        
  );
}
