import { Grid, Container, Typography } from "@mui/material";
import ContactInfoCard from "../common/ContactInfoCard";
import hoursOfOperation from "../../images/hoursOfOperation.svg";
import phone from "../../images/phone.svg";
import email from "../../images/email.svg";
import { useTranslation } from "react-i18next";

export default function GetConnected() {
  const translationNamespace = ["caregiverCoachingTranslations"];
  const { t } = useTranslation(translationNamespace);
  return (
    <div className="get-connect-today-section bg-light-yellow" data-testid="get-connected-component">
      <Container maxWidth="xl" sx={{ p: "50px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ paddingBottom: "50px" }}>
            <Typography
              variant="h3"
              className="h3-black-title-text"
              sx={{ color: "#000" }}
            >
              {t("getConnectedToday")}
            </Typography>
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <ContactInfoCard
              title={t("getConnected1.title")}
              value={t("getConnected1.value")}
              imageUrl={hoursOfOperation}
              link=""
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <ContactInfoCard
              title={t("getConnected2.title")}
              value="(888) 991-7234"
              imageUrl={phone}
              link="tel:(888) 991-7234"
            />
          </Grid>
          <Grid item sm={12} md={6} lg={4}>
            <ContactInfoCard
              title={t("getConnected3.title")}
              value={t("getConnected3.value")}
              imageUrl={email}
              link="mailto:help@calgrows.org"
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
