// import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { configureStore } from '@reduxjs/toolkit'
import searchParameters from './reducer/searchParameters';
import searchFilters from './reducer/searchFilters';
import searchResult from './reducer/searchResult';
import breadcrumb from './reducer/breadcrumb';
import course from './reducer/course';
import apiStatus from './reducer/apiStatus';
import storage from 'redux-persist/lib/storage';
import visibility from './reducer/visibility';
import userLanguage from './reducer/userLanguage'
import { persistReducer } from 'redux-persist';
import { combineReducers } from '@reduxjs/toolkit';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const presistConfig = {
    key: "root",
    version: 1,
    whitelist: ['searchParameters', 'userLanguage'],
    storage,
    stateReconcile: autoMergeLevel2
}

const reducers = combineReducers({
    searchParameters,
    searchResult,
    searchFilters,
    course,
    breadcrumb,
    apiStatus,
    visibility,
    userLanguage
})

const presistedReducers = persistReducer(presistConfig, reducers)

const store = configureStore({
    reducer: presistedReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

export type RootState = ReturnType<typeof store.getState>

export default store;