import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import AccordingWrapper from "./AccordingWrapper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { List } from "reselect/es/types";
// import { FC, useEffect, useState } from "react";
import { useEffect, useState } from "react";

export interface QuestionAnswer {
  question: string;
  answer: string;
  component?: any
}
const QuestionCategory = (props: {
  type: string;
  category: string;
  details: List<QuestionAnswer>;
  selectedCategory: List<string>;
  searchText: string;
}) => {
  const { type, category, details, selectedCategory, searchText } = props;
  if (details.length === 0) {
    return null;
  }  

  const [expanded, setExpanded] = useState<boolean>(false);
  const searchTexts = searchText ? searchText.split(" ") : [];
  const isAccordingExpanded = searchTexts.length > 0 || selectedCategory.length > 0 ;

  useEffect(() => {
   setExpanded(isAccordingExpanded)
  },[selectedCategory, searchText]);

  const handleChange = ( newExpanded: boolean ) => {
    setExpanded(newExpanded);
  };
  return (
    <>
      {selectedCategory.length > 0 && !selectedCategory.includes(type) ? null : (
        <Accordion expanded={expanded}  className="according-wrapper"  onChange={(event, newExpanded)=>handleChange(newExpanded)}>
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon className="icon-faq-arrow" />}
            aria-controls={type}
            id={type}
            sx={{ backgroundColor: "#F5F5F5" }}
            className='according-title'
          >
            <Typography sx={{ fontWeight: "700", color:'#000', fontSize:'1.5rem' }} variant="h4">
              {category}
            </Typography>
          </AccordionSummary>
          <AccordionDetails className="according-info">
            {details.map((i: QuestionAnswer) => (
              <AccordingWrapper key={i.question} question={i.question} answer={i.answer} AnswerComp={i.component} searchTexts={searchTexts} />
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

export default QuestionCategory;
