// import { Box, Button, Container, Grid, Link, Typography } from "@mui/material";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
// import { CAREGIVER_COACHING_URL, CAREGIVER_TRAININGS_URL, FAQ_URL, GET_PAID_URL, ROOT_URL } from "../constant/path";
import { CAREGIVER_TRAININGS_URL, FAQ_URL, GET_PAID_URL, ROOT_URL } from "../constant/path";
export default function NotFound() {
  const navigate = useNavigate();
  // const handleBack = () => {
  //   navigate(-1);
  // };

  const translationNamespace = ["headerTranslations"];
  const { t } = useTranslation(translationNamespace);

  return (
    <Fragment>
      <div className="main-content-wrapper" data-testid="not-found-view">
        <Container maxWidth="xl">
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              minHeight: "50vh",
            }}
          >
            <Typography variant="h1">404</Typography>
            <Typography variant="h6">
             {t("notExist")}
            </Typography>
            <Box sx={{marginTop: 5}}>
            <Link
              color="inherit"
              variant="button"
              sx={{ p: 2 }}
              onClick={() => {
                navigate(ROOT_URL);
              }}
            >
              {t("home")}
            </Link>
            <Link
              color="inherit"
              variant="button"
              sx={{ p: 2 }}
              onClick={() => {
                navigate(CAREGIVER_TRAININGS_URL);
              }}
            >
              {t("caregiverTrainings")}
            </Link>
            <Link
              color="inherit"
              variant="button"
              sx={{ p: 2 }}
              onClick={() => {
                navigate(GET_PAID_URL);
              }}
            >
              {t("getPaid")}
            </Link>
            {/* <Link
              color="inherit"
              variant="button"
              sx={{ p: 2 }}
              onClick={() => {
                navigate(CAREGIVER_COACHING_URL);
              }}
            >
              {t("caregiverCoaching")}
            </Link> */}
            <Link
              color="inherit"
              variant="button"
              sx={{ p: 2 }}
              onClick={() => {
                navigate(FAQ_URL);
              }}
            >
              {t("faq")}
            </Link>
          </Box>
          </Grid>
        </Container>
      </div>
    </Fragment>
  );
}
