// import timezone from "./timezone";

const formatTime = (time:any) => {
    if(time){
        let output = ""
        let timeOfDay = "am"

        const timeFloat = parseFloat(time.$numberDecimal || time)    
        // const minutes =  Math.floor(timeFloat)*60 + (timeFloat-Math.floor(timeFloat))*100 + timezone().offset;
        const minutes = Math.floor(timeFloat) * 60 + (timeFloat - Math.floor(timeFloat)) * 100;

        const hoursFloat = minutes/60
        let hours = Math.floor(hoursFloat);
        const rminutes = Math.round((hoursFloat - hours) * 60);

        if (hours>11){
            timeOfDay = "pm"
        }

        if (hours>12){
            hours = hours-12
        }

        if (rminutes < 10) {
            output = `${hours}:0${rminutes}${timeOfDay}`
        }
        else {
            output = `${hours}:${rminutes}${timeOfDay}`
        }
        return output;
    }
    else {
        return ""
    }
    
};

export default formatTime