import { Container, Typography } from "@mui/material";
import React from "react";
import './about-calgrows-info.scss'
import { useTranslation } from 'react-i18next';

const AboutCalGrowsInfo = () => {
    const translationNamespace = ['aboutCalGrowsTranslations'];
    const { t } = useTranslation(translationNamespace);

    return (
        <div className="about-calgrows-info">
            <Container maxWidth="xl" data-testid="about-calGrows-data-container">
                <Typography className="condition-text">{t('aboutCalGrows.paragraph')}</Typography>
                <Typography className="condition-text">{t('aboutCalGrows.paragraph2')}</Typography>
                <Typography className="condition-text">{t('aboutCalGrows.paragraph3')}</Typography>
                <Typography className="condition-text">{t('aboutCalGrows.paragraph4')}</Typography>
            </Container>
        </div>
    )
}

export default AboutCalGrowsInfo;