import { Link } from "react-router-dom";
import { PROD_COURSE_CATALOG_PATH } from "../../../constant/siteUrl";
import HighlighterWrapper from "../../common/HighlighterWrapper";
import { extractContent } from "../utils/common";
import { CAREGIVER_TRAININGS_URL } from "../../../constant/path";

const C6Q = (props: { searchText: string; paragraph1: string; paragraph2?: string }) => {
  const { searchText, paragraph1, paragraph2 } = props;
  const searchTexts = searchText.split(" ");
  const coursecatalogUrl = PROD_COURSE_CATALOG_PATH;

  const extractor = extractContent(paragraph1);
  const para1 = extractor=== null || extractor.input=== undefined ? [] : extractor.input.replace(extractor[0], "");
  const paragraph2Text = paragraph2 ? paragraph2 : "";
  return (
    <p data-testid='C6Q-component'>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={para1?.toString()} />
      <Link to={`../${CAREGIVER_TRAININGS_URL}`}>
        <HighlighterWrapper searchTexts={searchTexts} textToHighlight={coursecatalogUrl?.toString()} />
      </Link>
      <HighlighterWrapper searchTexts={searchTexts} textToHighlight={paragraph2Text?.toString()} />
    </p>
  );
};

export default C6Q;
