import Typography from "@mui/material/Typography";
import Question from "../FAQ/Question";
import {Accordion, AccordionDetails} from "@mui/material";
import HighlighterWrapper from "../common/HighlighterWrapper";
import { FC } from "react";
import ParagraphWrapper from "../common/ParagraphWrapper";

const AccordingWrapper = (props: { question: string; answer: string, AnswerComp? : FC; searchTexts: string[] }) => {
  const { question, answer, searchTexts, AnswerComp } = props;

  return (
    <Accordion   sx={{ "box-shadow": "none", "border": "none", marginBottom:'15px' }} className='details-list-according' data-testid="according-wrapper-component">
      <Question>
        <Typography className="t1">
          <HighlighterWrapper searchTexts={searchTexts} textToHighlight={question} />
        </Typography>
      </Question>
      <AccordionDetails className="sub-inner-info">
        <div style={{paddingLeft: "16px", marginTop:'-10px'}} className="sub-info-block">
          <>
            {AnswerComp}
            {!AnswerComp && <ParagraphWrapper searchTexts={searchTexts} textToHighlight={answer?.toString()} />}
        </>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordingWrapper;
