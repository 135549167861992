import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import MenuBar from '../components/common/MenuBar';
import Hubspot from '../components/common/Hubspot';
import i18next from "i18next";
import ScrollToTop from '../components/common/ScrollToTop';
import React from 'react';
import { LANGUAGES } from '../constant/translation';
import { useTranslation } from 'react-i18next';
import Footer from '../components/common/Footer';

const AppLayout = () => {
    const languages = [LANGUAGES.EN, LANGUAGES.ES, LANGUAGES.RU, LANGUAGES.KO, LANGUAGES.VI, LANGUAGES.ZH, LANGUAGES.HY] as string[]
  
    const location = useLocation()
    const navigate = useNavigate()
    const { i18n } = useTranslation();
    const { lang } = useParams()

    React.useEffect(() => {
      if(lang === undefined && i18n.language !== LANGUAGES.EN) {
        i18n.changeLanguage(LANGUAGES.EN)
      }

      if(lang && languages.includes(lang) && i18n.language !== lang) {
        i18n.changeLanguage(lang)
      }

    }, [lang])
  
    i18next.on('languageChanged', (lng) => {
      const languageUrl = generateLanguageUrl(lng, location.pathname)
      navigate(languageUrl)
      navigate(0)
    })
  
    const generateLanguageUrl = (newLanguage: string, activePath: string) => {
      const pathSegments = activePath.split('/')
      const languageSegment = pathSegments[1]
      let newPath = activePath;
  
      if (newLanguage === LANGUAGES.EN ) {
        newPath = pathSegments.reduce((a, b, index) => {
          if (index == 1 && languages.includes(languageSegment)) {
            if (pathSegments.length === 2) {
              return '/'
            } else {
              return `${a}`
            }
  
          }
          return `${a}/${b}`
        })
      } else if (languageSegment === '') {
        newPath = `/${newLanguage}`
  
      } else if (languages.includes(languageSegment)) {
        newPath = pathSegments.reduce((a, b, index) => {
          if (index == 1) {
            return `${a}/${newLanguage}`
          }
          return `${a}/${b}`
        })
      } else {
        newPath = pathSegments.reduce((a, b, index) => {
          if (index == 1) {
            return `${a}/${newLanguage}/${b}`
          }
          return `${a}/${b}`
        })
      }
      return newPath
    }
  
    return (
      <React.Fragment>
        <MenuBar />
        <ScrollToTop />
        <Hubspot />
        <Outlet />
        <Footer />
      </React.Fragment>
  
    )
  }

  export default AppLayout