import React from "react";
import ReactDOM from "react-dom/client";
import '@aws-amplify/ui-react/styles.css';
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import store from "./store/store";
import TagManager from 'react-gtm-module'

// ---TAG Manager Configs-------------
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GA_TAGMANAGER_ID ?? ''
}
TagManager.initialize(tagManagerArgs)
// ------------------------------------

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID ?? '');

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const persistor = persistStore(store)

root.render(
  <React.StrictMode>    
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
