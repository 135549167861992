import React, { useState } from "react";
import { Add, Delete, HdrPlus } from "@mui/icons-material";
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Card,
    Chip,
    Divider,
    Grid,
    IconButton,
    InputLabel,
    TextField,
    Typography,
} from "@mui/material";

import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import { Session } from "../../model/session";
import { v4 as uuidv4 } from "uuid";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const FORMATS = {
    key: "formats",
    label: "Course training format",
    type: "autocomplete",
    options: [
        {
            title: "Hybrid",
            value: "Hybrid",
        },
        {
            title: "In person",
            value: "In person",
        },
        {
            title: "Online (instructor led)",
            value: "Online (instructor led)",
        },
        {
            title: "Online (self-paced)",
            value: "Online (self-paced)",
        },
    ],
    multiple: true,
};

const WEEKDAY = {
    key: "weekday",
    label: "Course training format",
    type: "autocomplete",
    options: [
        {
            title: "Monday",
            value: "Monday",
        },
        {
            title: "Tuesday",
            value: "Tuesday",
        },
        {
            title: "Wednesday",
            value: "Wednesday",
        },
        {
            title: "Thursday",
            value: "Thursday",
        },
        {
            title: "Friday",
            value: "Friday",
        },
        {
            title: "Saturday",
            value: "Saturday",
        },
        {
            title: "Sunday",
            value: "Sunday",
        },
    ],
    multiple: true,
};
const LANGUAGES = {
    key: "languages",
    label: "Languages the course will be offered in",
    type: "autocomplete",
    options: [
        {
            title: "Arabic",
            value: "Arabic",
        },
        {
            title: "Armenian",
            value: "Armenian",
        },
        {
            title: "Bengali",
            value: "Bengali",
        },
        {
            title: "Cantonese",
            value: "Cantonese",
        },
        {
            title: "English",
            value: "English",
        },
        {
            title: "English for language learners",
            value: "English for language learners",
        },
        {
            title: "Haitian Creole",
            value: "Haitian Creole",
        },
        {
            title: "Khmer",
            value: "Khmer",
        },
        {
            title: "Korean",
            value: "Korean",
        },
        {
            title: "Mandarin",
            value: "Mandarin",
        },
        {
            title: "Polish",
            value: "Polish",
        },
        {
            title: "Punjabi",
            value: "Punjabi",
        },
        {
            title: "Russian",
            value: "Russian",
        },
        {
            title: "Samoan",
            value: "Samoan",
        },
        {
            title: "Spanish",
            value: "Spanish",
        },
        {
            title: "Tagalog",
            value: "Tagalog",
        },
        {
            title: "Vietnamese",
            value: "Vietnamese",
        },
    ],
    multiple: true,
};

const REGISTRATION_STATUS = {
    key: "registrationStatus",
    label: "Course registration status",
    type: "autocomplete",
    options: [
        { title: "Open", value: "Open" },
        { title: "Closed", value: "Closed" },
        { title: "Coming soon", value: "Coming soon" },
    ],
};

const Sessions = (props: any) => {
    const { value, onChange, errorDetails } = props;
    const [sessions, setSessions] = useState(value || ([] as Session[]));

    console.log("sessions", sessions);
    useEffect(() => {
        onChange(sessions);
    }, [sessions]);

    const handleAddSession = () => {
        setSessions((_sessions: any) => [
            ..._sessions,
            {
                geography: {},
                schedules: [],
                registrationStatus: "",
                registrationInstructions: [],
                languages: [],
                formats: [],
                code: "",
                id: uuidv4(),
            },
        ]);
    };

    const handleDeleteSession = (id: string) => {
        setSessions((_sessions: any) => {
            const modifiedSessions = [..._sessions];
            const deleteIndex = modifiedSessions.findIndex((_s) => _s.id === id);
            modifiedSessions.splice(deleteIndex, 1);
            return modifiedSessions;
        });
    };

    const handleEditSession = (id: string, key: string, value: any) => {
        setSessions((_sessions: any) => {
            const modifiedSessions = [..._sessions];
            const sessionIndex = modifiedSessions.findIndex((_s) => _s.id === id);
            if (sessionIndex !== -1) {
                const modifiedSession = { ...modifiedSessions[sessionIndex] };
                modifiedSession[key] = value;
                modifiedSessions.splice(sessionIndex, 1, modifiedSession);
            }
            return modifiedSessions;
        });
    };

    const handleEditSchedule = (
        id: string,
        action: "add" | "delete" | "update",
        scheduleId?: string,
        key?: string,
        value?: any
    ) => {
        setSessions((_sessions: any) => {
            const modifiedSessions = [..._sessions];
            const updateIndex = modifiedSessions.findIndex((_s) => _s.id === id);
            const modifiedSession = { ...modifiedSessions[updateIndex] };
            if (action === "add") {
                modifiedSession.schedules.push({
                    scheduleId: uuidv4(),
                    date: undefined,
                    formatSubType: "",
                    startTime: undefined,
                    endTime: undefined,
                    weekday: "",
                });
            } else if (action === "delete") {
                const scheduleIndex = modifiedSession.schedules.findIndex((_s: any) => _s.scheduleId === scheduleId);
                modifiedSession.schedules.splice(scheduleIndex, 1);
            } else if (action === "update") {
                const scheduleIndex = modifiedSession.schedules.findIndex((_s: any) => _s.scheduleId === scheduleId);
                if (scheduleIndex !== -1) {
                    const modifiedSchedule = { ...modifiedSession.schedules[scheduleIndex] };
                    modifiedSchedule[key as string] = value;
                    modifiedSession.schedules.splice(scheduleIndex, 1, modifiedSchedule);
                }
            }
            modifiedSessions.splice(updateIndex, 1, modifiedSession);

            return modifiedSessions;
        });
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                "& >:not(:last-child)": {
                    mb: 2,
                },
            }}
        >
            {sessions?.length ? (
                sessions.map((session: Session, index: number) => (
                    <Card
                        key={session.id}
                        sx={{
                            width: "100%",
                        }}
                    >
                        <CardHeader
                            sx={{ p: 1 }}
                            action={
                                <IconButton
                                    size={"small"}
                                    aria-label="delete"
                                    onClick={() => handleDeleteSession(session.id as string)}
                                >
                                    <Delete fontSize={"small"} />
                                </IconButton>
                            }
                            subheader={`Session #${index + 1}`}
                        />
                        <Divider />
                        <Grid container spacing={3} sx={{ p: 2 }}>
                            <Grid item xs={12}>
                                <InputLabel>Session code:</InputLabel>
                                <TextField
                                    error={errorDetails?.[index]?.code}
                                    helperText={errorDetails?.[index]?.code ? "Field is required" : undefined}
                                    fullWidth
                                    // type="number"
                                    // inputProps={{ min: "0", step: "1" }}
                                    // onKeyPress={(event) => {
                                    //     if (event?.key === "-" || event?.key === "+") {
                                    //         event.preventDefault();
                                    //     }
                                    // }}
                                    value={session.code}
                                    onChange={(e: any) =>
                                        handleEditSession(session.id as string, "code", e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Formats:</InputLabel>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    options={(FORMATS.options as any) || []}
                                    getOptionLabel={(option: any) => option.title || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={errorDetails?.[index]?.formats}
                                            helperText={
                                                errorDetails?.[index]?.formats ? "Field is required" : undefined
                                            }
                                        />
                                    )}
                                    fullWidth
                                    value={session.formats as any[]}
                                    onChange={(e, values) => handleEditSession(session.id as string, "formats", values)}
                                    isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => {
                                            return (
                                                <Chip
                                                    sx={{ borderRadius: "4px" }}
                                                    variant="outlined"
                                                    label={typeof option === "string" ? option : option?.title}
                                                    {...getTagProps({ index })}
                                                    key={typeof option === "string" ? option : option?.value}
                                                />
                                            );
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Languages:</InputLabel>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    options={(LANGUAGES.options as any) || []}
                                    getOptionLabel={(option: any) => option.title || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={errorDetails?.[index]?.languages}
                                            helperText={
                                                errorDetails?.[index]?.languages ? "Field is required" : undefined
                                            }
                                        />
                                    )}
                                    fullWidth
                                    value={session.languages as any[]}
                                    onChange={(e, values) =>
                                        handleEditSession(session.id as string, "languages", values)
                                    }
                                    isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => {
                                            return (
                                                <Chip
                                                    sx={{ borderRadius: "4px" }}
                                                    variant="outlined"
                                                    label={typeof option === "string" ? option : option?.title}
                                                    {...getTagProps({ index })}
                                                    key={typeof option === "string" ? option : option?.value}
                                                />
                                            );
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Registration Status:</InputLabel>
                                <Autocomplete
                                    options={(REGISTRATION_STATUS.options as any) || []}
                                    getOptionLabel={(option: any) => option.title || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={errorDetails?.[index]?.registrationStatus}
                                            helperText={
                                                errorDetails?.[index]?.registrationStatus
                                                    ? "Field is required"
                                                    : undefined
                                            }
                                        />
                                    )}
                                    fullWidth
                                    value={session.registrationStatus as any}
                                    onChange={(e, values) =>
                                        handleEditSession(session.id as string, "registrationStatus", values)
                                    }
                                    isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => {
                                            console.log("option", value, option);
                                            return (
                                                <Chip
                                                    sx={{ borderRadius: "4px" }}
                                                    variant="outlined"
                                                    label={typeof option === "string" ? option : option?.title}
                                                    {...getTagProps({ index })}
                                                    key={typeof option === "string" ? option : option?.value}
                                                />
                                            );
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Registration Instructions: <em>To add link, highlight text and click the link icon. <strong>Links must begin with https://</strong></em></InputLabel>
                                <ReactQuill
                                    theme="snow"
                                    value={session.registrationInstructions?.toString()}
                                    onChange={(value: any) =>
                                        handleEditSession(session.id as string, "registrationInstructions", value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Zipcode:</InputLabel>
                                <TextField
                                    error={errorDetails?.[index]?.zipcode}
                                    helperText={errorDetails?.[index]?.zipcode ? "Field is required" : undefined}
                                    fullWidth
                                    type="number"
                                    inputProps={{ min: "0", step: "1" }}
                                    onKeyPress={(event) => {
                                        if (event?.key === "-" || event?.key === "+") {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={(session as any).zipcode}
                                    onChange={(e: any) =>
                                        ["-", "+"].includes(e.target.value.at(-1)) ||
                                        handleEditSession(session.id as string, "zipcode", e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>County:</InputLabel>
                                <TextField
                                    error={errorDetails?.[index]?.county}
                                    helperText={errorDetails?.[index]?.county ? "Field is required" : undefined}
                                    fullWidth
                                    value={(session as any).county}
                                    onChange={(e: any) =>
                                        handleEditSession(session.id as string, "county", e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>City:</InputLabel>
                                <TextField
                                    error={errorDetails?.[index]?.city}
                                    helperText={errorDetails?.[index]?.city ? "Field is required" : undefined}
                                    fullWidth
                                    value={(session as any).city}
                                    onChange={(e: any) =>
                                        handleEditSession(session.id as string, "city", e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InputLabel>Seat Capacity:</InputLabel>
                                <TextField
                                    error={errorDetails?.[index]?.seatCapacity}
                                    helperText={errorDetails?.[index]?.seatCapacity ? "Field is required" : undefined}
                                    fullWidth
                                    type="number"
                                    inputProps={{ min: "0", step: "1" }}
                                    onKeyPress={(event) => {
                                        if (event?.key === "-" || event?.key === "+") {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={(session as any).seatCapacity}
                                    onChange={(e: any) =>
                                        handleEditSession(session.id as string, "seatCapacity", e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <InputLabel>Schedules:</InputLabel>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                            "& >:not(:last-child)": {
                                                mb: 2,
                                            },
                                        }}
                                    >
                                        {session?.schedules?.length ? (
                                            session.schedules.map((schedule: any, scheduleIndex: number) => {
                                                return (
                                                    <React.Fragment key={schedule.scheduleId}>
                                                        <Card>
                                                            <CardHeader
                                                                sx={{ p: 1 }}
                                                                action={
                                                                    <IconButton
                                                                        size={"small"}
                                                                        aria-label="delete"
                                                                        onClick={() =>
                                                                            handleEditSchedule(
                                                                                session.id as string,
                                                                                "delete",
                                                                                schedule.scheduleId as string
                                                                            )
                                                                        }
                                                                    >
                                                                        <Delete fontSize={"small"} />
                                                                    </IconButton>
                                                                }
                                                                subheader={`Schedule #${scheduleIndex + 1}`}
                                                            />
                                                            <Divider />
                                                            <CardContent>
                                                                <Grid container spacing={3} sx={{ p: 2 }}>
                                                                    <Grid item xs={12} md={6}>
                                                                        <InputLabel>Date:</InputLabel>
                                                                        <DatePicker
                                                                            sx={{
                                                                                width: "100%",
                                                                                "& fieldset": errorDetails?.[index]
                                                                                    ?.schedules?.[scheduleIndex]
                                                                                    ?.date && {
                                                                                    borderColor: "red !important",
                                                                                },
                                                                            }}
                                                                            value={schedule.date || undefined}
                                                                            onChange={(newValue: any) =>
                                                                                handleEditSchedule(
                                                                                    session.id as string,
                                                                                    "update",
                                                                                    schedule.scheduleId as string,
                                                                                    "date",
                                                                                    newValue
                                                                                )
                                                                            }
                                                                        />
                                                                        <Typography variant="body1" color={"red"}>
                                                                            {errorDetails?.[index]?.schedules?.[
                                                                                scheduleIndex
                                                                            ]?.date
                                                                                ? "Field is required"
                                                                                : ""}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6}>
                                                                        <InputLabel>Format Subtype:</InputLabel>
                                                                        <TextField
                                                                            error={
                                                                                errorDetails?.[index]?.schedules?.[
                                                                                    scheduleIndex
                                                                                ]?.formatSubType
                                                                            }
                                                                            helperText={
                                                                                errorDetails?.[index]?.schedules?.[
                                                                                    scheduleIndex
                                                                                ]?.formatSubType
                                                                                    ? "Field is required"
                                                                                    : undefined
                                                                            }
                                                                            fullWidth
                                                                            value={schedule.formatSubType}
                                                                            onChange={(e: any) =>
                                                                                handleEditSchedule(
                                                                                    session.id as string,
                                                                                    "update",
                                                                                    schedule.scheduleId as string,
                                                                                    "formatSubType",
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6}>
                                                                        <InputLabel>Start time:</InputLabel>
                                                                        <TimePicker
                                                                            sx={{
                                                                                width: "100%",
                                                                                "& fieldset": errorDetails?.[index]
                                                                                    ?.schedules?.[scheduleIndex]
                                                                                    ?.startTime && {
                                                                                    borderColor: "red !important",
                                                                                },
                                                                            }}
                                                                            value={schedule.startTime || undefined}
                                                                            onChange={(newValue: any) =>
                                                                                handleEditSchedule(
                                                                                    session.id as string,
                                                                                    "update",
                                                                                    schedule.scheduleId as string,
                                                                                    "startTime",
                                                                                    newValue
                                                                                )
                                                                            }
                                                                        />
                                                                        <Typography variant="body1" color={"red"}>
                                                                            {errorDetails?.[index]?.schedules?.[
                                                                                scheduleIndex
                                                                            ]?.startTime
                                                                                ? "Field is required"
                                                                                : ""}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6}>
                                                                        <InputLabel>End time:</InputLabel>
                                                                        <TimePicker
                                                                            sx={{
                                                                                width: "100%",
                                                                                "& fieldset": errorDetails?.[index]
                                                                                    ?.schedules?.[scheduleIndex]
                                                                                    ?.endTime && {
                                                                                    borderColor: "red !important",
                                                                                },
                                                                            }}
                                                                            value={schedule.endTime || undefined}
                                                                            onChange={(newValue: any) =>
                                                                                handleEditSchedule(
                                                                                    session.id as string,
                                                                                    "update",
                                                                                    schedule.scheduleId as string,
                                                                                    "endTime",
                                                                                    newValue
                                                                                )
                                                                            }
                                                                        />
                                                                        <Typography variant="body1" color={"red"}>
                                                                            {errorDetails?.[index]?.schedules?.[
                                                                                scheduleIndex
                                                                            ]?.endTime
                                                                                ? "Field is required"
                                                                                : ""}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6}>
                                                                        <InputLabel>Weekday:</InputLabel>
                                                                        <Autocomplete
                                                                            options={(WEEKDAY.options as any) || []}
                                                                            getOptionLabel={(option: any) =>
                                                                                option.title || ""
                                                                            }
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    error={
                                                                                        errorDetails?.[index]
                                                                                            ?.schedules?.[scheduleIndex]
                                                                                            ?.weekday
                                                                                    }
                                                                                    helperText={
                                                                                        errorDetails?.[index]
                                                                                            ?.schedules?.[scheduleIndex]
                                                                                            ?.weekday
                                                                                            ? "Field is required"
                                                                                            : undefined
                                                                                    }
                                                                                />
                                                                            )}
                                                                            fullWidth
                                                                            value={schedule.weekday}
                                                                            onChange={(e, values: any) =>
                                                                                handleEditSchedule(
                                                                                    session.id as string,
                                                                                    "update",
                                                                                    schedule.scheduleId as string,
                                                                                    "weekday",
                                                                                    values
                                                                                )
                                                                            }
                                                                            isOptionEqualToValue={(
                                                                                option: any,
                                                                                value: any
                                                                            ) => option.value === value.value}
                                                                            renderTags={(value, getTagProps) =>
                                                                                value.map((option, index) => {
                                                                                    return (
                                                                                        <Chip
                                                                                            sx={{
                                                                                                borderRadius: "4px",
                                                                                            }}
                                                                                            variant="outlined"
                                                                                            label={
                                                                                                typeof option ===
                                                                                                "string"
                                                                                                    ? option
                                                                                                    : option?.title
                                                                                            }
                                                                                            {...getTagProps({
                                                                                                index,
                                                                                            })}
                                                                                            key={
                                                                                                typeof option ===
                                                                                                "string"
                                                                                                    ? option
                                                                                                    : option?.value
                                                                                            }
                                                                                        />
                                                                                    );
                                                                                })
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    </React.Fragment>
                                                );
                                            })
                                        ) : (
                                            <Typography variant={"caption"}>
                                                No schedules available for this session
                                            </Typography>
                                        )}
                                        <Button
                                            size="small"
                                            startIcon={<Add />}
                                            variant={"text"}
                                            sx={{ borderRadius: "4px" }}
                                            onClick={() => handleEditSchedule(session.id as string, "add")}
                                        >
                                            Add schedule
                                        </Button>
                                    </Box>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Card>
                ))
            ) : (
                <Typography variant={"caption"}>No sessions available</Typography>
            )}
            <Button
                size="small"
                startIcon={<Add />}
                variant={"text"}
                sx={{ borderRadius: "4px" }}
                onClick={handleAddSession}
            >
                Add session
            </Button>
        </Box>
    );
};

export default Sessions;
