// import { useState, useEffect } from "react";
// import { Trans, useTranslation } from "react-i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageTitle from "../components/common/PageTitle";
import { Alert, Box, Button, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import { CREATE_COURSE, MANAGE_COURSES } from "../constant/path";
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';

// import { Autocomplete, Box, Button, Chip, Container, makeStyles, Typography } from "@mui/material";
import Search from "../components/caregiverTrainings/Search";
import { useNavigate } from "react-router";

// export function ManageCourses({ signOut, user }: WithAuthenticatorProps) {
    export function ManageCourses({ signOut }: WithAuthenticatorProps) {
    const translationNamespace = ["manageCourseCalGrowsTranslations"];
    const { t } = useTranslation(translationNamespace);
    const metaDesc: string = t("metaDesc");

    const navigate = useNavigate();

    return (
        <>
            <Helmet>
                <title>{t("browserTitle")}</title>
                <meta name="description" content={metaDesc} />
                <link rel="canonical" href={`${process.env.REACT_APP_URL}/${MANAGE_COURSES}`} />
            </Helmet>
            <div className="main-content-wrapper" data-testid="edit-course-calGrows-view">
               
                    {/* <p className="title-block">Hello, {user?.username}.<Button onClick={signOut}>Sign out</Button></p> */}
                    <p className="title-block">Hello! <Button onClick={signOut}>Sign out</Button></p>

                <PageTitle title={t("title")} />
                <Container maxWidth="xl" sx={{ p: "25px" }} data-testid="course-fields-component">
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <Button variant="contained" onClick={() => navigate(`../${CREATE_COURSE}`, { replace: true })}>
                            {"Create New Course"}
                        </Button>
                    </Box>
                    <Search editMode />
                </Container>
            </div>
        </>
    );
}
export default withAuthenticator(ManageCourses);