import { Typography, Card } from "@mui/material";
const EmptyData = () => {
  return (
    <Card sx={{ border: 'none', backgroundColor: '#EEEAEA', p: 2, borderRadius: 0 }} data-testid="empty-data-component">
      <div>
        <Typography sx={{ fontWeight: "bold", color: "#426DA9", display: "block", textAlign: "center" }} variant="h5">
          No results found
        </Typography>
        <Typography sx={{ fontSize: '14px', color: "#426DA9", display: "block", textAlign: "center" }} variant="body2">
          There is no data for the specified criteria.
        </Typography>
      </div>
    </Card>
  );
};

export default EmptyData;
