import { styled } from "@mui/material/styles";
import { Box, Typography, Grid, Container } from "@mui/material";
import BgPatternOne from "./circlePattern/BgPatternOne";
import CirclePatternThreeCoaching from "./circlePatternThreeCoaching/circlePatternThreeCoaching";
import { useLocation } from "react-router";
import { Trans } from "react-i18next";

const BannerBox = styled(Box)({
  width: "100%",
  height: "500px",
});

const TextArea = styled(Box)({
  position: "relative",
});

function Banner(props: { imageUrl: string; title: string; subTitle: string; description: React.ReactNode }) {
  const { imageUrl, title, subTitle, description } = props;
  const currentLocation = useLocation().pathname.replace("/", "");
  return (
    <BannerBox
      data-testid="banner-component"
      className={"main-banner-section position-relative " + currentLocation} // we are using this value for dynamic style
    >
      <Container maxWidth="xl" className="h-100 position-relative banner-inner-container">
        <Grid container spacing={2} className="h-100">
          <Grid item sm={12} md={12} lg={6} className="banner-img-left" style={{ backgroundImage: `url(${imageUrl})` }}>
            <div className="m-banner-patterns">
              <CirclePatternThreeCoaching />
            </div>
          </Grid>
          <Grid item sm={12} md={12} lg={6} className="right-content">
            <TextArea className="info-text-banner">
              <Typography variant="h1">
                <Trans components={{ newLine: <br /> }}>{title}</Trans>
              </Typography>
              {subTitle.length > 0 && (
                <Typography variant="h6" className="sub-title" data-testid="banner-sub-title">
                  {subTitle}
                </Typography>
              )}

              <Typography variant="body2" color="textSecondary" component="p">
                {description}
              </Typography>
            </TextArea>
          </Grid>
        </Grid>
      </Container>
      <BgPatternOne />
      <CirclePatternThreeCoaching />
    </BannerBox>
  );
}

export default Banner;
