import { Box, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';



function FilterCollapse(props: { onClick: any, filterCount: number}) {
    const translationNamespace = ['caregiverTrainingsTranslations']
    const { t } = useTranslation(translationNamespace);
    
    const { onClick, filterCount } = props;


    const applyFilterCount = (count: number)=>{  
        if (count > 0){
            return ` (${count})`
        }
        else{
            return ""
        }
    }  

    return (
        <Box sx={{maxWidth: "100px", backgroundColor: "#426DA9", display:"flex", flexFlow:"column", alignItems:"center", marginTop:"24px"}} data-testid="filter-collapse-component">
            <IconButton aria-label="toogle" sx={{ backgroundColor: "#426DA9", color: "#fff"}} size="large" onClick={() => onClick()}>
                <ArrowRightIcon />
            </IconButton>            
            <Typography variant="h6" sx={{transform: 'rotate(-90deg)', marginTop: "20px", color: "#fff"}}>{t('filters') + applyFilterCount(filterCount)}</Typography>
        </Box>
    );
}

export default FilterCollapse;
