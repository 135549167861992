import TagManager from "react-gtm-module";

const triggerGTMEvent = (eventName: string, data: object) => {
    TagManager.dataLayer({
        dataLayer: {
            event: eventName,
            data: data
        }
    });
}

export default triggerGTMEvent