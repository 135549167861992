import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { SyntheticEvent } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function DropdownMultiSelect(props: { opts: string[], name: string, title: string, onChange: any, selectedValues: string[] }) {
  const { opts, name, title, onChange, selectedValues } = props;

  const onTagsChange = (event: SyntheticEvent, value: string[] | null) => {
    if (value) {
      onChange(value);
    }
  }

  return (
    <Autocomplete
      multiple
      id={name}
      options={opts}
      disableCloseOnSelect
      getOptionLabel={(opt) => opt}
      defaultValue={[...selectedValues]}
      value={[...selectedValues]}
      onChange={onTagsChange}
      renderOption={(props, opt, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {opt}
        </li>
      )}
      style={{ width: "100%" }}
      renderInput={(params) => <TextField {...params} label={title} placeholder={title} />}
    />
  );
}

export default DropdownMultiSelect;
