export const LANGUAGE_LOOKUP_LOCAL_STORAGE = 'i18nextLng'

export enum LANGUAGES {
    EN = 'en',
    ES = 'es',
    RU = 'ru',
    KO = 'ko',
    VI = 'vi',
    ZH = 'zh',
    HY = 'hy'
}